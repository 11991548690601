import React from 'react';
import styles from './CheckProfile.module.scss';

import { CheckboxTerms } from '@components/CheckboxTerms';
import { IOptionLooking } from '@components/LookingFor/LookingFor.types';

interface IAddress {
   neighborhood: string;
   city: string;
}

interface Props {
   name: string;
   address: IAddress;
   about: string;
   interests: IOptionLooking[];
   whatsapp: any;
   onClick: () => void;
   isLoadingProfile: boolean;
}

export function CheckProfile({
   name,
   address,
   about,
   interests,
   onClick,
   isLoadingProfile,
}: Props) {
   const [igree, setIgree] = React.useState(false);
   const [isLoading, setIsLoading] = React.useState(false);

   const disabled = igree ? {} : { disabled: true };
   const loading = isLoading || isLoadingProfile ? { loading: true } : {};

   function getInterestsList() {
      let listInterests: string[] = [];

      interests.forEach(interest => {
         listInterests.push(interest.name);
         const list = interest.oportunities?.map(i => i.name);

         if (list) {
            list.forEach(i => listInterests.push(i));
         }
      });

      return listInterests.sort();
   }

   function handleFinish() {
      setIsLoading(true);

      onClick();

      setTimeout(() => {
         setIsLoading(false);
      }, 2000);
   }

   return (
      <>
         <div className={styles.container}>
            <div className={styles.spacingText}>
               <clb-heading-team size="sm" displayRole="h3">
                  Para finalizar, <br />
                  revise seu perfil.
               </clb-heading-team>

               <clb-text>
                  É assim que seu perfil irá aparecer na vitrine de conexões.
               </clb-text>
            </div>

            <div className={styles.spacingInput}>
               <clb-card-profile
                  name={name}
                  //@ts-ignore
                  address={JSON.stringify(address)}
                  about={about}
                  //@ts-ignore
                  interests={JSON.stringify(getInterestsList())}
               ></clb-card-profile>

               <CheckboxTerms
                  checked={igree}
                  onClick={() => setIgree(prev => !prev)}
               />
            </div>

            <clb-button
               aria-label="Finalizar"
               onClick={handleFinish}
               {...disabled}
               {...loading}
            >
               Finalizar
            </clb-button>
         </div>
      </>
   );
}
