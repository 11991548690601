import React from 'react';
import styles from './ProfileData.module.scss';

import { ModalUpdate } from '@components/ModalUpdate';
import { useToast } from '@hooks/useToast';
interface Props {
   title: string;
   description: string;
   onUpdate: () => Promise<boolean>;
   onShowModal?: () => void;
   children?: React.ReactNode;
   hasError?: boolean;
}

export function ProfileData({
   title,
   description,
   onUpdate,
   onShowModal,
   children,
   hasError,
}: Props) {
   const toast = useToast();

   const [showModal, setShowModal] = React.useState(false);
   const [isLoading, setIsLoading] = React.useState(false);

   React.useEffect(() => {
      if (showModal && onShowModal) {
         onShowModal();
      }
   }, [showModal]);

   async function handleUpdate() {
      setIsLoading(true);

      if (await onUpdate()) {
         toast.show({
            title: `${title} atualizado com sucesso!`,
            type: 'positive',
         });
      } else {
         toast.show({
            title: `Ocorreu um erro ao enviar sua mensagem!`,
            message: 'Por favor, tente novamente!',
            type: 'negative',
         });
      }

      setShowModal(false);
      setIsLoading(false);
   }

   function getTitles() {
      return title === title.toLocaleUpperCase()
         ? title
         : title.toLocaleLowerCase();
   }

   function handleOpenModal() {
      toast.close();
      setShowModal(true);
   }

   return (
      <>
         <div className={styles.container}>
            <div className={styles.wrapper}>
               <div className={styles.content}>
                  <clb-heading-team size="xs">{title}</clb-heading-team>
                  <clb-text>{description}</clb-text>
               </div>
               <div>
                  <clb-button-icon
                     icon="Edit"
                     size="lg"
                     aria-label={`Editar ${getTitles()}`}
                     onClick={handleOpenModal}
                  ></clb-button-icon>
               </div>
            </div>
            <div className={styles.divider}>
               <clb-divider></clb-divider>
            </div>
         </div>

         <ModalUpdate
            id={title}
            title={`Editar ${getTitles()}`}
            isOpen={showModal}
            isLoading={isLoading}
            onClick={handleUpdate}
            onClose={isOpen => setShowModal(isOpen)}
            hasError={hasError}
         >
            {children}
         </ModalUpdate>
      </>
   );
}
