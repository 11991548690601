import React from 'react';
import styles from './Login.module.scss';
import { useLocation } from 'react-router-dom';

import { ContainerAuth } from '@components/ContainerAuth';
import { Login as LoginPage, TtypeUser } from '@components/Login';
import { AccessCode } from '@components/AccessCode';
import { HowHelpYou } from '@components/HowHelpYou';

import { useDrawer } from '@hooks/useDrawer';
import { AgeGate as ModalAgeGate } from '@components/AgeGate';

import { useToast } from '@hooks/useToast';

export function Login() {
   const drawer = useDrawer();

   const [showModalAgeGate, setShowModalAgeGate] = React.useState(false);
   const [phone, setPhone] = React.useState('');
   const [typeUser, setTypeUser] = React.useState<TtypeUser>('waiting-list');

   const [pageIndex, setPageIndex] = React.useState(0);
   const pages = [
      {
         page: (
            <LoginPage
               value={phone}
               onChange={e => setPhone(e.target.value)}
               onClick={handleValidatePhone}
            />
         ),
      },
      {
         page: (
            <AccessCode
               phone={phone}
               typeUser={typeUser}
               onValidateTypeUser={onValidateTypeUser}
               onBack={() => setPageIndex(0)}
            />
         ),
      },
      {
         page: <HowHelpYou onClick={() => setPageIndex(0)} phone={phone} />,
      },
   ];

   const location = useLocation();
   const toast = useToast();

   React.useEffect(() => {
      const DURATION_SHOW_MODAL = window.innerWidth > 768 ? 650 : 350;

      if (location.state && location.state.toast) {
         toast.show({
            title: 'Você precisa estar logado para acessar esta página!',
            type: 'negative',
         });

         location.state.toast = false;
      }

      setTimeout(() => {
         setShowModalAgeGate(true);
      }, DURATION_SHOW_MODAL);
   }, []);

   function handleValidatePhone(typeUserSelected: TtypeUser) {
      setTypeUser(typeUserSelected);
      setPageIndex(1);
   }

   function onValidateTypeUser() {
      setPageIndex(2);
   }

   function handleOnBack() {
      if (pageIndex === 0) {
         drawer.closeDrawer();
      } else {
         setPageIndex(0);
      }
   }

   return (
      <>
         <ContainerAuth maxPages={pages.length} onBack={handleOnBack}>
            <div className={styles.container}>
               <div className={styles.page}>{pages[pageIndex].page}</div>
            </div>
         </ContainerAuth>

         {showModalAgeGate && <ModalAgeGate />}
      </>
   );
}
