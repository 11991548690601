import React from 'react';
import styles from './Pdv.module.scss';

import { useLocation, useNavigate } from 'react-router-dom';

import * as pdvService from '@services/pdv';

import { ContainerAuth } from '@components/ContainerAuth';
import { HowCallYou } from '@components/HowCallYou';
import { More18Years } from '@components/More18Years';
import { BirthDate } from '@components/BirthDate';
import { InformCNPJCPF } from '@components/InformCNPJCPF';
import { formatStringData } from '@utils/convertDate';
import { clearString } from '@utils/formatString';

import { useToast } from '@hooks/useToast';
import { useAuth } from '@hooks/useAuth';
import { gaFormTracking } from '@utils/gaFormTracking';

export function Pdv() {
   const navigate = useNavigate();
   const toast = useToast();
   const auth = useAuth();
   const location = useLocation();

   const [name, setName] = React.useState('');
   const [dateOfBirth, setdateOfBirth] = React.useState('');
   const [cpfCnpj, setCpfCnpj] = React.useState('');

   const [isLoadingFinish, setIsLoadingFinish] = React.useState(false);

   const [pageIndex, setPageIndex] = React.useState(0);
   const stepTitles = [
      'como-podemos-chamar-você',
      'voce-tem-mais-de-18-anos',
      'agora-sua-data-de-nascimento',
      'informe-seu-cnpj-ou-cpf',
   ];

   const pages = [
      {
         page: (
            <HowCallYou
               onClick={handleNextPage}
               value={name}
               onChange={e => setName(e.target.value)}
            />
         ),
      },
      {
         page: <More18Years onClick={handleNextPage} />,
      },
      {
         page: (
            <BirthDate
               onClick={handleNextPage}
               value={dateOfBirth}
               onChange={(e: any) => setdateOfBirth(e.target.value)}
            />
         ),
      },
      {
         page: (
            <InformCNPJCPF
               onClick={handleFinish}
               value={cpfCnpj}
               onChange={(e: any) => setCpfCnpj(e.target.value)}
               isLoading={isLoadingFinish}
            />
         ),
      },
   ];

   function handleNextPage() {
      gaFormTracking(
         'form',
         `pdv_form_step_${stepTitles[pageIndex]}`,
         'pdv_form',
      );
      setPageIndex((prev: number) => prev + 1);
   }

   function handleOnBack() {
      if (pageIndex === 0) {
         navigate('/');
      } else {
         setPageIndex((prev: number) => prev - 1);
      }
   }

   async function handleFinish() {
      if (!location.state.phone) return;
      setIsLoadingFinish(true);

      gaFormTracking(
         'form',
         `pdv_form_step_${stepTitles[pageIndex]}`,
         'pdv_form',
      );

      const cpfCnpjNumbers = clearString(cpfCnpj);
      const phoneNumbers = clearString(location.state.phone);

      const user = await pdvService.savePdv({
         name: name,
         phone: phoneNumbers,
         dateOfBirth: formatStringData(dateOfBirth),
         cpfCnpj: cpfCnpjNumbers,
      });

      if (!user) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });
         return;
      }

      auth.saveUser({
         name,
         token: user.token,
         phone: location.state.phone,
         level: 'pdv',
      });

      setIsLoadingFinish(false);

      navigate('/connections', {
         state: { showWelcomePDV: true },
      });
   }

   return (
      <>
         <ContainerAuth
            title="Seu perfil"
            maxPages={pages.length}
            pageNumber={pageIndex + 1}
            description={`Etapa ${pageIndex + 1} de ${pages.length}`}
            showProgress
            onBack={handleOnBack}
         >
            <div className={styles.container}>
               <div className={styles.page}>{pages[pageIndex].page}</div>
            </div>
         </ContainerAuth>
      </>
   );
}
