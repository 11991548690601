import axios from 'axios';
import { IUser } from '../hooks/useAuth.types';

const api = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors?.request.use(async function (config) {
   const userSaved = localStorage.getItem(
      `${process.env.REACT_APP_NAME_BD}user`,
   );

   if (userSaved) {
      const user = JSON.parse(userSaved) as IUser;

      config.headers = {
         Authorization: `Bearer ${user.token}`,
      };
   }

   return config;
});

api.interceptors?.response.use(
   response => {
      return response;
   },
   error => {
      if (error.response.status === 401) {
         localStorage.removeItem(`${process.env.REACT_APP_NAME_BD}user`);
         location.reload();
         return;
      }

      return Promise.reject(error);
   },
);

export default api;
