import React from 'react';
import styles from './ConnectionFilters.module.scss';

import { InterestItem } from '@services/Interest.types';
import { OpportunityItem } from '@services/opportunity.types';

export interface IItemsSelected {
   interests: InterestItem[];
   opportunities: OpportunityItem[];
}

interface Props {
   visible: boolean;
   interests: InterestItem[];
   interestsIds: string[] | undefined;
   opportunities: OpportunityItem[];
   opportunitiesIds: string[] | undefined;
   onApply: (items: IItemsSelected) => void;
}

export function ConnectionFilters({
   visible,
   interests,
   interestsIds,
   opportunities,
   opportunitiesIds,
   onApply,
}: Props) {
   const [interestsSelected, setInterestsSelected] = React.useState<
      InterestItem[]
   >([]);
   const [opportunitiesSelected, setOpportunitiesSelected] = React.useState<
      OpportunityItem[]
   >([]);

   React.useEffect(() => {
      if (visible) onReCheckInterestOpportunity();
   }, [visible]);

   function onReCheckInterestOpportunity() {
      if (interestsSelected.length > 0 && interestsIds) {
         const _interestSelect = interestsSelected.filter(interest =>
            interestsIds.includes(interest.id),
         );

         setInterestsSelected(_interestSelect);
      }

      if (opportunitiesSelected.length > 0 && opportunitiesIds) {
         const _opportunitiesSelected = opportunitiesSelected.filter(
            opportunity => opportunitiesIds.includes(opportunity.id),
         );

         setOpportunitiesSelected(_opportunitiesSelected);
      }
   }

   function handleCheckInterest(item: InterestItem) {
      if (interestsSelected.includes(item)) {
         const listInterest = interestsSelected.filter(i => i !== item);
         setInterestsSelected(listInterest);
      } else {
         setInterestsSelected([...interestsSelected, item]);
      }
   }

   function handleCheckOpportunity(item: OpportunityItem) {
      if (opportunitiesSelected.includes(item)) {
         const listInterest = opportunitiesSelected.filter(i => i !== item);
         setOpportunitiesSelected(listInterest);
      } else {
         setOpportunitiesSelected([...opportunitiesSelected, item]);
      }
   }

   function handleClearFilters() {
      setInterestsSelected([]);
      setOpportunitiesSelected([]);

      onApply({
         interests: [],
         opportunities: [],
      });
   }

   function handleApplyFilters() {
      onApply({
         interests: interestsSelected,
         opportunities: opportunitiesSelected,
      });
   }

   const booleanProps = {
      visible: visible ? true : undefined,
   };

   return (
      <clb-super-drawer id="filter-connections" {...booleanProps}>
         <div className={styles.container}>
            <clb-heading-team size="lg">Filtros</clb-heading-team>

            <div className={styles.titleAndCheckboxWrapper}>
               <clb-heading-team size="xs">
                  Encontre pessoas que buscam
               </clb-heading-team>
               <div className={styles.checkboxWrapperFlex}>
                  {interests
                     .filter(i => i.enabled === true)
                     .map(interest => {
                        const checked = interestsSelected.includes(interest)
                           ? { checked: true }
                           : {};

                        return (
                           <div key={interest.id} className={styles.checkbox}>
                              <clb-checkbox
                                 // @ts-ignore
                                 key={interest.id}
                                 id={`interest-${interest.id}`}
                                 name={`interest-${interest.id}`}
                                 label={interest.name}
                                 onClick={() => handleCheckInterest(interest)}
                                 {...checked}
                              ></clb-checkbox>
                           </div>
                        );
                     })}
               </div>
            </div>

            <div className={styles.titleAndCheckboxWrapper}>
               <clb-heading-team size="xs">Oportunidades</clb-heading-team>

               <div className={styles.checkboxWrapperFlex}>
                  <div className={styles.checkboxWrapperFlexColumn}>
                     {opportunities.map(opportunity => {
                        const checked = opportunitiesSelected.includes(
                           opportunity,
                        )
                           ? { checked: true }
                           : {};

                        return (
                           <div
                              key={opportunity.id}
                              className={styles.checkbox}
                           >
                              <clb-checkbox
                                 // @ts-ignore
                                 key={opportunity.id}
                                 id={`opportunity-${opportunity.id}`}
                                 name={`opportunity-${opportunity.id}`}
                                 label={opportunity.name}
                                 onClick={() =>
                                    handleCheckOpportunity(opportunity)
                                 }
                                 {...checked}
                              ></clb-checkbox>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </div>

            <div className={styles.buttonsWrapper}>
               <clb-button
                  size="md"
                  type="secondary"
                  onClick={handleClearFilters}
               >
                  Limpar filtros
               </clb-button>

               <clb-button onClick={handleApplyFilters}>
                  Aplicar filtros
               </clb-button>
            </div>
         </div>
      </clb-super-drawer>
   );
}
