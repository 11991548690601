import React from 'react';
import styles from './HowHelpYou.module.scss';

import { useNavigate } from 'react-router-dom';

import { ModalButton } from '@components/ModalButton';
import { ModalStepper } from '@components/ModalStepper';

import { useDrawer } from '@hooks/useDrawer';

type TRadioOption = 'capacity' | 'recruit';

interface Props {
   onClick: () => void;
   phone: string;
}

export function HowHelpYou({ onClick, phone }: Props) {
   const navigate = useNavigate();
   const drawer = useDrawer();

   const [radioChecked, setRadioChecked] = React.useState<TRadioOption | null>(
      null,
   );
   const [showModal, setShowModal] = React.useState(false);

   const capacityChecked = radioChecked === 'capacity' ? { checked: true } : {};
   const recruitChecked = radioChecked === 'recruit' ? { checked: true } : {};

   const disabled = radioChecked === null ? { disabled: true } : {};

   function handleCapicity() {
      setRadioChecked('capacity');
   }

   function handleRecruit() {
      setRadioChecked('recruit');
   }

   function handleContinue() {
      setShowModal(true);
   }

   React.useEffect(() => {
      const modalElement = document.getElementById(
         'modalButton',
      ) as unknown as HTMLElement;
      modalElement?.addEventListener('click', e => {
         const modalIsOpen = modalElement.getAttribute('isOpen') ? true : false;
         setShowModal(modalIsOpen);
      });
   }, []);

   React.useEffect(() => {
      const modalElement = document.getElementById(
         'modalStepper',
      ) as unknown as HTMLElement;
      modalElement?.addEventListener('click', e => {
         const modalIsOpen = modalElement.getAttribute('isOpen') ? true : false;
         setShowModal(modalIsOpen);
      });
   }, []);

   return (
      <>
         <div>
            <clb-heading-team size="lg">
               Como o Bora <br />
               pode te ajudar?
            </clb-heading-team>

            <div className={styles.spacingText} />
            <clb-text>Selecione uma opção.</clb-text>

            <div className={styles.spacingRadiogroup}>
               <clb-radio-button {...capacityChecked} onClick={handleCapicity}>
                  Busco conhecimento e oportunidades
                  <br />
                  de emprego.
               </clb-radio-button>

               <div className={styles.spacingRadio} />
               <clb-radio-button {...recruitChecked} onClick={handleRecruit}>
                  Tenho um negócio e quero contratar.
               </clb-radio-button>
            </div>

            <clb-button onClick={handleContinue} {...disabled}>
               Continuar
            </clb-button>
         </div>

         <ModalButton
            id="modalCapacity"
            isOpen={showModal && radioChecked === 'capacity'}
            title="Apenas pessoas<br/>convidadas"
            description="No momento o Bora é apenas para pessoas<br/>convidadas, verifique seu celular ou entre<br/>na lista de espera."
            onClose={setShowModal}
            buttonPrimary={{
               label: 'Entrar na lista',
               onClick: () => {
                  navigate(`/waitinglist`, { state: { phone } });
                  drawer.removeDrawer();
               },
            }}
            buttonSecondary={{
               label: 'Tentar novamente',
               onClick,
            }}
            emojiType="disappointed-face"
         />

         <ModalStepper
            id="modalRecruit"
            isOpen={showModal && radioChecked === 'recruit'}
            onClose={setShowModal}
            onClick={() => {
               drawer.removeDrawer();
               navigate(`/pdv`, { state: { phone } });
            }}
            steps={[
               {
                  id: '1',
                  title: 'Faça um cadastro<br/>bem rapidinho!',
                  description:
                     'Precisamos de algumas informações para você<br/>ter acesso a vitrine de conexões do Bora.',
                  emoji: 'hand-writing',
               },
               {
                  id: '2',
                  title: 'Conecte com perfis<br/>do seu interesse!',
                  description:
                     'Você poderá entrar em contato com todos os<br/>perfis disponíveis, comunicando sua proposta.',
                  emoji: 'star-struck',
               },
               {
                  id: '3',
                  title: 'Agora é só esperar<br/>pela resposta!',
                  description:
                     'Vamos encaminhar sua mensagem e<br/>contato para a pessoa que deseja conectar,<br/>ela responderá você via Whatsapp.',
                  emoji: 'raising-hands',
               },
            ]}
         />
      </>
   );
}
