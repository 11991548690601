import styles from './PrivacyPolicy.module.scss';

import logo from '@assets/logoBora.svg';

export function PrivacyPolicy() {
   const _scrollToWhichDataWeUse = () => {
      const whichDataWeUse = document.getElementById('whichDataWeUse');
      whichDataWeUse?.scrollIntoView({
         behavior: 'smooth',
      });
   };

   const _scrollToWhoWeShareDataWith = () => {
      const whoWeShareDataWith = document.getElementById('whoWeShareDataWith');
      whoWeShareDataWith?.scrollIntoView({
         behavior: 'smooth',
      });
   };

   const _scrollToHowWeKeepDataSafe = () => {
      const howWeKeepDataSafe = document.getElementById('howWeKeepDataSafe');
      howWeKeepDataSafe?.scrollIntoView({
         behavior: 'smooth',
      });
   };

   const _scrollToYourRights = () => {
      const yourRights = document.getElementById('yourRights');
      yourRights?.scrollIntoView({
         behavior: 'smooth',
      });
   };

   const _scrollToCookies = () => {
      const cookies = document.getElementById('cookies');
      cookies?.scrollIntoView({
         behavior: 'smooth',
      });
   };

   const feasibilityAndFunctionalities = (
      <td>
         <div className={styles.celTextWrapper}>
            <div>
               <ul>
                  <li>
                     <clb-text>
                        Dados de navegação e viabilidade das funcionalidades do
                        Site;
                     </clb-text>
                  </li>
                  <li>
                     <clb-text>
                        Cadastro no Site e utilização do Site pelas empresas e
                        seus gestores.
                     </clb-text>
                  </li>
               </ul>
               <clb-text>
                  <br />
                  <strong>Base legal:</strong> Legítimo interesse. <br />
                  <br />
                  <ul>
                     <li>
                        <clb-text>
                           Eventual envio de comunicação relacionada à
                           utilização das funcionalidades do Site e à conteúdos
                           informativos relacionados ao tema;
                        </clb-text>
                     </li>
                     <li>
                        <clb-text>
                           Tratamento de cookies não-essenciais.
                        </clb-text>
                     </li>
                  </ul>
                  <br />
                  <strong>Base legal:</strong> Consentimento do titular. <br />
                  <br />
                  <ul>
                     <li>
                        <clb-text>
                           Envio de dados para clientes e parceiros com a
                           finalidade de recolocação.
                        </clb-text>
                     </li>
                  </ul>
                  <br />
                  <strong>Base legal:</strong> Execução de contrato e
                  procedimentos preliminares a ele.
               </clb-text>
            </div>
         </div>
      </td>
   );

   return (
      <>
         <clb-header-team logo={logo} empty></clb-header-team>

         <section className={styles.container}>
            <div className={styles.wrapper}>
               <div className={styles.textWrapper}>
                  <div className={styles.titleWrapper}>
                     <clb-heading-team size="sm">
                        Política de Privacidade e Proteção de Dados
                     </clb-heading-team>
                  </div>
                  <div className={styles.subtitleAndTextWrapper}>
                     <clb-text>
                        A AMBEV S.A., pessoa jurídica de direito privado
                        inscrita no CNPJ sob o nº 07.526.557/0001-00, com sede
                        em São Paulo/SP (“AMBEV”), considera relevantes os
                        registros eletrônicos e dados disponibilizados por você
                        (“Usuário”) na utilização do site do Bora, disponível
                        por meio do link:{' '}
                        <a href="https://bora.ambev.com.br/" target="_blank">
                           bora.ambev.com.br
                        </a>
                        (“Site”), servindo a presente Política de Privacidade
                        ("Política") para regular, de forma simples,
                        transparente e objetiva, quais dados e informações serão
                        obtidos, assim como quando os mesmos poderão ser
                        utilizados pela AMBEV.
                        <br />
                        <br />
                        O BORA é uma iniciativa da AMBEV, pela qual ela ajuda
                        clientes e parceiros a conectar pessoas da população
                        geral que estejam em busca de uma recolocação
                        profissional, no qual o próprio interessado realiza o
                        cadastro para que seja avaliado por parceiros possíveis
                        oportunidades profissionais, sem qualquer garantia, por
                        parte da Ambev e parceiros que haverá a contratação.
                        <br />
                        <br />
                        Para melhor ilustrar a forma como realizamos o
                        tratamento dos dados, apresentamos um resumo de nossa
                        Política de Privacidade e Proteção de Dados Pessoais
                        (“Política”):
                     </clb-text>
                  </div>
                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mt3x}>
                        <clb-heading-team size="2xs">
                           Quadro Resumo
                        </clb-heading-team>
                     </div>

                     <table className={styles.tableOneMobile}>
                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Agente de tratamento
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Agente de tratamentos AmBev S.A.
                                    <br /> CNPJ: 07.526.557/0001-00
                                    <br />
                                    Endereço: Rua Dr. Renato Paes de Barros,
                                    1017, 3º andar, na cidade de São Paulo/SP
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Papel no tratamento
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>Controladora</clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Natureza dos dados tratados
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Dados pessoais fornecidos pelo usuário e/ou
                                    coletados automaticamente.
                                    <br />
                                    <a onClick={_scrollToWhichDataWeUse}>
                                       mais...
                                    </a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Finalidade como controladora
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>{feasibilityAndFunctionalities}</tr>
                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Compartilhamento
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Operadores e fornecedores de serviços
                                    essenciais para o funcionamento da
                                    plataforma. Parceiros fornecedores de
                                    serviços, produtos e conteúdos adicionais.
                                    <br />
                                    <a onClick={_scrollToWhoWeShareDataWith}>
                                       mais...
                                    </a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>

                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Proteção de Dados
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Medidas de segurança, técnicas e
                                    administrativas adequadas.
                                    <br />
                                    <a onClick={_scrollToHowWeKeepDataSafe}>
                                       mais...
                                    </a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>

                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Seus direitos
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Confirmação da existência de tratamento,
                                    acesso, correção, etc.
                                    <br />
                                    <a onClick={_scrollToYourRights}>mais...</a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>

                        <tr>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Contato
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    E-mail: dataprivacy@ambev.com.br
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                     </table>

                     <table className={styles.tableOneDesktop}>
                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Agente de tratamento
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Agente de tratamentos AmBev S.A.
                                    <br /> CNPJ: 07.526.557/0001-00
                                    <br />
                                    Endereço: Rua Dr. Renato Paes de Barros,
                                    1017, 3º andar, na cidade de São Paulo/SP
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Papel no tratamento
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>Controladora</clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Natureza dos dados tratados
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Dados pessoais fornecidos pelo usuário e/ou
                                    coletados automaticamente.
                                    <br />
                                    <a onClick={_scrollToWhichDataWeUse}>
                                       mais...
                                    </a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Finalidade como controladora
                                 </clb-heading-team>
                              </div>
                           </th>
                           {feasibilityAndFunctionalities}
                        </tr>

                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Compartilhamento
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Operadores e fornecedores de serviços
                                    essenciais para o funcionamento da
                                    plataforma. Parceiros fornecedores de
                                    serviços, produtos e conteúdos adicionais.
                                    <br />
                                    <a onClick={_scrollToWhoWeShareDataWith}>
                                       mais...
                                    </a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>

                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Proteção de Dados
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Medidas de segurança, técnicas e
                                    administrativas adequadas.
                                    <br />
                                    <a onClick={_scrollToHowWeKeepDataSafe}>
                                       mais...
                                    </a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>

                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Seus direitos
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celTextWrapperMore}>
                                 <clb-text>
                                    Confirmação da existência de tratamento,
                                    acesso, correção, etc.
                                    <br />
                                    <a onClick={_scrollToYourRights}>mais...</a>
                                 </clb-text>
                              </div>
                           </td>
                        </tr>

                        <tr>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Contato
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    E-mail: dataprivacy@ambev.com.br
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                     </table>
                  </div>
                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="whichDataWeUse"
                  >
                     <div className={styles.mb2x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           1. Quais Dados Utilizamos
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        {' '}
                        A AMBEV poderá coletar as informações inseridas
                        ativamente pelo Usuário no momento do cadastro e, ainda,
                        informações coletadas automaticamente quando da
                        utilização do Site e da rede, como, por exemplo,
                        identificação do estabelecimento comercial utilizado, IP
                        com data e hora da conexão, entre outras.
                        <br />
                        <br />
                        Há, assim, o tratamento de dois tipos de dados pessoais:
                        (i) aqueles fornecidos pelo próprio Usuário; e (ii)
                        aqueles coletados automaticamente.
                        <br />
                        <br />
                        (i) Informações fornecidas pelo Usuário: A AMBEV coleta
                        a nome, data de nascimento, CPF ou CNPJ, número de
                        celular, endereço e currículo, inseridos ativamente pelo
                        Usuário no Site, quando do preenchimento pelo Usuário do
                        cadastro para a utilização do Site.
                        <br />
                        <br />A AMBEV fará uso dessas informações para
                        viabilizar a utilização do Site e de suas ferramentas
                        pela empresa e gestores (usuários cadastrados). Ainda, a
                        AMBEV também fará uso das informações coletadas para o
                        envio de informações relativas à utilização dos recursos
                        disponibilizados pelo próprio Site.
                        <br />
                        <br />
                        (ii) Dados coletados automaticamente: A AMBEV também
                        coleta uma série de informações de modo automático, tais
                        como: características do dispositivo de acesso, do
                        navegador, IP (com data e hora), origem do IP,
                        informações sobre cliques, páginas acessadas, as páginas
                        seguintes acessadas após a saída do Site, ou qualquer
                        termo de procura digitado nos sites ou em referência a
                        estes, dentre outros. Para tal coleta, a AMBEV fará uso
                        de algumas tecnologias padrões, como cookies, pixel
                        tags, beacons e local shared objects, que são utilizadas
                        com o propósito de melhorar a experiência de navegação
                        do Usuário no Site, de acordo com seus hábitos e suas
                        preferências.
                        <br />
                        <br />É possível desabilitar, por meio das configurações
                        de seu navegador de internet, a coleta automática de
                        informações por meio de algumas tecnologias, como
                        cookies e caches, bem como em nosso próprio website,
                        especificamente quanto aos{' '}
                        <a onClick={_scrollToCookies}>cookies</a>. No entanto, o
                        Usuário deve estar ciente de que, se desabilitadas estas
                        tecnologias, alguns recursos oferecidos pelo site, que
                        dependem do tratamento dos referidos dados, poderão não
                        funcionar corretamente.
                        <br />
                        <br />
                        As informações coletadas poderão ser compartilhadas pela
                        AMBEV com: (i) parceiros da iniciativa XXXXX (ii) demais
                        empresas parceiras, quando forem necessárias para a
                        adequada prestação dos serviços objeto de suas
                        atividades; (iii) para proteção dos interesses da AMBEV
                        em qualquer tipo de conflito; (iv) mediante decisão
                        judicial ou requisição de autoridade competente.
                        <br />
                        <br />
                        Ainda, esclarecemos que suas informações também poderão
                        ser compartilhadas com empresas provedoras de
                        infraestrutura tecnológica e operacional necessária para
                        as atividades da AMBEV, como intermediadoras de
                        pagamento e provedores de serviço de armazenamento de
                        informações.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           2. Como Utilizamos Os Dados
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        As informações coletadas pela AMBEV têm como finalidade
                        possibilitar a utilização pela empresa e seu(s)
                        gestor(es) do Site e, eventualmente, disponibilizar ao
                        Usuário a opção para que ele receba sugestões e/ou
                        propostas de produtos, soluções ou serviços, e os
                        benefícios dos sites parceiros e da AMBEV.
                        <br />
                        <br />
                        As informações coletadas poderão, ainda, ser utilizadas
                        para fins de comunicação com as empresas e gestores
                        cadastrados e de envio de informações relativas à
                        utilização dos recursos disponibilizados no{' '}
                        <a href="https://bora.ambev.com.br/" target="_blank">
                           bora.ambev.com.br
                        </a>
                        <br />
                        <br />
                        <b>Base Legal:</b> Nestes casos, o tratamento de dados é
                        autorizado pelo inciso I do artigo 7º da Lei nº
                        13.709/2018, a Lei Geral de Proteção de Dados (“Lei
                        Geral de Proteção de Dados – LGPD”).
                     </clb-text>
                  </div>
                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team
                           className={styles.mb2x}
                           size="2xs"
                           id="cookies"
                        >
                           3. Como Utilizamos os Cookies
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Cookies são arquivos ou informações que podem ser
                        armazenadas em seus dispositivos, quando você visita o
                        Site. Geralmente, um cookie contém o nome do site que o
                        originou, seu tempo de vida e um valor, que é gerado
                        aleatoriamente.
                        <br />
                        <br />A AMBEV utiliza cookies para facilitar o uso e
                        melhor adaptar o Site aos seus interesses e
                        necessidades, bem como para compilarmos informações
                        sobre a utilização de nossos sites e serviços,
                        auxiliando a melhorar suas estruturas e seus conteúdos.
                        Os cookies também podem ser utilizados para acelerar
                        suas atividades e experiências futuras no Site.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <table className={styles.tableTwoMobile}>
                        <tr className={styles.brandColor}>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Tipos de cookies
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr className={styles.backgroundColor}>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Necessários</clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Esses cookies são essenciais para que o Site
                                    carregue corretamente e permitem que você
                                    navegue em nossos sites e faça uso de todas
                                    as funcionalidades.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr className={styles.backgroundColor}>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Desempenhos</clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Esses cookies nos ajudam a entender como os
                                    visitantes interagem com o Site, fornecendo
                                    informações sobre as áreas visitadas, o
                                    tempo de visita ao site e quaisquer
                                    problemas encontrados, como mensagens de
                                    erro.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr className={styles.backgroundColor}>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Funcionais</clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Esses cookies permitem que o Site se lembre
                                    de suas escolhas, para proporcionar uma
                                    experiência mais personalizada. Também,
                                    possibilitam que os Usuários assistam a
                                    vídeos e utilizem ferramentas sociais,
                                    campos para comentários, fóruns, entre
                                    outros.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr className={styles.backgroundColor}>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Marketing</clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Não é aplicado no caso do Bora.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                     </table>

                     <table className={styles.tableTwoDesktop}>
                        <tr className={styles.brandColor}>
                           <th>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    Tipos de cookies
                                 </clb-heading-team>
                              </div>
                           </th>
                           <td>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-heading-team size="2xs">
                                    O que eles fazem?
                                 </clb-heading-team>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td className={styles.backgroundColor}>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Necessários</clb-text>
                              </div>
                           </td>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Esses cookies são essenciais para que o Site
                                    carregue corretamente e permitem que você
                                    navegue em nossos sites e faça uso de todas
                                    as funcionalidades.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td className={styles.backgroundColor}>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Desempenhos</clb-text>
                              </div>
                           </td>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Esses cookies nos ajudam a entender como os
                                    visitantes interagem com o Site, fornecendo
                                    informações sobre as áreas visitadas, o
                                    tempo de visita ao site e quaisquer
                                    problemas encontrados, como mensagens de
                                    erro.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td className={styles.backgroundColor}>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Funcionais</clb-text>
                              </div>
                           </td>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Esses cookies permitem que o Site se lembre
                                    de suas escolhas, para proporcionar uma
                                    experiência mais personalizada. Também,
                                    possibilitam que os Usuários assistam a
                                    vídeos e utilizem ferramentas sociais,
                                    campos para comentários, fóruns, entre
                                    outros.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td className={styles.backgroundColor}>
                              <div className={styles.celHeadingWrapper}>
                                 <clb-text>Marketing</clb-text>
                              </div>
                           </td>
                           <td>
                              <div className={styles.celTextWrapper}>
                                 <clb-text>
                                    Não é aplicado no caso do Bora.
                                 </clb-text>
                              </div>
                           </td>
                        </tr>
                     </table>
                  </div>
                  <div className={styles.subtitleAndTextWrapper}>
                     <clb-text>
                        Após o usuário consentir para a utilização de cookies,
                        quando do uso do Site, a AMBEV armazenará um cookie em
                        seu dispositivo para lembrar disso na próxima sessão.
                        <br />
                        <br /> A qualquer momento, o Usuário poderá revogar seu
                        consentimento quanto aos cookies, pelo que deverá apagar
                        os cookies do Site utilizando as configurações de seu
                        navegador de preferência. Para mais informações sobre
                        como proceder em relação à gestão dos cookies nos
                        navegadores:
                        <br />
                        <br />
                        <ul>
                           <li>
                              Internet Explorer:{' '}
                              <a
                                 href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                                 target="_blank"
                              >
                                 https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
                              </a>
                           </li>
                           <li>
                              Mozilla Firefox:{' '}
                              <a
                                 href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam"
                                 target="_blank"
                              >
                                 https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam
                              </a>
                           </li>
                           <li>
                              Google Chrome:{' '}
                              <a
                                 href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR"
                                 target="_blank"
                              >
                                 https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR
                              </a>
                           </li>
                           <li>
                              Safari:{' '}
                              <a
                                 href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                                 target="_blank"
                              >
                                 https://support.apple.com/pt-br/guide/safari/sfri11471/mac
                              </a>
                           </li>
                        </ul>
                        <br />
                        Por fim, lembramos que, caso o Usuário não aceite alguns
                        cookies do Site, certos serviços poderão não funcionar
                        de maneira ideal.
                     </clb-text>
                  </div>

                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="whoWeShareDataWith"
                  >
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           4. Com Quem Compartilharemos Os Dados
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        A AMBEV constitui grupo econômico, que trabalha em
                        parceria com diversas empresas. Deste modo, poderá
                        compartilhar as informações coletadas por meio do Site,
                        nas seguintes hipóteses:
                        <br />
                        <br />
                        i. Com outras empresas do Grupo AMBEV, constituídas ou
                        atuantes em qualquer país, que se comprometem a utilizar
                        as informações para os mesmos fins indicados na presente
                        Política;
                        <br />
                        <br />
                        ii. Para proteção dos interesses da AMBEV em qualquer
                        tipo de conflito, incluindo ações judiciais;
                        <br />
                        <br />
                        iii. No caso de transações e alterações societárias
                        envolvendo a AMBEV, hipótese em que a transferência das
                        informações será necessária para a continuidade dos
                        serviços; ou,
                        <br />
                        <br />
                        iv. Mediante ordem judicial ou pelo requerimento de
                        autoridades administrativas que detenham competência
                        legal para sua requisição.
                     </clb-text>
                  </div>

                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="howWeKeepDataSafe"
                  >
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           5. Como Mantemos Os Dados Seguros
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        A AMBEV armazenará as informações coletadas em seu Site
                        em servidores próprios ou por ela contratados. Ainda,
                        todas as informações coletadas serão criptografadas e
                        anonimizadas, de modo a garantir a segurança das
                        informações e a dissociação de dados pessoais
                        eventualmente coletados.
                        <br />
                        <br />A AMBEV utiliza os meios razoáveis de mercado e
                        legalmente requeridos para preservar a privacidade dos
                        dados coletados em seu Site. Desta forma, adota as
                        seguintes precauções, em observância às diretrizes sobre
                        padrões de segurança estabelecidas no Decreto nº
                        8.771/2016, tais como:
                        <br />
                        <br />
                        i. A AMBEV utiliza os métodos padrão e de mercado para
                        criptografar e anonimizar os dados coletados;
                        <br />
                        <br />
                        ii. A AMBEV possui proteção contra acesso não autorizado
                        a seus sistemas;
                        <br />
                        <br />
                        iii. A AMBEV somente autoriza o acesso de pessoas
                        previamente estabelecidas ao local onde são armazenadas
                        as informações coletadas;
                        <br />
                        <br />
                        iv. Aqueles que entrarem em contato com as informações
                        deverão se comprometer a manter sigilo absoluto. A
                        quebra do sigilo acarretará responsabilidade civil e o
                        responsável será responsabilizado nos moldes da
                        legislação brasileira; e
                        <br />
                        <br />
                        v. Manutenção do inventário indicando momento, duração,
                        identidade do funcionário, ou do responsável pelo acesso
                        e o arquivo objeto, com base nos registros de conexão e
                        de acesso a aplicações, conforme determinado no artigo
                        13 do Decreto nº 8.771/2016.
                        <br />
                        <br />A AMBEV adota os melhores esforços, no sentido de
                        preservar a privacidade dos dados dos Usuários.&nbsp;
                        <u>
                           Entretanto, nenhum site é totalmente seguro e a AMBEV
                           não pode garantir integralmente que todas as
                           informações que trafegam no Site não sejam alvo de
                           acessos não autorizados perpetrados por meio de
                           métodos desenvolvidos para obter informações de forma
                           indevida. Por esse motivo, nós incentivamos os
                           Usuários a tomar as medidas apropriadas para se
                           proteger, como, por exemplo, mantendo confidenciais
                           todos os nomes de usuário e senhas.
                           <br />
                           <br />
                           Também aproveitamos para informar que a AMBEV não tem
                           nenhuma ingerência ou responsabilidade em relação a
                           plataformas de terceiros, ainda que sejam parceiros
                           comerciais.
                        </u>
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           6. Retenção das Informações Coletadas
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        As informações coletadas pela AMBEV por meio do Site
                        serão automaticamente excluídas de seus servidores
                        quando deixarem de ser úteis para os fins para os quais
                        foram coletadas, ou quando o usuário solicitar a
                        eliminação de seus dados pessoais ou revogar o
                        consentimento concedido para o tratamento dos dados,
                        hipótese em que o acesso do Usuário ao Site será
                        interrompido.
                        <br />
                        <br />
                        Sem prejuízo, as informações poderão ser conservadas
                        para cumprimento de obrigação legal ou regulatória,
                        transferência a terceiro – desde que respeitados os
                        requisitos de tratamento de dados – e uso exclusivo da
                        AMBEV, vedado seu acesso por terceiro, desde que
                        anonimizadas.
                     </clb-text>
                  </div>

                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="yourRights"
                  >
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           7. Seus Direitos
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Em cumprimento à regulamentação aplicável, no que diz
                        respeito ao tratamento de dados pessoais, a AMBEV
                        respeita e garante ao Usuário, a possibilidade de
                        apresentação de solicitações baseadas nos seguintes
                        direitos:
                        <br />
                        <br />
                        i) a confirmação da existência de tratamento;
                        <br />
                        <br />
                        ii) o acesso aos dados;
                        <br />
                        <br />
                        iii) a correção de dados incompletos, inexatos ou
                        desatualizados;
                        <br />
                        <br />
                        iv) a anonimização, bloqueio ou eliminação de dados
                        desnecessários, excessivos ou tratados em
                        desconformidade;
                        <br />
                        <br />
                        v) a portabilidade de seus dados a outro fornecedor de
                        serviço ou produto, mediante requisição expressa pelo
                        Usuário;
                        <br />
                        <br />
                        vi) a eliminação dos dados tratados com consentimento do
                        Usuário;
                        <br />
                        <br />
                        vii) a obtenção de informações sobre as entidades
                        públicas ou privadas com as quais a AMBEV compartilhou
                        seus dados;
                        <br />
                        <br />
                        viii) a informação sobre a possibilidade de não fornecer
                        o seu consentimento, bem como de ser informado sobre as
                        consequências, em caso de negativa;
                        <br />
                        <br />
                        ix) a revogação do consentimento.
                        <br />
                        <br />
                        Parte destes direitos poderá ser exercida diretamente
                        pelo Usuário, a partir da gestão de informações sobre
                        sua conta, enquanto outros dependerão do envio de
                        solicitação para posterior avaliação e adoção de demais
                        providências pela AMBEV.
                        <br />
                        <br />
                        Caso necessite de qualquer auxílio para exercer seus
                        direitos, o Usuário pode entrar em contato com a AMBEV,
                        conforme orientações nesta Política.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           8. Fale Conosco!
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        O Usuário pode entrar em contato com o nosso Encarregado
                        pelo Tratamento dos Dados Pessoais pelo e-mail:
                        <br />
                        dataprivacy@ambev.com.br.
                        <br />
                        <br />
                        Esse canal de comunicação é exclusivo para (i) sanar
                        quaisquer dúvidas sobre esta Política, (ii) apresentar
                        uma reclamação sobre possível violação das leis de
                        proteção de dados e (iii) para atendimento de
                        solicitações relacionadas aos seus direitos, conforme
                        listado no Item VII desta Política.
                        <br />
                        <br />
                        Para o acesso, correção de dados incompletos, inexatos
                        ou desatualizados e dúvidas sobre a AMBEV, você poderá
                        solicitar pelo formulário disponível em{' '}
                        <a
                           href="https://www.ambev.com.br/meu_cadastro"
                           target="_blank"
                        >
                           https://www.ambev.com.br/meu_cadastro
                        </a>{' '}
                        e em{' '}
                        <a
                           href="https://www.ambev.com.br/contato."
                           target="_blank"
                        >
                           https://www.ambev.com.br/contato.
                        </a>
                        <br />
                        <br />O Usuário fica ciente de que a exclusão das
                        informações essenciais para gestão de sua conta junto à
                        AMBEV poderá implicar o término de seu cadastro, com
                        consequente cancelamento dos serviços então prestados.
                        <br />
                        <br />A AMBEV empreenderá todos os esforços para atender
                        tais solicitações no menor tempo possível, sempre
                        observando a legislação aplicável. Em alguns casos, as
                        solicitações de exclusão poderão deixar de ser atendidas
                        imediatamente, em razão de determinação legal.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           9. Legislação, Foro e Atualização
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Esta Política será regida, interpretada e executada de
                        acordo com as Leis da República Federativa do Brasil,
                        especialmente a Lei nº 13.709/2018 – Lei Geral de
                        Proteção de Dados, independentemente das Leis de outros
                        estados ou Países, sendo competente o foro de domicílio
                        do Usuário para dirimir qualquer dúvida decorrente deste
                        documento.
                        <br />
                        <br />
                        Esta Política pode ser atualizada a qualquer momento por
                        razões legais, pelo uso de novas tecnologias e
                        funcionalidades e sempre que a AMBEV entender que as
                        alterações são necessárias, o que será cientificado aos
                        Usuários por meio do Site, podendo ainda ser encaminhado
                        por e-mail, caso o Usuário tenha optado por receber
                        comunicações da AMBEV. Após ciência das modificações
                        informadas, ao continuar utilizando o Site, o Usuário
                        concorda automaticamente com os novos termos da Política
                        e, caso não concorde com as alterações, pedimos que
                        deixe de acessar o Site.
                        <br />
                        <br />
                        Atualizado em 5 de setembro de 2022.
                     </clb-text>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}
