import api from '@services/api';
import { IUserByPhone, IUserProfile, IUserProps } from './user.types';

export async function updateUser(
   user: IUserProps,
): Promise<IUserProfile | null> {
   try {
      const resp = await api.post(`users`, user);
      return resp.data as IUserProfile;
   } catch (error) {
      return null;
   }
}

export async function updateUserPhone(
   userId: number | undefined,
   phone: string,
) {
   try {
      await api.post(`users/phone`, { id: userId, phone: phone });
      return { phoneUpdated: true };
   } catch (error) {
      return { phoneUpdated: false };
   }
}

export async function updateUserActive(
   userId: number | undefined,
   userActive: boolean,
) {
   try {
      const res = await api.post(`users/active`, {
         id: userId,
         isActive: userActive,
      });
      return { userActiveUpdated: true };
   } catch (error) {
      return { userActiveUpdated: false };
   }
}

export async function getUser(): Promise<IUserProfile | null> {
   try {
      const resp = await api.get(`users/profile`);
      return resp.data;
   } catch (error) {
      return null;
   }
}

export async function getUserByPhone(
   phone: string,
): Promise<IUserByPhone | null> {
   try {
      const resp = await api.get(`users/${phone}`);
      return resp.data;
   } catch (error) {
      return null;
   }
}
