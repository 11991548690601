import styles from './Help.module.scss';

import { Header } from '@components/Header';
import { AgeGate } from '@components/AgeGate';
import { Footer } from '@components/Footer';

import aboutBoraJSON from './aboutBora.json';
import aboutConectionsJSON from './aboutConections.json';

import parseHtml from 'html-react-parser';

import React from 'react';
export function Help() {
   return (
      <>
         <div className={styles.helpContainer}>
            <Header />
            <section className={styles.container}>
               <div className={styles.content}>
                  <div className={styles.wrapper}>
                     <div className={styles.headingWrapper}>
                        <clb-heading-team size="xl">
                           Perguntas<br></br> frequentes
                        </clb-heading-team>
                     </div>
                     <div className={styles.accordionGroup}>
                        <div className={styles.accordionWrapper}>
                           <div className={styles.accordionHeadingWrapper}>
                              <clb-heading-team size="sm">
                                 Sobre o Bora
                              </clb-heading-team>
                           </div>
                           {aboutBoraJSON.map(item => (
                              //@ts-ignore
                              <clb-accordion-team
                                 key={item.id}
                                 label={item.label}
                                 id={item.id}
                              >
                                 {parseHtml(item.description)}
                              </clb-accordion-team>
                           ))}
                        </div>
                        <div className={styles.accordionWrapper}>
                           <div className={styles.accordionHeadingWrapper}>
                              <clb-heading-team size="sm">
                                 Sobre as conexões
                              </clb-heading-team>
                           </div>
                           {aboutConectionsJSON.map(item => (
                              //@ts-ignore
                              <clb-accordion-team
                                 key={item.id}
                                 label={item.label}
                                 id={item.id}
                              >
                                 {parseHtml(item.description)}
                              </clb-accordion-team>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <Footer isHideNavigation />
         </div>
         <AgeGate />
      </>
   );
}
