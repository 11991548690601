import styles from './ConnectionAnimatedDots.module.scss';

export function ConnectionAnimatedDots() {
   return (
      <div className={styles.dotsAnimation}>
         <div className={styles.dot}></div>
         <div className={styles.dot}></div>
         <div className={styles.dot}></div>
      </div>
   );
}
