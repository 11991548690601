import React, { createContext, useContext } from 'react';

import { IUser, IAuthContext, Props } from './useAuth.types';

export const AuthContext = createContext({} as IAuthContext);

export const KEY_SAVE_USER = `${process.env.REACT_APP_NAME_BD}user`;

export function AuthProvider({ children }: Props) {
   const [user, setUser] = React.useState<IUser>();

   function saveUser(_user: IUser) {
      removeUser();

      const userSave = JSON.stringify(_user);
      localStorage.setItem(KEY_SAVE_USER, userSave);

      setUser(_user);
   }

   function getUser(): IUser | null {
      const userSaved = localStorage.getItem(KEY_SAVE_USER);

      if (userSaved) {
         const userLogin = JSON.parse(userSaved) as IUser;
         setUser(userLogin);

         return userLogin;
      } else {
         return null;
      }
   }

   function removeUser() {
      localStorage.removeItem(KEY_SAVE_USER);
   }

   function checkUserIsLogged(): boolean {
      const _user = getUser();

      if (_user?.name && _user?.name.length > 0) {
         setUser(_user);
         return true;
      } else {
         return false;
      }
   }

   function checkUserIsPDV(): boolean {
      const _user = getUser();

      if (_user?.name && _user?.name.length > 0 && _user.level === 'pdv') {
         return true;
      } else {
         return false;
      }
   }

   return (
      <AuthContext.Provider
         value={{
            user,
            saveUser,
            getUser,
            removeUser,
            checkUserIsLogged,
            checkUserIsPDV,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
}

export function useAuth() {
   return useContext(AuthContext);
}
