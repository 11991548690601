import React from 'react';
import styles from './Hero.module.scss';
import pattern from '@assets/pattern02.svg';
import logoAmbev from '@assets/ambevLogo.svg';
import logoBees from '@assets/beesLogo.svg';

import { useAuth } from '@hooks/useAuth';
import { useDrawer } from '@hooks/useDrawer';

import '@meiuca/celebration-team-bora';

export function Hero() {
   const auth = useAuth();
   const drawer = useDrawer();

   const [userIsLogged, setUserIsLogged] = React.useState(false);

   React.useEffect(() => {
      const _userIsLogged = auth.checkUserIsLogged();
      setUserIsLogged(_userIsLogged);
   }, []);

   function openLoginPage() {
      drawer.openLoginDrawer();
   }

   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <div className={styles.wrapper}>
               <div className={styles.title}>
                  <clb-heading-team size="display">
                     Bora aprender. Bora se conectar.
                  </clb-heading-team>
                  <div className={styles.button}>
                     {!userIsLogged && (
                        <clb-button aria-label="Entrar" onClick={openLoginPage}>
                           Entrar
                        </clb-button>
                     )}
                  </div>
               </div>
               <img className={styles.image} src={pattern} alt="hero image" />
               <img
                  className={styles.imageAbsolute}
                  src={pattern}
                  alt="hero image"
               />
            </div>
            <div className={styles.heroFooter}>
               <clb-text>Uma iniciativa social</clb-text>
               <div className={styles.logos}>
                  <img src={logoAmbev} alt="logo ambev" />
                  <img src={logoBees} alt="logo bees" />
               </div>
            </div>
         </div>
      </section>
   );
}
