import styles from './CheckboxTerms.module.scss';

interface Props {
   checked: boolean;
   onClick: () => void;
}

export function CheckboxTerms({ onClick, checked }: Props) {
   return (
      <div onClick={onClick} className={styles.checkbox}>
         <input type="checkbox" checked={checked} />
         <span>
            Concordo com os{' '}
            <a href="/terms-of-use" target="_blank">
               Termos de Uso
            </a>{' '}
            e estou ciente da{' '}
            <a href="/privacy-policy" target="_blank">
               Política de Privacidade
            </a>
            .
         </span>
      </div>
   );
}
