import axios from 'axios';
import { ICep } from '@components/ConfirmAddress';
import { clearString } from '@utils/formatString';

interface Props {
   cep: string;
}

export async function getCep({ cep }: Props): Promise<ICep | null> {
   try {
      if (clearString(cep).length < 8) {
         return null;
      }

      const { data: cepData } = await axios.get(
         `https://viacep.com.br/ws/${cep}/json/`,
      );

      if ('erro' in cepData) {
         return null;
      }

      return cepData as ICep;
   } catch (error) {
      return null;
   }
}
