import React from 'react';
import styles from './ProfileUser.module.scss';

import { filterWords } from '@utils/filterBadwords';
import { ProfileData } from '@components/ProfileData';
import * as userService from '@services/user';

import {
   clearString,
   convertDate,
   formatCep,
   formatDate,
   formatPhone,
} from '@utils/formatString';

import { IUserProfile } from '@services/user.types';
import { getCep } from '@services/cep';
import { PublicProfileModal } from '@components/PublicProfileModal';
import { InputCheckbox } from '@components/InputCheckbox';
import { useToast } from '@hooks/useToast';

interface Props {
   onUpdateUser: (user: IUserProfile) => Promise<IUserProfile | null>;
   userDataSelected: IUserProfile;
   onUpdateUserSelected: (userDataSelected: IUserProfile) => void;
   onUpdatePublicProfile: (isActive: boolean) => void;
}

export function PersonalData({
   onUpdateUser,
   userDataSelected,
   onUpdateUserSelected,
   onUpdatePublicProfile,
}: Props) {
   const [name, setName] = React.useState('');
   const [phone, setPhone] = React.useState('');
   const [dateOfBirth, setDateOfBirth] = React.useState('');
   const [cep, setCep] = React.useState('');
   const [about, setAbout] = React.useState('');

   const [isLoading, setIsLoading] = React.useState(false);

   const [profilePublic, setProfilePublic] = React.useState<
      boolean | undefined
   >(undefined);
   const [showModalDisableProfile, setShowModalDisableProfile] =
      React.useState(false);

   const toast = useToast();

   const profilePublicChecked = profilePublic ? { checked: true } : {};

   const ASTERISC_REGEX: RegExp = /\*/g;
   const isCensored = ASTERISC_REGEX.test(about);

   const hasBadwordError = isCensored
      ? {
           error: true,
           'helper-text': 'Palavra inadequada. Revise para continuar!',
        }
      : {};

   React.useEffect(() => {
      setName(userDataSelected.name);
      setPhone(formatPhone(userDataSelected.phone));
      setDateOfBirth(formatDate(userDataSelected.dateOfBirth));
      setCep(formatCep(userDataSelected.address?.zipCode));
      setAbout(userDataSelected.description);
      setProfilePublic(userDataSelected.isActive);
   }, []);

   async function _onUpdateUser(): Promise<boolean> {
      const cepData = await getCep({ cep });
      if (!cepData) return false;

      const _opportunitiesIds = userDataSelected.opportunities
         ? userDataSelected.opportunities.map(i => i.id)
         : userDataSelected.opportunitiesIds;

      const _interestsIds = userDataSelected.interests
         ? userDataSelected.interests.map(i => i.id)
         : userDataSelected.interestsIds;

      const userUpdateData: IUserProfile = {
         ...userDataSelected,
         ...{
            name,
            phone: clearString(phone),
            dateOfBirth: convertDate(dateOfBirth),
            description: about,
            isActive: profilePublic,
            address: {
               zipCode: clearString(cep),
               street: cepData?.logradouro,
               district: cepData?.bairro,
               city: cepData?.localidade,
               uf: cepData?.uf,
            },
            opportunitiesIds: _opportunitiesIds,
            interestsIds: _interestsIds,
         },
      };

      const userUpdated = await onUpdateUser(userUpdateData);

      if (!userUpdated) {
         return false;
      } else {
         onUpdateUserSelected(userUpdated);
      }

      return true;
   }

   function publicProfileChecked(isActive: boolean) {
      if (isActive) {
         onUpdatePublicProfile(true);
      } else {
         setShowModalDisableProfile(true);
      }

      setProfilePublic(isActive);
   }

   async function handleUpdateProfile(
      userProfile: IUserProfile,
   ): Promise<IUserProfile | null> {
      const _userUpdated = await userService.updateUser(userProfile);

      if (!_userUpdated) {
         return null;
      } else {
         return _userUpdated;
      }
   }

   async function handleModalConfirm({ message, reasonId }: any) {
      const userUpdated = await handleUpdateProfile({
         ...userDataSelected,
         isActive: profilePublic,
         reason: {
            id: reasonId,
            comment: message,
         },
      } as IUserProfile);

      if (!userUpdated) {
         toast.show({
            title: `Ocorreu um erro ao enviar sua mensagem!`,
            message: 'Por favor, tente novamente!',
            type: 'negative',
         });
      } else {
         toast.show({
            title: 'Perfil público atualizado com sucesso!',
            type: 'positive',
         });
      }

      setIsLoading(false);

      setShowModalDisableProfile(false);
   }

   function handleCancelInativeProfile() {
      setShowModalDisableProfile(false);
      setProfilePublic(true);
   }

   function handleCloseModal(isOpen: boolean) {
      setShowModalDisableProfile(isOpen);
      setProfilePublic(true);
   }

   return (
      <>
         <div className={styles.profilePage}>
            <div className={styles.containerPersonalData}>
               <div className={styles.profileInfoLeftContainer}>
                  <div className={styles.profileName}>
                     <ProfileData
                        title="Nome"
                        description={userDataSelected.name}
                        onUpdate={_onUpdateUser}
                        hasError={name.length < 3}
                     >
                        <clb-input-text
                           label="Nome"
                           value={name}
                           placeholder="Digite aqui..."
                           onChange={(e: any) => setName(e.target.value)}
                        ></clb-input-text>
                     </ProfileData>
                  </div>

                  <div className={styles.profileCellphone}>
                     <div className={styles.profileCellphone}>
                        <ProfileData
                           title="Celular"
                           description={formatPhone(userDataSelected.phone)}
                           onUpdate={_onUpdateUser}
                           hasError={phone.length < 15}
                        >
                           <clb-input-text
                              label="Celular"
                              value={phone}
                              placeholder="(00) 9 0000-0000"
                              onChange={(e: any) => setPhone(e.target.value)}
                              mask="phone"
                           ></clb-input-text>
                        </ProfileData>
                     </div>
                  </div>
                  <div className={styles.profileBirthDate}>
                     <ProfileData
                        title="Data de nascimento"
                        description={formatDate(userDataSelected.dateOfBirth)}
                        onUpdate={_onUpdateUser}
                        hasError={dateOfBirth.length < 10}
                     >
                        <clb-input-text
                           label="Data de nascimento"
                           value={dateOfBirth}
                           placeholder="00/00/0000"
                           onChange={(e: any) => setDateOfBirth(e.target.value)}
                           mask="date"
                        ></clb-input-text>
                     </ProfileData>
                  </div>
               </div>

               <div className={styles.profileInfoRightContainer}>
                  <div className={styles.profileCep}>
                     <ProfileData
                        title="CEP"
                        description={formatCep(
                           userDataSelected.address?.zipCode,
                        )}
                        onUpdate={_onUpdateUser}
                        hasError={cep.length < 9}
                     >
                        <clb-input-text
                           label="CEP"
                           value={cep}
                           placeholder="00000-000"
                           onChange={(e: any) => setCep(e.target.value)}
                           mask="cep"
                        ></clb-input-text>
                     </ProfileData>
                  </div>

                  <div className={styles.profileAboutYou}>
                     <ProfileData
                        title="Sobre você"
                        description={userDataSelected.description}
                        onUpdate={_onUpdateUser}
                        hasError={isCensored || about.length < 4}
                     >
                        <clb-input-textarea
                           label="Descrição"
                           name="description"
                           placeholder="Conte sobre você..."
                           maxlength={140}
                           // @ts-ignore
                           value={about}
                           onChange={e =>
                              // @ts-ignore
                              setAbout(filterWords.filter(e.target.value))
                           }
                           {...hasBadwordError}
                        ></clb-input-textarea>
                     </ProfileData>
                  </div>
               </div>

               <div className={styles.publicProfile}>
                  <InputCheckbox
                     label={'Perfil público'}
                     name={'checkbox'}
                     id={'checkbox'}
                     checked={false}
                     disabled={false}
                     required={false}
                     onChange={() => publicProfileChecked(!profilePublic)}
                     {...profilePublicChecked}
                  />

                  <clb-text size="default">
                     Quando seu perfil está público ele fica visível na vitrine
                     de conexões.
                  </clb-text>
               </div>

               <PublicProfileModal
                  id="modalDisableProfile"
                  isOpen={showModalDisableProfile}
                  onClose={handleCloseModal}
                  _userData={userDataSelected}
                  _setPublicProfile={setProfilePublic}
                  _publicProfile={profilePublic}
                  _setShowModal={setShowModalDisableProfile}
                  _isLoading={isLoading}
                  _setIsLoading={setIsLoading}
                  buttonSecondary={{
                     onClick: handleCancelInativeProfile,
                  }}
                  buttonPrimary={{
                     onClick: handleModalConfirm,
                  }}
               />
            </div>
         </div>
      </>
   );
}
