import React from 'react';
import { motion } from 'framer-motion';

interface Props {
   children: React.ReactNode;
   drawerPage: JSX.Element;
   isOpen: boolean;
}

export function DrawerAnimationScreen({ children, drawerPage, isOpen }: Props) {
   const transitionAnimated = {
      initial: { x: isOpen ? window.innerWidth : 0 },
      animate: { x: isOpen ? 0 : window.innerWidth },
      exit: { x: isOpen ? window.innerWidth : 0 },
   };

   const [animation, setAnimation] = React.useState(transitionAnimated);
   const [showChildren, setShowChildren] = React.useState(true);
   const [showDrawerPage, setShowDrawerPage] = React.useState(false);

   const TIMEOUT_CHANGE_PAGE = window.innerWidth > 768 ? 780 : 480;
   const DURATION_ANIMATE = window.innerWidth > 768 ? 0.8 : 0.5;

   React.useEffect(() => {
      if (isOpen) {
         setShowDrawerPage(true);
         setTimeout(() => setShowChildren(false), TIMEOUT_CHANGE_PAGE);
      } else if (!isOpen) {
         setShowChildren(true);
         setTimeout(() => setShowDrawerPage(false), TIMEOUT_CHANGE_PAGE);
      }

      setAnimation(transitionAnimated);
   }, [isOpen]);

   return (
      <>
         {showChildren && children}

         {showDrawerPage && (
            <motion.div
               {...animation}
               transition={{ duration: DURATION_ANIMATE, ease: 'easeOut' }}
               style={{
                  position: 'absolute',
                  top: 0,
                  zIndex: 110,
                  minWidth: '100%',
                  height: '100vh',
                  maxHeight: '-webkit-fill-available',
                  background: '#FFF',
               }}
            >
               {drawerPage}
            </motion.div>
         )}
      </>
   );
}
