import styles from './ConfirmAddress.module.scss';

export interface ICep {
   bairro?: string;
   cep?: string;
   complemento?: string;
   ddd?: string;
   gia?: string;
   ibge?: string;
   localidade?: string;
   logradouro?: string;
   siafi?: string;
   uf?: string;
}

interface Props {
   onClick: () => void;
   onEdit: () => void;
   cep?: ICep;
   isLoading?: boolean;
}

export function ConfirmAddress({ onClick, onEdit, cep, isLoading }: Props) {
   const isLoadingActive = isLoading ? { loading: true } : {};

   function onValidated() {
      if (onClick) onClick();
   }

   function handleEditAddress() {
      onEdit();
   }

   return (
      <div>
         <clb-heading-team size="sm">
            Confirme sua rua, <br />
            por favor.
         </clb-heading-team>

         <div className={styles.spacingAddress}>
            <div>
               <clb-text>{cep?.logradouro}</clb-text>
               <clb-text>
                  {cep?.localidade} - {cep?.uf}
               </clb-text>
            </div>

            <clb-link onClick={handleEditAddress}>Editar</clb-link>
         </div>

         <clb-button {...isLoadingActive} onClick={onValidated}>
            Próximo
         </clb-button>
      </div>
   );
}
