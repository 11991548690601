import { ConnectionAnimatedDots } from '@components/ConnectionAnimatedDots';
import styles from './AdminSearchingFor.module.scss';

export function AdminSearchingFor() {
   return (
      <div className={styles.container}>
         <div className={styles.dotsAndHeadingWrapper}>
            <ConnectionAnimatedDots />
            <clb-heading-team size="2xs">Procurando perfil...</clb-heading-team>
         </div>
      </div>
   );
}
