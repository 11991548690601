import styles from './AdminUserCard.module.scss';
import { IUserByPhone } from '@services/user.types';

import { formatDate, formatPhone } from '@utils/formatString';

interface Props {
   userData: IUserByPhone;
   onClickSecondaryButton: () => void;
   onClickPrimaryButton: () => void;
}

export function AdminUserCardComponent({
   userData,
   onClickSecondaryButton,
   onClickPrimaryButton,
}: Props) {
   return (
      <clb-shape>
         <div className={styles.container}>
            <div className={styles.tagsWrapper}>
               <div className={styles.tag}>
                  {userData?.isPdv ? (
                     <clb-tag-status label="PDV" />
                  ) : (
                     <clb-tag-status label="Pessoa candidata" />
                  )}
               </div>

               <div className={styles.tag}>
                  {userData?.isTester && userData?.isPdv ? (
                     <clb-tag type="warning">Perfil de teste</clb-tag>
                  ) : (
                     ''
                  )}
               </div>
            </div>

            <div className={styles.headerDesktop}>
               <div className={styles.tagsWrapperDesktop}>
                  <div className={styles.tag}>
                     {userData?.isPdv ? (
                        <clb-tag-status label="PDV" />
                     ) : (
                        <clb-tag-status label="Pessoa candidata" />
                     )}
                  </div>

                  <div className={styles.tag}>
                     {userData?.isTester && userData?.isPdv ? (
                        <clb-tag type="warning">Perfil de teste</clb-tag>
                     ) : (
                        ''
                     )}
                  </div>
               </div>
               <div className={styles.buttonWrapperDesktop}>
                  {userData?.isPdv && (
                     <clb-button
                        type="secondary"
                        onClick={onClickSecondaryButton}
                     >
                        {userData?.isTester
                           ? 'Desabilitar perfil teste'
                           : 'Habilitar perfil teste'}
                     </clb-button>
                  )}
                  <clb-button type="primary" onClick={onClickPrimaryButton}>
                     Editar celular
                  </clb-button>
               </div>
            </div>

            <div className={styles.userInfoWrapper}>
               <div className={styles.leftContainer}>
                  <div className={styles.name}>
                     <clb-heading size="2xs">{userData?.name}</clb-heading>
                     <clb-text>Nome</clb-text>
                  </div>

                  <div className={styles.celular}>
                     <clb-heading size="2xs">
                        {formatPhone(userData?.phone)}
                     </clb-heading>
                     <clb-text>Celular</clb-text>
                  </div>
               </div>

               <div className={styles.rightContainer}>
                  {!userData?.isPdv && (
                     <div className={styles.email}>
                        <>
                           <clb-heading size="2xs">
                              {userData?.email ? userData.email : '-'}
                           </clb-heading>
                           <clb-text>E-mail</clb-text>
                        </>
                     </div>
                  )}

                  <div
                     className={
                        !userData?.isPdv
                           ? styles.birthDate
                           : styles.birthDatePdv
                     }
                  >
                     <clb-heading size="2xs">
                        {formatDate(userData?.dateOfBirth)}
                     </clb-heading>
                     <clb-text>Data de nascimento</clb-text>
                  </div>
               </div>
            </div>
         </div>

         <div className={styles.buttonWrapper}>
            {userData?.isPdv && (
               <clb-button type="secondary" onClick={onClickSecondaryButton}>
                  {userData?.isTester
                     ? 'Desabilitar perfil teste'
                     : 'Habilitar perfil teste'}
               </clb-button>
            )}

            <clb-button type="primary" onClick={onClickPrimaryButton}>
               Editar celular
            </clb-button>
         </div>
      </clb-shape>
   );
}
