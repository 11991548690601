import filter from './filterBadwordsConfig/filter.json';
import aliases from './filterBadwordsConfig/aliases.json';
const Piii = require('piii');

export const filterWords = new Piii({
   filters: [...Object.values(filter)],
   aliases: aliases,
   repeated: true,
   censor: (badWord: string) => {
      return badWord.charAt(0) + '*'.repeat(badWord.length - 1);
   },
});
