import React from 'react';
import styles from './Login.module.scss';

import { validatePhone } from '@utils/validatePhone';
import * as loginService from '@services/login';
import { useToast } from '@hooks/useToast';

export type TtypeUser = 'is-user' | 'waiting-list';

interface Props {
   onClick: (firstAccess: TtypeUser) => void;
   value: string;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export function Login({ onClick, value, onChange }: Props) {
   const toast = useToast();

   const [isLoading, setIsLoading] = React.useState(false);
   const [isError, setIsError] = React.useState(false);

   const loading = isLoading ? { loading: true } : {};
   const error = isError
      ? { error: true, 'helper-text': 'Celular inválido!' }
      : {};

   async function handleContinue() {
      if (isLoading) return;

      if (validatePhone(value)) {
         setIsError(true);
         return;
      }

      setIsLoading(true);
      setIsError(false);

      const phone = value.replace(/\D/g, '');
      const userPhone = await loginService.validatePhone({ phone });

      if (!userPhone) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });

         setIsLoading(false);
         return;
      }

      if (userPhone.accessBlocked) {
         toast.show({
            title: 'Você atingiu o limite de tentativas de acesso.',
            message: 'Seu número de celular ficará bloqueado por 1 hora.',
            type: 'warning',
         });

         setIsLoading(false);
         return;
      }

      if (userPhone?.isUser) {
         onClick('is-user');
      } else if (!userPhone?.isUser) {
         onClick('waiting-list');
      } else {
         setIsError(true);
      }

      setIsLoading(false);
   }

   return (
      <div className={styles.container}>
         <clb-emoji type="smiling-face"></clb-emoji>

         <div className={styles.spacingHeading} />
         <clb-heading-team size="lg">Entrar</clb-heading-team>

         <div className={styles.spacingText} />
         <clb-text>
            Digite seu celular para entrar <br />
            ou se cadastrar.
         </clb-text>

         <div className={styles.spacingInput} />
         <clb-input-text
            label="Celular"
            placeholder="(00) 9 0000-0000"
            value={value}
            onChange={onChange}
            mask="phone"
            {...error}
         ></clb-input-text>

         <div className={styles.spacingButton} />
         <clb-button onClick={handleContinue} {...loading}>
            Continuar
         </clb-button>
      </div>
   );
}
