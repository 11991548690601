import { useEffect, useState } from 'react';
import styles from './WhichOportunities.module.scss';

import * as OpportunityService from '@services/opportunity';
import { Props, IOptionOportunities } from './WhichOportunities.types';
import { ConnectionAnimatedDots } from '@components/ConnectionAnimatedDots';

export function WhichOportunities({ onClick, itemLooking }: Props) {
   const [isLoading, setIsLoading] = useState(false);

   const [options, setOptions] = useState<IOptionOportunities[]>([]);
   const [optionsSelected, setOptionsSelected] = useState<
      IOptionOportunities[]
   >([]);

   const isDisabled = optionsSelected.length > 0 ? {} : { disabled: true };

   useEffect(() => {
      setOptions([]);
      setOptionsSelected([]);

      getOpportunities();
   }, [itemLooking.id]);

   async function getOpportunities() {
      if (isLoading) return;
      setIsLoading(true);

      const opportunityData = await OpportunityService.getOpportunityByInterest(
         { interestId: itemLooking.id },
      );

      if (opportunityData.length === 0) {
         return onClick([]);
      }

      setOptions(opportunityData);
      setIsLoading(false);
   }

   const handleCheckbox = (item: IOptionOportunities) => {
      if (optionsSelected.includes(item)) {
         const list = optionsSelected.filter(i => i !== item);
         setOptionsSelected(list);
      } else {
         setOptionsSelected([...optionsSelected, item]);
      }
   };

   function onValidated() {
      onClick(optionsSelected);
   }

   return (
      <div className={styles.container}>
         <div className={styles.spacingText}>
            <clb-text size="sm">
               <b>{itemLooking.name}</b>
            </clb-text>

            <div className={styles.title}>
               <clb-heading-team size="sm" displayRole="h3">
                  Você busca oportunidades <br />
                  em quais áreas?
               </clb-heading-team>
            </div>

            <clb-text>Selecione uma ou mais opções.</clb-text>
         </div>

         <div
            className={`${styles.spacingOptions} ${
               isLoading ? {} : styles.spacingOptionsLoadingFinish
            }`}
         >
            {isLoading ? (
               <div className={styles.loading}>
                  <ConnectionAnimatedDots />
               </div>
            ) : (
               options.length > 0 &&
               options.map(item => {
                  const isChecked = optionsSelected.includes(item) && {
                     checked: true,
                  };

                  return (
                     <div className={styles.option} key={item.id}>
                        <clb-checkbox
                           id={`oportunity-${item.id}`}
                           name={`oportunity-${item.id}`}
                           label={item.name}
                           onClick={() => handleCheckbox(item)}
                           {...isChecked}
                        ></clb-checkbox>
                     </div>
                  );
               })
            )}
         </div>

         {!isLoading && (
            <div className={styles.button}>
               <clb-button
                  aria-label="Entrar"
                  onClick={onValidated}
                  {...isDisabled}
               >
                  Próximo
               </clb-button>
            </div>
         )}
      </div>
   );
}
