import React from 'react';
import styles from './ProfilePdv.module.scss';

import { useNavigate } from 'react-router-dom';

import { ProfileData } from '@components/ProfileData';
import { NeedHelp } from '@components/NeedHelp';
import { ModalButton } from '@components/ModalButton';
import { Footer } from '@components/Footer';
import { Load } from '@components/Load';

import {
   clearString,
   convertDate,
   formatCnpjCpf,
   formatDate,
   formatPhone,
} from '@utils/formatString';

import * as userService from '@services/user';
import { IUserProfile } from '@services/user.types';

import { useToast } from '@hooks/useToast';
import { useAuth } from '@hooks/useAuth';
import { useDrawer } from '@hooks/useDrawer';

import validateCnpj from '@utils/validateCnpj';
import validateCpf from '@utils/validateCpf';

import { CnpjCpf } from './CnpjCpf';

export function ProfilePdv() {
   const navigate = useNavigate();
   const toast = useToast();
   const auth = useAuth();
   const drawer = useDrawer();

   const [userData, setUserData] = React.useState<IUserProfile | null>(null);
   const [name, setName] = React.useState('');
   const [phone, setPhone] = React.useState('');
   const [dateOfBirth, setDateOfBirth] = React.useState('');
   const [cpfCnpj, setCpfCnpj] = React.useState('');
   const [cpfCnpjReload, setCpfCnpjReload] = React.useState(0);

   const [isLoading, setIsLoading] = React.useState(false);
   const [showExitProfile, setShowExitProfile] = React.useState(false);

   React.useEffect(() => {
      getUserProfile();
   }, []);

   async function getUserProfile() {
      setIsLoading(true);

      const _user = await userService.getUser();

      if (!_user) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });

         setIsLoading(false);
         return;
      }

      const _cpfCnpj = formatCnpjCpf(
         _user.cpf.length > 0 ? _user.cpf : _user.cnpj,
      );

      setUserData(_user);
      setName(_user.name);
      setPhone(formatPhone(_user.phone));
      setCpfCnpj(_cpfCnpj);
      setDateOfBirth(formatDate(_user.dateOfBirth));

      setIsLoading(false);
   }

   function handleLogout() {
      if (isLoading) return;

      toast.close();

      auth.removeUser();
      setShowExitProfile(false);

      navigate('/');
      drawer.removeDrawer();
   }

   function handleOnBack() {
      drawer.closeDrawer();
   }

   async function handleUpdateProfile(): Promise<boolean> {
      const cnpjNumbers = clearString(cpfCnpj);

      const isCPF = cnpjNumbers.length <= 11;

      const checkCpnjCpfIsValid = isCPF
         ? validateCpf.isValid(cnpjNumbers)
         : validateCnpj.isValid(cnpjNumbers);

      if (!checkCpnjCpfIsValid) {
         return false;
      }

      const _cpfCnpj = isCPF
         ? { cpf: cnpjNumbers, cnpj: '' }
         : { cpf: '', cnpj: cnpjNumbers };

      const userUpdateData = {
         ...userData,
         ...{
            name,
            phone: clearString(phone),
            dateOfBirth: convertDate(dateOfBirth),
            ..._cpfCnpj,
            opportunitiesIds: [],
            interestsIds: [],
         },
      };

      const _userUpdated = await userService.updateUser(userUpdateData);

      if (!_userUpdated) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });

         return false;
      } else {
         setUserData(_userUpdated);
      }

      return true;
   }

   function handleOpenModalLogout() {
      toast.close();
      setShowExitProfile(true);
   }

   return (
      <div className={styles.profilePage}>
         <div className={styles.container}>
            <header className={styles.header}>
               <clb-button-icon
                  icon="Arrow1"
                  size="lg"
                  aria-label="Voltar página"
                  onClick={handleOnBack}
               ></clb-button-icon>
            </header>

            <div className={styles.title}>
               <clb-heading-team size="xl">Meu perfil</clb-heading-team>
            </div>

            <div className={styles.profileContainer}>
               {isLoading && <Load title="" />}
               {userData && (
                  <>
                     <div className={styles.profileInfoLeftContainer}>
                        <div className={styles.profileName}>
                           <ProfileData
                              title="Nome"
                              description={userData.name}
                              onUpdate={handleUpdateProfile}
                              hasError={name.length < 1}
                           >
                              <clb-input-text
                                 label="Nome"
                                 value={name}
                                 placeholder="Digite aqui..."
                                 onChange={(e: any) => setName(e.target.value)}
                              ></clb-input-text>
                           </ProfileData>
                        </div>

                        <div className={styles.profileCellphone}>
                           <ProfileData
                              title="Celular"
                              description={formatPhone(userData.phone)}
                              onUpdate={handleUpdateProfile}
                              hasError={phone.length < 15}
                           >
                              <clb-input-text
                                 label="Celular"
                                 value={phone}
                                 placeholder="(00) 9 0000-0000"
                                 onChange={(e: any) => setPhone(e.target.value)}
                                 mask="phone"
                              ></clb-input-text>
                           </ProfileData>
                        </div>
                     </div>

                     <div className={styles.profileInfoRightContainer}>
                        <div className={styles.profileBirthDate}>
                           <ProfileData
                              title="Data de nascimento"
                              description={formatDate(userData.dateOfBirth)}
                              onUpdate={handleUpdateProfile}
                              hasError={dateOfBirth.length < 10}
                           >
                              <clb-input-text
                                 label="Data de nascimento"
                                 value={dateOfBirth}
                                 placeholder="00/00/0000"
                                 onChange={(e: any) =>
                                    setDateOfBirth(e.target.value)
                                 }
                                 mask="date"
                              ></clb-input-text>
                           </ProfileData>
                        </div>

                        <div className={styles.profileId}>
                           <ProfileData
                              title="CPF/CNPJ"
                              description={formatCnpjCpf(
                                 userData.cpf.length > 0
                                    ? userData.cpf
                                    : userData.cnpj,
                              )}
                              onUpdate={handleUpdateProfile}
                              onShowModal={() =>
                                 setCpfCnpjReload(prev => prev + 1)
                              }
                           >
                              <CnpjCpf
                                 value={cpfCnpj}
                                 valueDescription={formatCnpjCpf(
                                    userData.cpf.length > 0
                                       ? userData.cpf
                                       : userData.cnpj,
                                 )}
                                 onChange={setCpfCnpj}
                                 reload={cpfCnpjReload}
                              />
                           </ProfileData>
                        </div>
                     </div>
                  </>
               )}
            </div>

            <div className={styles.exitProfile}>
               <clb-link onClick={handleOpenModalLogout}>
                  Sair do perfil
               </clb-link>

               <ModalButton
                  id="modalCloseLogout"
                  isOpen={showExitProfile}
                  onClose={setShowExitProfile}
                  title={'Tem certeza que<br/> deseja sair do perfil?'}
                  description={'Esperamos te ver em breve por aqui!'}
                  emojiType={'disappointed-face'}
                  headingSize={window.innerWidth > 768 ? 'sm' : 'lg'}
                  buttonPrimary={{
                     label: 'Sim, quero sair!',
                     onClick: () => handleLogout(),
                  }}
                  buttonSecondary={{
                     label: 'Cancelar',
                     onClick: () => setShowExitProfile(false),
                  }}
               />
            </div>
         </div>

         <div className={styles.footer}>
            <NeedHelp />
            <Footer isHideNavigation />
         </div>
      </div>
   );
}
