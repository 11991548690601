import React from 'react';
import styles from './WaitingList.module.scss';

import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '@hooks/useToast';

import * as waitingListService from '@services/waitingList';

import { ContainerAuth } from '@components/ContainerAuth';
import { HowCallYou } from '@components/HowCallYou';
import { More18Years } from '@components/More18Years';
import { WhereFinded } from '@components/WhereFinded';
import { ModalButton } from '@components/ModalButton';
import { gaFormTracking } from '@utils/gaFormTracking';

export function WaitingList() {
   const navigate = useNavigate();
   const toast = useToast();
   const location = useLocation();

   const [name, setName] = React.useState('');
   const [recommendation, setRecommendation] = React.useState<string | null>();
   const [isLoadingFinish, setIsLoadingFinish] = React.useState(false);
   const [showModal, setShowModal] = React.useState(false);

   const [pageIndex, setPageIndex] = React.useState(0);
   const stepTitles = [
      'como-podemos-chamar-você',
      'voce-tem-mais-de-18-anos',
      'por-onde-conheceu-o-bora',
   ];

   const pages = [
      {
         page: (
            <HowCallYou
               value={name}
               onChange={e => setName(e.target.value)}
               onClick={handleNextPage}
            />
         ),
      },
      {
         page: <More18Years onClick={handleNextPage} />,
      },
      {
         page: (
            <WhereFinded
               onClick={setRecommendation}
               isLoading={isLoadingFinish}
            />
         ),
      },
   ];

   React.useEffect(() => {
      if (recommendation) {
         handleFinish();
      }
   }, [recommendation]);

   function handleNextPage() {
      gaFormTracking(
         'form',
         `waiting_list_form_step_${stepTitles[pageIndex]}`,
         'waiting_list_form',
      );
      setPageIndex((prev: number) => prev + 1);
   }

   function handleOnBack() {
      if (pageIndex === 0) {
         navigate('/');
      } else {
         setPageIndex((prev: number) => prev - 1);
      }
   }

   async function handleFinish() {
      if (!location.state.phone) return;
      setIsLoadingFinish(true);

      gaFormTracking(
         'form',
         `waiting_list_form_step_${stepTitles[pageIndex]}`,
         'waiting_list_form',
      );

      const phoneNumber = location.state.phone.replace(/\D/g, '');

      const data = await waitingListService.saveWaitingList({
         phone: phoneNumber,
         name: name,
         foundBy: recommendation!,
      });

      if (!data) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });

         setIsLoadingFinish(false);
         return;
      }

      setIsLoadingFinish(false);
      setShowModal(true);
   }

   function handleModal() {
      setShowModal(false);
      navigate('/');
   }

   return (
      <>
         <ContainerAuth
            title="Seu perfil"
            maxPages={pages.length}
            pageNumber={pageIndex + 1}
            description={`Etapa ${pageIndex + 1} de ${pages.length}`}
            showProgress
            onBack={handleOnBack}
         >
            <div className={styles.container}>
               <div className={styles.page}>{pages[pageIndex].page}</div>
            </div>
         </ContainerAuth>

         <ModalButton
            id="modalWaitingList"
            isOpen={showModal}
            onClose={handleModal}
            title={`Você já está na<br/>lista de espera!`}
            description={`Avisaremos você via SMS quando o Bora<br/>estiver disponível para o público.`}
            buttonPrimary={{
               label: 'Ir para tela inicial',
               onClick: () => handleModal(),
            }}
            emojiType="heart-face"
         />
      </>
   );
}
