import styles from './Piloto02.module.scss';
import image from '@assets/piloto03Image.png';
import icon01 from '@assets/pilotoIcon01.svg';
import icon02 from '@assets/pilotoIcon02.svg';
import icon03 from '@assets/pilotoIcon03.svg';

import '@meiuca/celebration-team-bora';

export function Piloto02() {
   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <div className={styles.imageSection}>
               <img
                  loading="lazy"
                  className={styles.image}
                  src={image}
                  alt="Uma pessoa de blusa branca"
               />
            </div>
            <div className={styles.contentSection}>
               <clb-tag-status label="Piloto 02"></clb-tag-status>
               <div className={styles.titleTextWrapper}>
                  <clb-heading-team size="xl">
                     Bora empreender com comida?
                  </clb-heading-team>
                  <clb-text size="lg">
                     O Bora Empreender com Comida é um programa realizado pela
                     Ambev em parceria com a Rede Mulher Empreendedora (RME)
                     para impactar mulheres que atuam com alimentação.
                  </clb-text>
               </div>
               <div className={styles.cardsWrapper}>
                  <div className={styles.card}>
                     <div className={styles.iconWrapper}>
                        <img
                           loading="lazy"
                           src={icon01}
                           alt="ícone de coração"
                        />
                     </div>
                     <clb-text>
                        <b>2.000</b> pequenas e microempreendedoras
                        qualificadas.
                     </clb-text>
                  </div>
                  <div className={styles.card}>
                     <div className={styles.iconWrapper}>
                        <img
                           loading="lazy"
                           src={icon02}
                           alt="ícone de coração"
                        />
                     </div>
                     <clb-text>
                        <b>São Luís-MA</b> e <b>Recife-PE</b>
                     </clb-text>
                  </div>
                  <div className={styles.card}>
                     <div className={styles.iconWrapper}>
                        <img
                           loading="lazy"
                           src={icon03}
                           alt="ícone de coração"
                        />
                     </div>
                     <clb-text>
                        Promover conexão das empreendedoras com negócios locais
                        e com a rede Ambev.
                     </clb-text>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}
