import React from 'react';
import styles from './FirstAccess.module.scss';

import { useNavigate, useLocation } from 'react-router-dom';

import * as userService from '@services/user';
import { getCep } from '@services/cep';

import { formatStringData } from '@utils/convertDate';
import { clearString } from '@utils/formatString';

import { ContainerAuth } from '@components/ContainerAuth';
import { HowCallYou } from '@components/HowCallYou';
import { More18Years } from '@components/More18Years';
import { BirthDate } from '@components/BirthDate';
import { WhereLive } from '@components/WhereLive';
import { ConfirmAddress, ICep } from '@components/ConfirmAddress';
import { LookingFor } from '@components/LookingFor';
import { IOptionLooking } from '@components/LookingFor/LookingFor.types';
import { AboutYou } from '@components/AboutYou';
import { CheckProfile } from '@components/CheckProfile';

import { useToast } from '@hooks/useToast';
import { useAuth } from '@hooks/useAuth';
import { useDrawer } from '@hooks/useDrawer';
import { gaFormTracking } from '@utils/gaFormTracking';

export function FirstAccess() {
   const navigate = useNavigate();
   const location = useLocation();
   const toast = useToast();
   const auth = useAuth();
   const drawer = useDrawer();

   const [phone, setPhone] = React.useState('');
   const [name, setName] = React.useState('');
   const [birthdate, setBirthdate] = React.useState('');
   const [cep, setCep] = React.useState('');
   const [about, setAbout] = React.useState('');
   const [cepData, setCepData] = React.useState<ICep | undefined>(undefined);
   const [oportunities, setOportunities] = React.useState<IOptionLooking[]>([]);

   const [isLoadingCEP, setIsLoadingCEP] = React.useState(false);
   const [isErrorCEP, setIsErrorCEP] = React.useState(false);

   const [isLoadingProfile, setIsLoadingProfile] = React.useState(false);

   const [pageIndex, setPageIndex] = React.useState(0);
   const stepTitles = [
      'como-podemos-chamar-você',
      'voce-tem-mais-de-18-anos',
      'agora-sua-data-de-nascimento',
      'onde-voce-mora',
      'confirme-sua-rua',
      'o-que-você-busca',
      'fale-um-pouco-sobre-você',
      'revise-seu-perfil',
   ];

   const pages = [
      {
         page: (
            <HowCallYou
               value={name}
               onChange={e => setName(e.target.value)}
               onClick={handleNextPage}
            />
         ),
      },
      {
         page: <More18Years onClick={handleNextPage} />,
      },
      {
         page: (
            <BirthDate
               value={birthdate}
               onChange={e => setBirthdate(e.target.value)}
               onClick={handleNextPage}
            />
         ),
      },
      {
         page: (
            <WhereLive
               value={cep}
               onChange={e => setCep(e.target.value)}
               onClick={handleCEP}
               isLoading={isLoadingCEP}
               isErrorCep={isErrorCEP}
            />
         ),
      },
      {
         page: (
            <ConfirmAddress
               onClick={handleNextPage}
               onEdit={handleOnBack}
               cep={cepData}
            />
         ),
      },
      {
         page: (
            <LookingFor
               onChangeSetValue={selectedOptions => {
                  setOportunities(selectedOptions);
                  handleNextPage();
               }}
            />
         ),
      },
      {
         page: (
            <AboutYou
               value={about}
               onClick={handleNextPage}
               onChange={text => setAbout(text)}
            />
         ),
      },
      {
         page: (
            <CheckProfile
               name={name}
               address={{
                  neighborhood: cepData?.logradouro!,
                  city: cepData?.localidade!,
               }}
               about={about}
               interests={oportunities}
               whatsapp={false}
               onClick={handleFinish}
               isLoadingProfile={isLoadingProfile}
            />
         ),
      },
   ];

   React.useEffect(() => {
      if (phone.length === 0) {
         setPhone(location.state.phone);
      }
   }, []);

   async function handleCEP() {
      setIsLoadingCEP(true);

      const response = await getCep({ cep });

      if (!response) {
         setIsLoadingCEP(false);
         setIsErrorCEP(true);
         return;
      }

      setIsLoadingCEP(false);
      setIsErrorCEP(false);
      setCepData(response);

      handleNextPage();
   }

   function handleNextPage() {
      gaFormTracking(
         'form',
         `first_access_form_step_${stepTitles[pageIndex]}`,
         'first_access_form',
      );
      setPageIndex(prev => prev + 1);
   }

   function handleOnBack() {
      if (pageIndex === 0) {
         navigate('/');
      } else {
         setPageIndex(prev => prev - 1);
      }
   }

   async function handleFinish() {
      if (phone.length === 0) return;

      setIsLoadingProfile(true);

      gaFormTracking(
         'form',
         `first_access_form_step_${stepTitles[pageIndex]}`,
         'first_access_form',
      );

      const date = formatStringData(birthdate);
      const cepNumber = clearString(cep);

      const oportunitiesIds: string[] = [];
      const goalsIds = oportunities.map(goal => {
         if (goal.oportunities) {
            goal.oportunities.forEach(oportunity => {
               oportunitiesIds.push(oportunity.id);
            });
         }
         return goal.id;
      });

      const _user = auth.getUser();

      if (!_user) {
         toast.show({
            title: 'Usuário não encontrado!',
            type: 'negative',
         });

         setIsLoadingProfile(false);
         return;
      }
      const data = await userService.updateUser({
         phone: phone,
         name: name,
         dateOfBirth: date,
         description: about,
         interestsIds: goalsIds,
         opportunitiesIds: oportunitiesIds,
         isActive: true,
         address: {
            zipCode: cepNumber,
            street: cepData?.logradouro,
            district: cepData?.bairro,
            city: cepData?.localidade,
            uf: cepData?.uf,
         },
      });

      if (!data) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });
         setIsLoadingProfile(false);
         return;
      }

      auth.saveUser({ ..._user, name });

      setIsLoadingProfile(false);

      navigate('/connections', { state: { showWelcomeUser: true } });
      drawer.closeDrawer();
   }

   return (
      <>
         <ContainerAuth
            title="Seu perfil"
            maxPages={pages.length}
            pageNumber={pageIndex + 1}
            description={`Etapa ${pageIndex + 1} de ${pages.length}`}
            showProgress
            onBack={handleOnBack}
         >
            <div className={styles.container}>
               <div className={styles.page}>{pages[pageIndex].page}</div>
            </div>
         </ContainerAuth>
      </>
   );
}
