import React from 'react';
import styles from './ModalButton.module.scss';
import parseHtml from 'html-react-parser';

import { Type as TEmoji } from '@meiuca/celebration-team-bora/dist/components/emoji/emoji.types';

type TSizesHeading = 'display' | '2xl' | 'xl' | 'lg' | 'sm' | 'xs' | '2xs';

interface IButton {
   label: string;
   onClick: () => void;
}

interface Props {
   id?: string;
   title: string;
   description: string;
   buttonPrimary: IButton;
   buttonSecondary?: IButton;
   emojiType?: TEmoji;
   hideButtonClose?: boolean;
   isOpen?: boolean;
   onClose?: (isOpen: boolean) => void;
   headingSize?: TSizesHeading;
   [key: string]: unknown;
}

export function ModalButton({
   id = 'Button',
   title,
   description,
   buttonPrimary,
   buttonSecondary,
   emojiType,
   hideButtonClose = false,
   isOpen = false,
   headingSize = 'lg',
   onClose,
}: Props) {
   const modalId = `modal${id
      .toLocaleLowerCase()
      .split(' ')
      .join('_')
      .split('/')
      .join('_')}`;

   React.useEffect(() => {
      if (onClose) {
         const modalElement = document.getElementById(modalId) as HTMLElement;

         modalElement?.addEventListener('click', e => {
            const targetTagName = (
               e.target as HTMLElement
            ).tagName.toLowerCase();

            if (targetTagName.includes('clb-button-icon')) {
               if (isLoading) return;
               setTimeout(() => onClose(false), 400);
            }

            if (targetTagName.includes('clb-button')) {
               validateClbButton(e.target as HTMLElement, modalElement);
            }
         });
      }
   }, []);

   function validateClbButton(target: HTMLElement, modalElement: HTMLElement) {
      if (isLoading) return;

      const isButtonSecondary = target.attributes[0]?.value ? true : false;

      if (isButtonSecondary) {
         removeClassModal(modalElement);
      } else {
         setTimeout(() => {
            removeClassModal(modalElement);
         }, 1000);
      }
   }

   function removeClassModal(modalElement: HTMLElement) {
      modalElement.shadowRoot
         ?.querySelector('div')
         ?.classList.remove('container--isOpen');
   }

   const [isLoading, setIsLoading] = React.useState(false);

   const booleanProps = {
      hideButtonClose: hideButtonClose ? true : undefined,
      isOpen: isOpen ? true : undefined,
   };

   const loading = isLoading && { loading: true };

   return (
      <clb-modal id={modalId} {...booleanProps}>
         <div className={styles.modalContent}>
            <div className={styles.emojiHeadingWrapper}>
               <clb-emoji type={emojiType}></clb-emoji>
               <clb-heading-team size={headingSize}>
                  {parseHtml(title)}
               </clb-heading-team>
               <div className={styles.descriptionWrapper}>
                  <clb-text size="default">{parseHtml(description)}</clb-text>
               </div>
            </div>

            <div className={styles.modalButtonWrapper}>
               {buttonSecondary && (
                  <clb-button
                     onClick={() => {
                        if (isLoading) return;
                        setTimeout(() => buttonSecondary.onClick(), 400);
                     }}
                     type="secondary"
                  >
                     {buttonSecondary.label}
                  </clb-button>
               )}

               <clb-button
                  onClick={() => {
                     setIsLoading(true);
                     setTimeout(() => buttonPrimary.onClick(), 1000);
                  }}
                  {...loading}
               >
                  {buttonPrimary.label}
               </clb-button>
            </div>
         </div>
      </clb-modal>
   );
}
