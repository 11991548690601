import React from 'react';
import styles from './Denied.module.scss';
import pattern from '@assets/pattern02.svg';

import { useNavigate } from 'react-router-dom';

import { Header } from '@components/Header';
import { Footer } from '@components/Footer';

export function Denied() {
   const navigate = useNavigate();

   React.useEffect(() => {
      const unlisten = window.addEventListener(
         'popstate',
         () => navigate('/'),
         false,
      );

      return () => unlisten;
   }, []);

   return (
      <div className={styles.deniedContainer}>
         <Header />

         <section className={styles.container}>
            <div className={styles.content}>
               <div className={styles.wrapper}>
                  <clb-emoji type="disappointed-face"></clb-emoji>
                  <div className={styles.textWrapper}>
                     <clb-heading-team size="lg">
                        Desculpe, o conteúdo do Bora não está disponível para
                        você!
                     </clb-heading-team>
                     <clb-text>
                        Para participar do Bora, você
                        <br className={styles.brMobile}></br> precisa
                        <br className={styles.brDesktop}></br> ter mais de 18
                        anos.
                     </clb-text>
                  </div>
               </div>
               <img className={styles.image} src={pattern} alt="hero image" />
            </div>
         </section>
         <Footer isHideNavigation />
      </div>
   );
}
