import styles from './Load.module.scss';

import { ConnectionAnimatedDots } from '@components/ConnectionAnimatedDots';

interface Props {
   title?: string;
}

export function Load({ title = 'Buscando...' }: Props) {
   return (
      <div className={styles.container}>
         <ConnectionAnimatedDots />
         <clb-heading-team size="2xs">{title}</clb-heading-team>
      </div>
   );
}
