import styles from './HowCallYou.module.scss';

interface Props {
   onClick: () => void;
   value: string;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export function HowCallYou({ onClick, value, onChange }: Props) {
   const notValid = value.length < 1;
   const isDisabled = notValid ? { disabled: true } : {};

   function onValidated() {
      if (notValid) return;
      onClick();
   }

   return (
      <div>
         <clb-heading-team size="sm">
            Como podemos <br />
            chamar você?
         </clb-heading-team>

         <div className={styles.spacingInput}>
            <clb-input-text
               label="Nome"
               placeholder="Digite aqui..."
               helper-text="Dica: Use seu primeiro nome + um sobrenome."
               type="text"
               value={value}
               onChange={onChange}
            ></clb-input-text>
         </div>

         <clb-button onClick={onValidated} {...isDisabled}>
            Próximo
         </clb-button>
      </div>
   );
}
