import React from 'react';
import '@meiuca/celebration-team-bora';
import '@meiuca/celebration-design-tokens/dist/css/brand01/dark.css';
import '@meiuca/celebration-design-tokens/dist/scss/globals.scss';

import { AuthProvider } from '@hooks/useAuth';
import { ToastProvider } from '@hooks/useToast';
import boraMotion from '@assets/boraLoading.json';

import { motion } from 'framer-motion';
import { Player } from '@lottiefiles/react-lottie-player';

import Routes from './routes';

function App() {
   const [isLoading, setIsLoading] = React.useState(true);

   const animation = {
      initial: { opacity: 1 },
      animate: { opacity: 0 },
      exit: { opacity: 0 },
   };

   const showAnimation = isLoading ? {} : animation;

   React.useEffect(() => {
      setTimeout(() => {
         setIsLoading(false);
      }, 5000);
   }, []);

   return (
      <>
         <motion.div
            {...showAnimation}
            transition={{ duration: 0.5 }}
            style={{
               position: 'fixed',
               width: '100%',
               height: '100%',
               backgroundColor: '#F3FF00',
               zIndex: 2147483647,
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               pointerEvents: isLoading ? 'all' : 'none',
            }}
         >
            <Player autoplay loop src={boraMotion}></Player>
         </motion.div>

         <ToastProvider>
            <AuthProvider>
               <Routes />
            </AuthProvider>
         </ToastProvider>
      </>
   );
}

export default App;
