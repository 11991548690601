import React from 'react';
import styles from './Admin.module.scss';

import { clearString } from '@utils/formatString';

interface Props {
   value: string;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
   onSearch: () => void;
   isLoading: boolean;
}

export function HeaderAdmin({ value, onChange, onSearch, isLoading }: Props) {
   function handleSearchPhone(e: React.KeyboardEvent<HTMLElement>) {
      if (isLoading) return;

      const phone = clearString(value);
      const searchPhone = [0, 11].includes(phone.length);

      if (e.key === 'Enter' && searchPhone) {
         onSearch();
      }
   }

   function handleSearchPhoneButton(e: React.MouseEvent<HTMLElement>) {
      if (isLoading) return;

      //@ts-ignore
      if (e.target.tagName.toLowerCase() === 'input') {
         const cep = clearString(value);
         const searchPhone = [0, 11].includes(cep.length);

         if (searchPhone) {
            onSearch();
         }
      }
   }

   return (
      <>
         <div className={styles.headingAndTextWrapper}>
            <clb-heading-team size="xl">Painel</clb-heading-team>
            <clb-text size="lg">
               Administre os perfis cadastrados na plataforma.
            </clb-text>
         </div>

         <div className={styles.inputWrapper}>
            <div className={styles.headingInputWrapper}>
               <clb-heading-team size="2xs">
                  Encontre o perfil pelo celular
               </clb-heading-team>
               <clb-input-search
                  placeholder="(00) 9 0000-0000"
                  //@ts-ignore
                  mask="phone"
                  value={value}
                  onChange={onChange}
                  onSearch={onSearch}
                  onKeyUp={handleSearchPhone}
                  onClick={handleSearchPhoneButton}
               ></clb-input-search>
            </div>
         </div>

         <clb-divider></clb-divider>
      </>
   );
}
