import React from 'react';
import { Header } from '@components/Header';
import { Footer } from '@components/Footer';
import { useToast } from '@hooks/useToast';
import { clearString, formatPhone } from '@utils/formatString';

import * as userService from '@services/user';

import { HeaderAdmin } from './HeaderAdmin';
import { AdminSearchingFor } from '@components/AdminSearchingFor';

import styles from './Admin.module.scss';
import { AdminUserCardComponent } from '@components/AdminUserCard';
import { IUserByPhone } from '@services/user.types';
import { ModalUpdate } from '@components/ModalUpdate';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth';

export function Admin() {
   const toast = useToast();
   const navigate = useNavigate();
   const auth = useAuth();

   const [phone, setPhone] = React.useState('');
   const [newPhone, setNewPhone] = React.useState('');
   const [userData, setUserData] = React.useState<IUserByPhone | null>(null);
   const [isLoading, setIsLoading] = React.useState(false);
   const [showFindUser, setShowFindUser] = React.useState(true);
   const [showModalPhone, setShowModalPhone] = React.useState(false);

   React.useEffect(() => {
      const userIsLogged = auth.checkUserIsLogged();
      if (!userIsLogged) {
         return navigate('/login', { state: { toast: true } });
      }
   }, []);

   const NotFoundPeople = React.memo(() => (
      <div className={styles.emojiCardWrapper}>
         <clb-emoji type="disappointed-face"></clb-emoji>
         <div className={styles.wrapperHeading}>
            <clb-heading-team size="xs">
               Não encontramos nenhum perfil com este celular.
            </clb-heading-team>
         </div>
         <clb-text>Confira se não há algum erro de digitação.</clb-text>
      </div>
   ));

   const FindPeople = React.memo(() => (
      <div className={styles.emojiCardWrapper}>
         <clb-emoji type="nerd-face"></clb-emoji>
         <div className={styles.wrapperHeading}>
            <clb-heading-team size="xs">
               Encontre qualquer perfil cadastrado
            </clb-heading-team>
         </div>
         <clb-text>
            No painel de perfis cadastrados você encontra, edita, habilita e
            desabilita perfis.
         </clb-text>
      </div>
   ));

   async function findUser() {
      setIsLoading(true);
      setShowFindUser(false);
      const user = await userService.getUserByPhone(clearString(phone));

      setUserData(user);
      setIsLoading(false);
   }

   async function updateUserStatus() {
      const userStatusUpdate = await userService.updateUserActive(
         userData?.id,
         !userData?.isTester,
      );

      if (userStatusUpdate.userActiveUpdated) {
         const user = await userService.getUserByPhone(clearString(phone));

         setUserData(user);

         if (userData?.isTester) {
            toast.show({
               title: 'Perfil de teste desabilitado com sucesso!',
               type: 'positive',
            });
         } else {
            toast.show({
               title: 'Perfil de teste habilitado com sucesso!',
               type: 'positive',
            });
         }
      } else {
         if (userData?.isTester) {
            toast.show({
               title: 'Ocorreu um erro ao desabilitar como perfil de teste!',
               message: 'Por favor, tente novamente!',
               type: 'negative',
            });
         } else {
            toast.show({
               title: 'Ocorreu um erro ao habilitar como perfil de teste!',
               message: 'Por favor, tente novamente!',
               type: 'negative',
            });
         }
      }

      setShowModalPhone(false);
   }

   async function updatePhone() {
      const userPhoneUpdate = await userService.updateUserPhone(
         userData?.id,
         clearString(newPhone),
      );

      if (userPhoneUpdate.phoneUpdated) {
         const user = await userService.getUserByPhone(clearString(newPhone));
         setUserData(user);

         toast.show({
            title: 'Celular atualizado com sucesso!',
            type: 'positive',
         });
      } else {
         toast.show({
            title: `Ocorreu um erro ao editar o celular!`,
            message: 'Por favor, tente novamente!',
            type: 'negative',
         });
      }

      setShowModalPhone(false);
   }

   return (
      <>
         <Header links={false} />
         <div className={styles.adminContainer}>
            <div className={styles.content}>
               <div className={styles.wrapper}>
                  <HeaderAdmin
                     value={phone}
                     onChange={e => setPhone(e.target.value)}
                     onSearch={findUser}
                     isLoading={false}
                  />

                  <div className={styles.mainContentWrapper}>
                     {isLoading && <AdminSearchingFor />}
                     {showFindUser && <FindPeople />}
                     {!userData && !isLoading && !showFindUser && (
                        <NotFoundPeople />
                     )}
                  </div>

                  <div className={styles.userCardWrapper}>
                     {userData && !isLoading && (
                        <AdminUserCardComponent
                           userData={userData}
                           onClickPrimaryButton={() => setShowModalPhone(true)}
                           onClickSecondaryButton={updateUserStatus}
                        />
                     )}
                  </div>
               </div>
            </div>
         </div>
         {showModalPhone && (
            <ModalUpdate
               isOpen={showModalPhone}
               onClose={setShowModalPhone}
               title={'Editar celular'}
               onClick={updatePhone}
               hasError={phone.length < 15}
            >
               <clb-input-text
                  label="Celular"
                  value={formatPhone(userData?.phone as string)}
                  placeholder="(00) 9 0000-0000"
                  onChange={(e: any) => setNewPhone(e.target.value)}
                  mask="phone"
               ></clb-input-text>
            </ModalUpdate>
         )}

         <Footer isHideNavigation />
      </>
   );
}
