import styles from './Bora.module.scss';
import boraSectionImage from '@assets/boraSectionImage.svg';

import '@meiuca/celebration-team-bora';

export function Bora() {
   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <div className={styles.wrapper}>
               <div className={styles.mobileImageWrapper}>
                  <img
                     className={styles.imageMobile}
                     src={boraSectionImage}
                     alt="bora image"
                  />
               </div>
               <div className={styles.title}>
                  <clb-heading-team size="xl">
                     Afinal, o<br></br> que é o Bora?
                  </clb-heading-team>
               </div>
               <div className={styles.textImageWrapper}>
                  <div className={styles.textWrapper}>
                     <clb-text size="lg">
                        O Bora é um <b>programa da Ambev</b> com trilhas de
                        conhecimento, que ajuda pessoas empreendedoras e que
                        querem trabalhar para dar o próximo passo através de
                        conhecimento, incentivo e conexão.
                     </clb-text>
                  </div>
                  <img
                     className={styles.imageDesktop}
                     src={boraSectionImage}
                     alt="bora image"
                  />
               </div>
            </div>
         </div>
      </section>
   );
}
