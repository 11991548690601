import styles from './Piloto01.module.scss';
import image from '@assets/piloto01Image.png';
import icon01 from '@assets/pilotoIcon01.svg';
import icon02 from '@assets/pilotoIcon02.svg';
import icon03 from '@assets/pilotoIcon03.svg';

import '@meiuca/celebration-team-bora';

export function Piloto01() {
   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <div className={styles.contentSection}>
               <clb-tag-status label="Piloto 01"></clb-tag-status>
               <div className={styles.titleTextWrapper}>
                  <clb-heading-team size="xl">
                     Bora se preparar para conquistar um trabalho?
                  </clb-heading-team>
                  <clb-text size="lg">
                     A Ambev, em parceria com o Instituto da Criança, lançou um
                     programa de qualificação profissional para{' '}
                     <b>jovens do RJ</b> atuarem em bares, restaurantes e
                     hotéis. Mais de 1.000 pessoas finalizaram a jornada e o
                     programa está em fase de conexão com vagas de trabalho.
                  </clb-text>
               </div>
               <div className={styles.cardsWrapper}>
                  <div className={styles.card}>
                     <div className={styles.iconWrapper}>
                        <img
                           loading="lazy"
                           src={icon01}
                           alt="ícone de coração"
                        />
                     </div>
                     <clb-text>
                        Mais de <b>1000</b> pessoas já qualificadas.
                     </clb-text>
                  </div>
                  <div className={styles.card}>
                     <div className={styles.iconWrapper}>
                        <img
                           loading="lazy"
                           src={icon02}
                           alt="ícone de coração"
                        />
                     </div>
                     <clb-text>
                        <b>Rio de Janeiro</b>
                     </clb-text>
                  </div>
                  <div className={styles.card}>
                     <div className={styles.iconWrapper}>
                        <img
                           loading="lazy"
                           src={icon03}
                           alt="ícone de coração"
                        />
                     </div>
                     <clb-text>
                        Conteúdo com base em 3 pilares: emocional,
                        comportamental e técnico.
                     </clb-text>
                  </div>
               </div>
            </div>
            <div className={styles.imageSection}>
               <img
                  loading="lazy"
                  className={styles.image}
                  src={image}
                  alt="Uma pessoa de camiseta vermelha"
               />
            </div>
         </div>
      </section>
   );
}
