import React from 'react';
import styles from './PersonalData/ProfileUser.module.scss';

import { useNavigate } from 'react-router-dom';

import { InterestItem } from '@services/Interest.types';
import { OpportunityItem as IOpportunity } from '@services/opportunity.types';
import { NeedHelp } from '@components/NeedHelp';
import { Footer } from '@components/Footer';
import { ModalButton } from '@components/ModalButton';
import { Load } from '@components/Load';

import { useAuth } from '@hooks/useAuth';
import { useToast } from '@hooks/useToast';
import { useDrawer } from '@hooks/useDrawer';

import * as userService from '@services/user';
import { IUserProfile } from '@services/user.types';
import { getInterest } from '@services/Interest';
import { getOpportunityByInterest } from '@services/opportunity';

import { PersonalData } from './PersonalData/PersonalData';
import { IItemsSelected, Interests } from './Interests';

export function ProfileUser() {
   const navigate = useNavigate();
   const auth = useAuth();
   const toast = useToast();
   const drawer = useDrawer();

   const [isLoading, setIsLoading] = React.useState(false);
   const [showExitProfile, setShowExitProfile] = React.useState(false);

   const [userData, setUserData] = React.useState<IUserProfile | null>(null);
   const [interests, setInterests] = React.useState<InterestItem[]>([]);
   const [interestsSelect, setInterestsSelect] = React.useState<string[]>([]);
   const [opportunities, setOpportunities] = React.useState<IOpportunity[]>([]);
   const [oportunitiesSelet, setOportunitiesSelect] = React.useState<string[]>(
      [],
   );

   React.useEffect(() => {
      getUserProfile();
   }, []);

   async function getUserProfile() {
      setIsLoading(true);
      const _user = await userService.getUser();

      if (!_user) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });

         setIsLoading(false);
         return;
      }

      await getInterestOpportunities();

      setInterestsSelect(_user.interestsIds!);
      setOportunitiesSelect(_user.opportunitiesIds!);
      setUserData(_user);

      setIsLoading(false);
   }

   async function getInterestOpportunities() {
      if (interests.length === 0) {
         const _interestsData = await getInterest();
         setInterests(_interestsData);
      }

      if (opportunities.length === 0) {
         const _opportunitiesData = await getOpportunityByInterest({
            interestId: '1',
         });
         setOpportunities(_opportunitiesData);
      }
   }

   async function handleUpdateProfile(
      userProfile: IUserProfile,
   ): Promise<IUserProfile | null> {
      const _userUpdated = await userService.updateUser(userProfile);

      if (!_userUpdated) {
         return null;
      } else {
         return _userUpdated;
      }
   }

   async function handleUpdatePublicProfile(isActive: boolean) {
      const userUpdated = await handleUpdateProfile({
         ...userData,
         isActive,
         opportunitiesIds: oportunitiesSelet,
         interestsIds: interestsSelect,
      } as IUserProfile);

      if (!userUpdated) {
         toast.show({
            title: `Ocorreu um erro ao enviar sua mensagem!`,
            message: 'Por favor, tente novamente!',
            type: 'negative',
         });
      } else {
         toast.show({
            title: 'Perfil público atualizado com sucesso!',
            type: 'positive',
         });
      }

      setUserData(userUpdated);
   }

   async function onUpdateInterestsOpportunities({
      interests: _interestsIds,
      opportunities: _opportunitiesIds,
   }: IItemsSelected): Promise<boolean> {
      if (_interestsIds.length === 0 && _opportunitiesIds.length === 0) {
         return false;
      }

      const userUpdated = await handleUpdateProfile({
         ...userData,
         opportunitiesIds: _opportunitiesIds,
         interestsIds: _interestsIds,
      } as IUserProfile);

      if (!userUpdated) return false;

      setUserData(userUpdated);
      return true;
   }

   function handleLogout() {
      if (isLoading) return;

      auth.removeUser();
      setShowExitProfile(false);

      navigate('/');
      drawer.removeDrawer();
   }

   function handleOnback() {
      drawer.closeDrawer();
   }

   function handleQuestionLogout() {
      toast.close();
      setShowExitProfile(true);
   }

   return (
      <>
         <div className={styles.profilePage}>
            <div className={styles.header}>
               <clb-button-icon
                  icon="Arrow1"
                  size="lg"
                  aria-label="edit button"
                  onClick={handleOnback}
               ></clb-button-icon>
            </div>
            <div className={styles.container}>
               <div className={styles.title}>
                  <clb-heading-team size="xl">Meu Perfil</clb-heading-team>

                  <div className={styles.tabsWrapper}>
                     {isLoading && <Load title="" />}

                     {userData && (
                        <clb-tab-group-team>
                           <clb-tab-item-team
                              title="Dados pessoais"
                              tab-index="1"
                              active
                           >
                              <PersonalData
                                 onUpdateUser={handleUpdateProfile}
                                 userDataSelected={userData}
                                 onUpdateUserSelected={setUserData}
                                 onUpdatePublicProfile={
                                    handleUpdatePublicProfile
                                 }
                              />
                           </clb-tab-item-team>

                           <clb-tab-item-team title="Interesses" tab-index="1">
                              <Interests
                                 interests={interests}
                                 opportunities={opportunities}
                                 interestsSelected={interestsSelect}
                                 opportunitiesSelected={oportunitiesSelet}
                                 onUpdateInterestsOpportunities={
                                    onUpdateInterestsOpportunities
                                 }
                              />
                           </clb-tab-item-team>
                        </clb-tab-group-team>
                     )}
                  </div>

                  <div className={styles.exitProfile}>
                     <clb-link onClick={handleQuestionLogout}>
                        Sair do perfil
                     </clb-link>
                  </div>
               </div>
            </div>

            <div className={styles.footer}>
               <NeedHelp />
               <Footer isHideNavigation />
            </div>
         </div>

         <ModalButton
            id="modalCloseLogout"
            isOpen={showExitProfile}
            onClose={setShowExitProfile}
            title={'Tem certeza que<br/> deseja sair do perfil?'}
            description={'Esperamos te ver em breve por aqui!'}
            emojiType={'disappointed-face'}
            headingSize={window.innerWidth > 768 ? 'sm' : 'lg'}
            buttonPrimary={{
               label: 'Sim, quero sair!',
               onClick: () => handleLogout(),
            }}
            buttonSecondary={{
               label: 'Cancelar',
               onClick: () => setShowExitProfile(false),
            }}
         />
      </>
   );
}
