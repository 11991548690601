import React from 'react';

import { ProfilePdv } from '@pages/ProfilePdv';
import { ProfileUser } from '@pages/ProfileUser';

import { useAuth } from '@hooks/useAuth';

export function Profile() {
   const auth = useAuth();

   const [isPdv, setIsPdv] = React.useState(false);

   React.useEffect(() => {
      const _isPdv = auth.checkUserIsPDV();
      setIsPdv(_isPdv);
   }, []);

   return isPdv ? <ProfilePdv /> : <ProfileUser />;
}
