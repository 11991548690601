import React from 'react';
import styles from './AccessCode.module.scss';

import { useNavigate } from 'react-router-dom';

import { ModalStepper } from '@components/ModalStepper';

import * as loginService from '@services/login';

import { useAuth } from '@hooks/useAuth';
import { useToast } from '@hooks/useToast';
import { useDrawer } from '@hooks/useDrawer';

import { TtypeUser } from '@components/Login';

interface Props {
   phone: string;
   typeUser: TtypeUser;
   onValidateTypeUser: () => void;
   onBack: () => void;
}

export function AccessCode({
   phone,
   typeUser,
   onValidateTypeUser,
   onBack,
}: Props) {
   const navigate = useNavigate();
   const auth = useAuth();
   const toast = useToast();
   const drawer = useDrawer();

   const phoneNumber = phone.replace(/\D/g, '');

   const [showModal, setShowModal] = React.useState(false);
   const [isLoading, setIsLoading] = React.useState(false);
   const [isError, setIsError] = React.useState(false);

   const [code, setCode] = React.useState('');
   const [resendCode, setResendCode] = React.useState(false);
   const [seconds, setSeconds] = React.useState(60);

   const timerRef = React.useRef<NodeJS.Timer | null>(null);

   const loading = isLoading ? { loading: true } : {};
   const disabledResendCode = resendCode ? {} : { disabled: true };
   const error = isError
      ? { error: true, 'helper-text': 'Código inválido!' }
      : {};

   React.useEffect(() => {
      if (!timerRef.current) {
         const intervalID = setInterval(() => {
            setSeconds(seg => seg - 1);
         }, 1000);

         timerRef.current = intervalID;
      }
   }, [resendCode]);

   React.useEffect(() => {
      if (seconds <= 0) clearTimer();
   }, [seconds]);

   function clearTimer() {
      if (timerRef.current) {
         clearInterval(timerRef.current);
         setSeconds(0);
         setResendCode(true);
      }
   }

   async function handleResendCode() {
      if (!resendCode) return;

      const userPhone = await loginService.validatePhone({
         phone: phoneNumber,
      });

      if (!userPhone) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });

         setIsLoading(false);
         return;
      }

      toast.show({
         title: 'Código reenviado com sucesso!',
         type: 'positive',
      });

      timerRef.current = null;
      setResendCode(false);
      setSeconds(60);
   }

   async function handleLogin() {
      if (isLoading) return;

      if (code.length === 0) {
         setIsError(true);
         return;
      }

      setIsError(false);
      setIsLoading(true);

      const user = await loginService.validateCode({
         phone: phoneNumber,
         code,
      });

      setIsLoading(false);

      if (!user) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });
         return;
      }

      if (user.accessBlocked) {
         toast.show({
            title: 'Você atingiu o limite de tentativas de acesso.',
            message: 'Seu número de celular ficará bloqueado por 1 hora.',
            type: 'warning',
         });

         onBack();
         return;
      }

      if (user.codeNotExists) {
         setIsError(true);
         return;
      }

      if (typeUser === 'waiting-list') {
         onValidateTypeUser();
         return;
      }

      if (!user.isFirstAccess || user.isPdv) {
         let levelPDV = {};
         if (user.isPdv) {
            levelPDV = {
               level: 'pdv',
            };
         }

         auth.saveUser({
            name: user.userName,
            token: user.token,
            phone,
            isAdmin: user.isAdmin,
            ...levelPDV,
         });

         navigate('/connections');
         drawer.removeDrawer();
      } else if (user.isFirstAccess) {
         auth.saveUser({
            name: '',
            token: user.token,
            phone,
            isAdmin: user.isAdmin,
         });

         setShowModal(true);
      }
   }

   return (
      <>
         <div className={styles.container}>
            <clb-heading-team size="lg" displayRole="h3">
               Recebeu?
            </clb-heading-team>

            <div className={styles.spacingSubtitle} />
            <clb-text>
               Enviamos um código de acesso por SMS, ele pode levar em média
               <strong> {seconds} segundos</strong> para chegar.
            </clb-text>

            <div className={styles.spacingInput} />
            <clb-input-password
               label="Código"
               placeholder="••••••"
               size="lg"
               //@ts-ignore
               value={code}
               //@ts-ignore
               onChange={e => setCode(e.target.value)}
               {...error}
            ></clb-input-password>

            <div className={styles.spacingResend} />
            <clb-link onClick={handleResendCode} {...disabledResendCode}>
               Reenviar código
            </clb-link>

            <div className={styles.spacingButton} />
            <clb-button aria-label="Entrar" onClick={handleLogin} {...loading}>
               Entrar
            </clb-button>
         </div>

         <ModalStepper
            isOpen={showModal}
            steps={[
               {
                  id: '1',
                  title: 'Queremos<br/>conhecer você!',
                  description:
                     'A partir de agora confirmaremos algumas<br/>informações para montarmos o seu<br/>perfil dentro do Bora.',
                  emoji: 'hand-writing',
               },
               {
                  id: '2',
                  title: 'Seu perfil em<br/>nossa vitrine!',
                  description:
                     'Seu perfil ficará visível em nossa vitrine de<br/>conexões e você será avisado por SMS toda vez<br/>que alguém demonstrar interesse.',
                  emoji: 'nerd-face',
               },
               {
                  id: '3',
                  title: 'A partir daí é<br/>com você!',
                  description:
                     'Pelo SMS, você receberá o número da pessoa<br/>interessada pelo seu perfil e será responsável<br/>por entrar em contato para fazer a conexão.',
                  emoji: 'raising-hands',
               },
            ]}
            onClick={() => {
               navigate(`/firstaccess`, {
                  state: { phone: phoneNumber },
               });
               drawer.removeDrawer();
            }}
         />
      </>
   );
}
