import styles from './DashboardCard.module.scss';

interface Props {
   title: string;
   subtitle?: string;
   data: number;
   onClick?: () => void;
}

export default function DashboardCard({
   title,
   subtitle,
   data,
   onClick,
}: Props) {
   return (
      <div className={styles.cardUsersData}>
         <clb-shape>
            <div className={styles.titleWrapper}>
               <clb-heading-team size="2xs">{title}</clb-heading-team>
               <clb-text size="sm">{subtitle}</clb-text>
            </div>
            <div className={styles.dataWrapper}>
               <h3
                  className={
                     title == 'Conexões realizadas'
                        ? styles.textWrapperBlue
                        : styles.textWrapperDefault
                  }
               >
                  {data}
               </h3>

               {onClick ? (
                  <div className={styles.icon}>
                     <clb-button-icon
                        icon={'Downfile'}
                        onClick={() => onClick()}
                     />
                  </div>
               ) : (
                  ''
               )}
            </div>
         </clb-shape>
      </div>
   );
}
