import React, { useCallback } from 'react';
import styles from './ConnectionModal.module.scss';

import { useAuth } from '@hooks/useAuth';
import { useToast } from '@hooks/useToast';
import { CheckboxTerms } from '@components/CheckboxTerms';

import * as connectionsService from '@services/connections';
import { clearString } from '@utils/formatString';
import { filterWords } from '@utils/filterBadwords';

interface Props {
   isOpen?: boolean;
   onClose: () => void;
   userID?: string;
}

export function ConnectionModal({ isOpen = false, userID, onClose }: Props) {
   const auth = useAuth();
   const toast = useToast();

   const [isLoading, setIsLoading] = React.useState(false);
   const [motives, setMotives] = React.useState(['']);
   const [reasonId, setReasonId] = React.useState();

   const [name, setName] = React.useState('');
   const [phone, setPhone] = React.useState('');
   const [message, setMessage] = React.useState('');
   const [isAcceptTerms, setIsAcceptTerms] = React.useState(false);

   const [showModal, setShowModal] = React.useState(isOpen);

   const loading = isLoading ? { loading: true } : {};

   const booleanProps = {
      isOpen: showModal ? true : undefined,
   };

   const ASTERISC_REGEX: RegExp = /\*/g;
   const isCensored = ASTERISC_REGEX.test(message);

   const sendMessageIsNotAccept =
      !isAcceptTerms ||
      !reasonId ||
      message.length < 5 ||
      name.length < 3 ||
      phone.length < 15 ||
      isCensored;

   const disableButtonSend = sendMessageIsNotAccept ? { disabled: true } : {};

   const hasBadwordError = isCensored
      ? {
           error: true,
           'helper-text': 'Palavra inadequada. Revise para continuar!',
        }
      : {};

   React.useEffect(() => {
      if (auth.user && isOpen) {
         setName(auth.user?.name);
         setPhone(auth.user?.phone ?? '');
         setMessage('');
      }

      setShowModal(isOpen);
   }, [isOpen]);

   React.useEffect(() => {
      getSendMessageMotives();
   }, []);

   const getSendMessageMotives = useCallback(async () => {
      const reasons = await connectionsService.getReasons({ type: 2 });

      setMotives(reasons);
   }, []);

   async function handleSendMessage() {
      if (sendMessageIsNotAccept) return;
      if (!userID) return;

      setIsLoading(true);
      const smsSended = await connectionsService.sendMessage({
         name,
         phone: clearString(phone),
         message,
         userId: userID,
         reasonId,
      });

      const closeModal = () => {
         document
            .querySelector('#modalWhatsapp')
            ?.shadowRoot?.querySelector('div')
            ?.classList.remove('container--isOpen');

         setMessage('');
         setIsAcceptTerms(false);
         setIsLoading(false);
         setShowModal(true);

         setTimeout(() => {
            onClose();
         }, 400);
      };

      if (!smsSended) {
         toast.show({
            title: 'Ocorreu um erro ao enviar sua mensagem!',
            message: 'Por favor, tente novamente! ',
            type: 'negative',
         });

         closeModal();
         return;
      }

      toast.show({
         title: 'Mensagem enviada com sucesso!',
         message:
            'Encaminhamos seu contato para a pessoa que deseja se conectar. ',
         type: 'positive',
      });

      closeModal();
   }

   return (
      <clb-modal id="modalWhatsapp" {...booleanProps}>
         <div className={styles.container}>
            <div className={styles.titleTextWrapper}>
               <clb-emoji type="hand-writing"></clb-emoji>
               <clb-heading-team size="lg">Entrar em contato</clb-heading-team>
               <clb-text>
                  Informe seus dados, vamos encaminhar sua mensagem para a
                  pessoa que deseja se conectar. Ela responderá você via
                  Whatsapp!
               </clb-text>
            </div>

            <div className={styles.form}>
               <div className={styles.nameCelWrapper}>
                  <clb-input-text
                     placeholder="Digite seu nome..."
                     label="Nome"
                     value={name}
                     //@ts-ignore
                     onChange={e => setName(e.target.value)}
                  ></clb-input-text>

                  <clb-input-text
                     placeholder="(00) 0 0000-0000"
                     label="Celular"
                     mask="phone"
                     value={phone}
                     //@ts-ignore
                     onChange={e => setPhone(e.target.value)}
                  ></clb-input-text>
               </div>

               <clb-select
                  defaultSelect="Selecione um motivo"
                  options={JSON.stringify(motives)}
                  labelText="Motivo do contato"
                  //@ts-ignore
                  onChange={e => setReasonId(e.target.value)}
               ></clb-select>

               <clb-input-textarea
                  maxlength={140}
                  placeholder="Escreva sua mensagem aqui..."
                  label="Motivo do contato"
                  //@ts-ignore
                  value={message}
                  //@ts-ignore
                  onChange={e => setMessage(filterWords.filter(e.target.value))}
                  {...hasBadwordError}
               ></clb-input-textarea>
            </div>

            <div className={styles.checkboxWrapper}>
               <CheckboxTerms
                  checked={isAcceptTerms}
                  onClick={() => setIsAcceptTerms(prev => !prev)}
               />
            </div>

            <div className={styles.buttonWrapper}>
               <clb-button
                  {...loading}
                  {...disableButtonSend}
                  onClick={handleSendMessage}
               >
                  Enviar
               </clb-button>
            </div>
         </div>
      </clb-modal>
   );
}
