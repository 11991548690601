export function validatePhone(phone: string) {
   phone = phone.replace(/[^\d]+/g, '');
   if (phone == '') return true;
   // Elimina numeros invalidos conhecidos
   if (
      phone.length != 11 ||
      phone == '00000000000' ||
      phone == '11111111111' ||
      phone == '22222222222' ||
      phone == '33333333333' ||
      phone == '44444444444' ||
      phone == '55555555555' ||
      phone == '66666666666' ||
      phone == '77777777777' ||
      phone == '88888888888' ||
      phone == '99999999999'
   )
      return true;

   // Valida o numero de celular
   const regex = /^((1[1-9])|([2-9]\d))((3\d{3}\d{4})|(9\d{3}\d{5}))$/;

   if (regex.test(phone) === false) return true;

   return false;
}
