import styles from './NeedHelp.module.scss';

export function NeedHelp() {
   return (
      <div className={styles.container}>
         <div className={styles.containerWrapper}>
            <div className={styles.heading}>
               <clb-heading-team size="xs">Precisa de ajuda?</clb-heading-team>
            </div>

            <div className={styles.text}>
               <clb-text>
                  Caso tenha alguma dúvida relacionada ao Bora, entre em contato
                  através do email:
               </clb-text>
            </div>

            <div className={styles.link}>
               <clb-link href="mailto:bora@ambev.com.br">
                  bora@ambev.com.br
               </clb-link>
            </div>
         </div>
      </div>
   );
}
