import api from '@services/api';
import { DashboardItems } from '@services/dashboard.types';

export async function getWaitinglist(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/waitinglist');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getPdvs(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/pdvs');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getRegisteredCandidates(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/registered-candidates');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getPendingCandidates(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/pending-candidates');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getInactiveCandidates(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/inactive-candidates');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getRegistrationsPerDay(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/registrations-per-day');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getCandidatesWhoSentMessage(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/candidates-sent-messages');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getUsersWithIncompleteCard(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/users-incomplete-card');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getCandidatesWhoSentMessagesToPdvs(): Promise<
   DashboardItems[]
> {
   try {
      const resp = await api.get('dashboard/candidates-sent-messages-pdvs');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getPdvsWhoSentMessages(): Promise<DashboardItems[]> {
   try {
      const resp = await api.get('dashboard/pdvs-sent-messages');
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function getSpreadsheet(
   worksheetName: string,
): Promise<DashboardItems[]> {
   const config: any = {
      headers: {
         'Content-Type':
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      responseType: 'blob',
   };

   try {
      const resp = await api.get(`dashboard/reports/${worksheetName}`, config);
      return resp.data;
   } catch (error) {
      return [];
   }
}
