import { useState } from 'react';
import styles from './WhereFinded..module.scss';

export type TRadioOptions =
   | 'Redes sociais'
   | 'Indicação'
   | 'Iniciativas Ambev'
   | 'Outros';

interface Props {
   onClick: (optionChecked: string | null) => void;
   isLoading?: boolean;
}

export function WhereFinded({ onClick, isLoading }: Props) {
   const [optionChecked, setOptionChecked] = useState<TRadioOptions | null>(
      null,
   );

   const [otherValue, setOtherValue] = useState('');

   const loading = isLoading ? { loading: true } : {};

   const disabled =
      optionChecked === null ||
      (optionChecked === 'Outros' && otherValue.length < 3)
         ? { disabled: true }
         : {};

   const options = [
      'Redes sociais',
      'Indicação',
      'Iniciativas Ambev',
      'Outros',
   ];

   function handleCheckOption(option: any) {
      setOptionChecked(option);
   }

   function onValidated() {
      onClick(optionChecked === 'Outros' ? otherValue : optionChecked);
   }

   return (
      <div>
         <div className={styles.title}>
            <clb-heading-team size="sm">
               Por onde conheceu o Bora?
            </clb-heading-team>
         </div>

         <div className={styles.spacingSubtitle} />
         <clb-text>Selecione uma opção.</clb-text>

         <div className={styles.spacingRadiogroup} />
         {options.map((option, index) => {
            const checked = optionChecked === option ? { checked: true } : {};

            const className =
               index === 0 ? styles.spacingRadiogroup : styles.spacingRadio;

            return (
               <div key={index}>
                  <div className={className} />
                  <clb-radio-button
                     onClick={() => handleCheckOption(option)}
                     {...checked}
                  >
                     {option}
                  </clb-radio-button>
               </div>
            );
         })}

         {optionChecked === 'Outros' && (
            <>
               <div className={styles.spacingInput} />
               <clb-input-text
                  label="Conta pra gente!"
                  placeholder="Escreva aqui..."
                  value={otherValue}
                  //@ts-ignore
                  onChange={e => setOtherValue(e.target.value)}
               ></clb-input-text>
            </>
         )}

         <div
            className={
               optionChecked === 'Outros'
                  ? styles.spacingButtonInput
                  : styles.spacingButton
            }
         />
         <clb-button onClick={onValidated} {...loading} {...disabled}>
            Entrar para a lista
         </clb-button>
      </div>
   );
}
