import React from 'react';
import styles from './WhereLive.module.scss';

interface Props {
   onClick: () => void;
   value: string;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
   isLoading: boolean;
   isErrorCep: boolean;
}

export function WhereLive({
   onClick,
   value,
   onChange,
   isLoading,
   isErrorCep,
}: Props) {
   const [isError, setIsError] = React.useState(false);

   const isLoadingActive = isLoading ? { loading: true } : {};
   const isErrorActive =
      isError || isErrorCep
         ? { error: true, 'helper-text': 'CEP inválido!' }
         : { 'helper-text': 'Dica: Use o CEP da sua rua ou de uma próxima.' };

   function onValidated() {
      if (value.length <= 7) {
         setIsError(true);
         return;
      }

      setIsError(false);
      onClick();
   }

   return (
      <div>
         <clb-heading-team size="sm">Onde você mora?</clb-heading-team>

         <div className={styles.spacingInput}>
            <clb-input-text
               label="CEP"
               placeholder="00000-000"
               mask="cep"
               value={value}
               onChange={onChange}
               {...isErrorActive}
            />

            <div className={styles.spacingLink} />
            <clb-link
               href="https://buscacepinter.correios.com.br/app/endereco/index.php"
               target="_blank"
               aria-label="Não sei meu CEP"
            >
               Não sei meu CEP
            </clb-link>
         </div>

         <clb-button {...isLoadingActive} onClick={onValidated}>
            Próximo
         </clb-button>
      </div>
   );
}
