import styles from './Home.module.scss';

import { useLocation } from 'react-router-dom';

import { Header } from '@components/Header';
import { Footer } from '@components/Footer';
import { AgeGate as ModalAgeGate } from '@components/AgeGate';

import { Hero } from './sections/Hero';
import { Bora } from './sections/Bora';
import { HowTo } from './sections/HowTo';
import { Piloto01 } from './sections/Piloto01';
import { Piloto02 } from './sections/Piloto02';

export function Home() {
   const location = useLocation();
   const isLoginRoute = location.pathname === '/login';

   return (
      <>
         <div className={styles.container}>
            <Header />
            <Hero />
            <Bora />
            <HowTo />
            <Piloto01 />
            <Piloto02 />
            <Footer />
         </div>

         {!isLoginRoute && <ModalAgeGate />}
      </>
   );
}
