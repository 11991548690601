import React from 'react';
import styles from './ModalUpdate.module.scss';

import parseHtml from 'html-react-parser';

interface Props {
   id?: string;
   title: string;
   isOpen?: boolean;
   isLoading?: boolean;
   onClick: () => void;
   onClose?: (isOpen: boolean) => void;
   children?: React.ReactNode;
   [key: string]: unknown;
   hasError?: boolean;
}

export function ModalUpdate({
   id = 'Update',
   title,
   isOpen = false,
   isLoading = false,
   onClick,
   onClose,
   children,
   hasError,
}: Props) {
   const modalId = `modal${id
      .toLocaleLowerCase()
      .split(' ')
      .join('_')
      .split('/')
      .join('_')}`;

   const windowSize = window.innerWidth;

   const booleanProps = {
      isOpen: isOpen ? true : undefined,
   };

   const loading = {
      loading: isLoading ? true : undefined,
   };

   const isDisabled = hasError ? { disabled: true } : {};

   React.useEffect(() => {
      if (onClose) {
         const modalElement = document.getElementById(
            modalId,
         ) as unknown as HTMLElement;

         modalElement?.addEventListener('click', e => {
            const targetTagName = (
               e.target as HTMLElement
            ).tagName.toLowerCase();

            if (targetTagName.includes('clb-button-icon')) {
               setTimeout(() => onClose(false), 400);
            }
         });
      }
   }, []);

   return (
      <clb-modal id={modalId} {...booleanProps}>
         <div className={styles.container}>
            <div className={styles.header}>
               <clb-heading-team size={windowSize > 768 ? 'sm' : 'lg'}>
                  {title === 'Editar data de nascimento'
                     ? parseHtml('Editar data <br/> de nascimento')
                     : title}
               </clb-heading-team>
            </div>

            <div className={styles.content}>{children}</div>

            <div className={styles.footer}>
               <clb-button
                  onClick={() => {
                     if (hasError) return;
                     onClick();
                  }}
                  {...loading}
                  {...isDisabled}
               >
                  Atualizar
               </clb-button>
            </div>
         </div>
      </clb-modal>
   );
}
