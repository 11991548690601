import React from 'react';
import styles from './AboutYou.module.scss';
import { filterWords } from '@utils/filterBadwords';

interface Props {
   onClick: () => void;
   value: string;
   onChange: (text: string) => void;
}

export function AboutYou({ onClick, value, onChange }: Props) {
   const [isLoading, setIsLoading] = React.useState(false);

   const loading = isLoading ? { loading: true } : {};

   const ASTERISC_REGEX: RegExp = /\*/g;
   const isCensored = ASTERISC_REGEX.test(value);

   const notValid = value.length < 1 || isCensored;

   const isDisabled = notValid ? { disabled: true } : {};

   const hasBadwordError = isCensored
      ? {
           error: true,
           'helper-text': 'Palavra inadequada. Revise para continuar!',
        }
      : {};

   function onValidated() {
      if (notValid) return;

      setIsLoading(true);
      setIsLoading(false);
      onClick();
   }

   return (
      <div className={styles.container}>
         <div className={styles.spacingText}>
            <clb-heading-team size="sm" displayRole="h3">
               Um pouco sobre você!
            </clb-heading-team>

            <clb-text>
               Fale um pouco sobre você, seus objetivos e conhecimentos.
               Capricha :)
            </clb-text>
         </div>

         <div className={styles.spacingInput}>
            <clb-input-textarea
               label="Descrição"
               name="description"
               placeholder="Conte sobre você..."
               maxlength={140}
               // @ts-ignore
               value={value}
               // @ts-ignore
               onChange={e => onChange(filterWords.filter(e.target.value))}
               {...hasBadwordError}
            ></clb-input-textarea>

            <clb-button
               aria-label="Próximo"
               onClick={onValidated}
               {...isDisabled}
               {...loading}
            >
               Próximo
            </clb-button>
         </div>
      </div>
   );
}
