import React from 'react';
import styles from './ContainerAuth.module.scss';

import ambevLogo from '@assets/ambev.svg';

interface Props {
   children?: React.ReactNode;
   title?: string;
   description?: string;
   showProgress?: boolean;
   pageNumber?: number;
   maxPages?: number;
   onBack?: () => void;
}

export function ContainerAuth({
   children,
   title = '',
   description,
   showProgress = false,
   pageNumber = 1,
   maxPages = 1,
   onBack,
}: Props) {
   const progress = showProgress
      ? {
           showProgress: showProgress,
           pageNumber: pageNumber,
           maxPages: maxPages,
        }
      : {};

   function InfoBanner() {
      return (
         <div className={styles.infoBanner}>
            <clb-text size="default">Uma iniciativa social</clb-text>
            <img src={ambevLogo} alt="Ambev" />
         </div>
      );
   }

   return (
      <div className={styles.container}>
         <div className={styles.content}>
            <div className={styles.navigationWrapper}>
               <clb-navigation-header
                  title={title}
                  description={description}
                  onClick={onBack}
                  {...progress}
               />
            </div>
            <div className={styles.childrens}>{children}</div>
            <InfoBanner />
         </div>

         <div className={styles.banner}>
            <InfoBanner />
         </div>
      </div>
   );
}
