import React from 'react';

import styles from './InputCheckbox.module.scss';

interface Props {
   label: string;
   name: string;
   id: string;
   checked: boolean;
   disabled: boolean;
   required: boolean;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export function InputCheckbox({
   label,
   name,
   id,
   checked = false,
   disabled = false,
   required,
   onChange,
}: Props) {
   const [_isChecked, _setIsChecked] = React.useState(checked);

   React.useEffect(() => {
      _setIsChecked(checked);
   }, [checked]);

   const _handleOnChange = (
      event: React.ChangeEvent<HTMLInputElement>,
   ): void => {
      _setIsChecked(!_isChecked);
      if (onChange) onChange(event);
   };

   return (
      <label className={styles.checkbox} htmlFor={name}>
         <input
            type="checkbox"
            id={id}
            name={name}
            disabled={disabled}
            required={required}
            checked={_isChecked}
            onChange={_handleOnChange}
         ></input>
         <span>{label}</span>
      </label>
   );
}
