import React, { Dispatch, SetStateAction } from 'react';
import styles from './PublicProfileModal.module.scss';

import { filterWords } from '@utils/filterBadwords';
import * as connectionsService from '@services/connections';
import { IUserProfile } from '@services/user.types';
import * as userService from '@services/user';
import { useToast } from '@hooks/useToast';

interface IButtonSecondary {
   onClick: () => void;
}

interface IButtonPrimary {
   onClick: (data: any) => any;
}

interface Props {
   id?: string;
   buttonSecondary: IButtonSecondary;
   buttonPrimary: IButtonPrimary;
   _publicProfile: boolean | undefined;
   _isLoading: boolean;
   _setIsLoading: Dispatch<SetStateAction<boolean>>;
   isOpen?: boolean;
   _defaultSelected?: string;
   onClose?: (isOpen: boolean) => void;
   [key: string]: unknown;
}

export function PublicProfileModal({
   id = 'Button',
   buttonSecondary,
   buttonPrimary,
   isOpen = false,
   _isLoading,
   _setIsLoading,
   onClose,
}: Props) {
   const modalId = `modal${id
      .toLocaleLowerCase()
      .split(' ')
      .join('_')
      .split('/')
      .join('_')}`;

   const toast = useToast();

   const [motives, setMotives] = React.useState(['']);
   const [reasonId, setReasonId] = React.useState();
   const [message, setMessage] = React.useState('');
   const [defaultSelected, setDefaultSelected] = React.useState(
      'Selecione um motivo',
   );

   const ASTERISC_REGEX: RegExp = /\*/g;
   const isCensored = ASTERISC_REGEX.test(message);

   const sendMessageIsNotAccept = !reasonId || message.length < 5 || isCensored;
   const disableButtonSend = sendMessageIsNotAccept ? { disabled: true } : {};

   const hasBadwordError = isCensored
      ? {
           error: true,
           'helper-text': 'Palavra inadequada. Revise para continuar!',
        }
      : {};

   React.useEffect(() => {
      if (onClose) {
         const modalElement = document.getElementById(modalId) as HTMLElement;

         modalElement?.addEventListener('click', e => {
            const targetTagName = (
               e.target as HTMLElement
            ).tagName.toLowerCase();

            if (targetTagName.includes('clb-button-icon')) {
               if (_isLoading) return;
               setTimeout(() => onClose(false), 400);
            }

            if (targetTagName.includes('clb-button')) {
               validateClbButton(e.target as HTMLElement, modalElement);
            }
         });
      }
   }, []);

   React.useEffect(() => {
      getSendMessageMotives();
   }, []);

   const getSendMessageMotives = React.useCallback(async () => {
      const reasons = await connectionsService.getReasons({ type: 1 });

      setMotives(reasons);
   }, []);

   function validateClbButton(target: HTMLElement, modalElement: HTMLElement) {
      if (_isLoading) return;

      const isButtonSecondary = target.attributes[0]?.value ? true : false;

      if (isButtonSecondary) {
         removeClassModal(modalElement);
      } else {
         setTimeout(() => {
            removeClassModal(modalElement);
         }, 1000);
      }
   }

   function removeClassModal(modalElement: HTMLElement) {
      modalElement.shadowRoot
         ?.querySelector('div')
         ?.classList.remove('container--isOpen');
   }

   const booleanProps = {
      isOpen: isOpen ? true : undefined,
   };

   async function handleUpdateProfile(
      userProfile: IUserProfile,
   ): Promise<IUserProfile | null> {
      const _userUpdated = await userService.updateUser(userProfile);

      if (!_userUpdated) {
         return null;
      } else {
         return _userUpdated;
      }
   }

   const loading = _isLoading && { loading: true };

   const windowSize = window.innerWidth;

   return (
      <clb-modal className={styles.close} id={modalId} {...booleanProps}>
         <div className={styles.modalContent}>
            <div className={styles.emojiHeadingWrapper}>
               <clb-emoji type={'disappointed-face'}></clb-emoji>
               <clb-heading-team size={windowSize > 768 ? 'sm' : 'lg'}>
                  Tem certeza que deseja <br /> tornar o seu perfil privado?
               </clb-heading-team>
               <div className={styles.descriptionWrapper}>
                  <clb-text size="default">
                     Ao confirmar, ele não ficará mais visível na vitrine <br />{' '}
                     de conexões. Lembre-se que você pode tornar seu <br />
                     perfil publico novamente sempre que precisar!
                  </clb-text>
               </div>
            </div>
            <div className={styles.selectWrapper}>
               <clb-select
                  defaultSelect={defaultSelected}
                  options={JSON.stringify(motives)}
                  labelText="Motivo"
                  //@ts-ignore
                  onChange={e => setReasonId(e.target.value)}
               ></clb-select>
            </div>

            <div className={styles.inputWrapper}>
               <clb-input-textarea
                  maxlength={140}
                  placeholder="Escreva sua mensagem aqui..."
                  label="Explica pra gente um pouco mais"
                  //@ts-ignore
                  value={message}
                  //@ts-ignore
                  onChange={e => setMessage(filterWords.filter(e.target.value))}
                  {...hasBadwordError}
               ></clb-input-textarea>
            </div>

            <div className={styles.modalButtonWrapper}>
               <clb-button
                  onClick={() => {
                     if (_isLoading) return;
                     buttonSecondary.onClick();
                  }}
                  type="secondary"
               >
                  Cancelar
               </clb-button>

               <clb-button
                  {...loading}
                  {...disableButtonSend}
                  onClick={() => {
                     _setIsLoading(true);
                     buttonPrimary.onClick({ message, reasonId });
                     setMessage('');
                  }}
               >
                  Confirmar
               </clb-button>
            </div>
         </div>
      </clb-modal>
   );
}
