export function validateAge(date: string) {
   const today = new Date();

   const parts = date.split('/');
   const birthday = parseInt(parts[0], 10);
   const birthmonth = parseInt(parts[1], 10);
   const birthyear = parseInt(parts[2], 10);

   const age = today.getFullYear() - birthyear;
   const month = today.getMonth() + 1;
   const day = today.getDate();

   if (age > 18) return true;
   if (age == 18 && birthmonth < month) return true;
   if (birthmonth == month && birthday < day) return true;
   return false;
}
