import api from '@services/api';
import { OpportunityProps, OpportunityItem } from '@services/opportunity.types';

export async function getOpportunityByInterest({
   interestId,
}: OpportunityProps): Promise<OpportunityItem[]> {
   try {
      const resp = await api.get(`opportunities/${interestId}/interest`);

      return resp.data as OpportunityItem[];
   } catch (error) {
      return [];
   }
}

export async function getOpportunities(): Promise<OpportunityItem[]> {
   try {
      const resp = await api.get(`opportunities`);

      return resp.data as OpportunityItem[];
   } catch (error) {
      return [];
   }
}
