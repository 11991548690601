import api from '@services/api';
import { IPdv } from '@services/pdv.types';
import { IValidateCode } from './login.types';

export async function savePdv({
   name,
   phone,
   dateOfBirth,
   cpfCnpj,
}: IPdv): Promise<IValidateCode | null> {
   try {
      const _cpfCnpj =
         cpfCnpj.length <= 11
            ? { cpf: cpfCnpj, cnpj: '' }
            : { cpf: '', cnpj: cpfCnpj };

      const resp = await api.post(`pdv`, {
         name: name,
         phone: phone,
         dateOfBirth: dateOfBirth,
         ..._cpfCnpj,
      });

      return resp.data;
   } catch (error) {
      return null;
   }
}
