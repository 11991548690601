import React from 'react';
import styles from './BirthDate.module.scss';
import { useNavigate } from 'react-router-dom';

import { validateDate } from '@utils/validateDate';
import { validateAge } from '@utils/validateAge';

interface Props {
   onClick: () => void;
   value: string;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export function BirthDate({ onClick, value, onChange }: Props) {
   const navigate = useNavigate();

   const [isError, setIsError] = React.useState(false);

   const error = isError
      ? { error: true, 'helper-text': 'Data inválida!' }
      : {};

   const notValid = value.length < 10;

   const isDisabled = notValid ? { disabled: true } : {};

   function onValidated() {
      if (notValid) return;

      if (!validateDate(value)) {
         setIsError(true);
         return;
      }

      if (!validateAge(value)) {
         navigate('/denied');
      }

      setIsError(false);
      onClick();
   }

   return (
      <div>
         <clb-heading-team size="sm">
            Agora, sua data <br />
            de nascimento.
         </clb-heading-team>

         <div className={styles.spacingText}>
            <clb-input-text
               label="Data de nascimento"
               placeholder="00/00/0000"
               value={value}
               onChange={onChange}
               mask="date"
               {...error}
            ></clb-input-text>
         </div>

         <clb-button onClick={onValidated} {...isDisabled}>
            Próximo
         </clb-button>
      </div>
   );
}
