import React, { useCallback, useState } from 'react';
import * as DashboardService from '@services/dashboard';

import { Header } from '@components/Header';
import styles from './Dashboard.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth';
import { ConnectionAnimatedDots } from '@components/ConnectionAnimatedDots';
import { IDashboardItems } from '@pages/Dashboard/Dashboard.types';
import RegistrationPerMonth from './RegistrationPerMonth';
import DashboardCard from '@components/DashboardCard';
import { Footer } from '@components/Footer';

import { useToast } from '@hooks/useToast';

export function Dashboard() {
   const navigate = useNavigate();
   const auth = useAuth();
   const toast = useToast();

   const [isLoading, setIsLoading] = useState(false);

   const [waitingList, setWaitingList] = useState<IDashboardItems[]>([]);
   const [pdvs, setPdvs] = useState<IDashboardItems[]>([]);
   const [pendingCandidates, setPendingCandidates] = useState<
      IDashboardItems[]
   >([]);
   const [registeredCandidates, setRegisteredCandidates] = useState<
      IDashboardItems[]
   >([]);

   const [usersWithIncompleteCard, setUsersWithIncompleteCard] = useState<
      IDashboardItems[]
   >([]);

   const [inactiveCandidates, setInactiveCandidates] = useState<
      IDashboardItems[]
   >([]);
   const [candidatesWhoSentMessage, setCandidatesWhoSentMessage] = useState<
      IDashboardItems[]
   >([]);
   const [candidatesWhoSentMessagesToPdvs, setCandidatesWhoSentMessagesToPdvs] =
      useState<IDashboardItems[]>([]);
   const [pdvsWhoSentMessages, setPdvsWhoSentMessages] = useState<
      IDashboardItems[]
   >([]);

   const [registrationsPerDay, setRegistrationsPerDay] = useState<
      IDashboardItems[]
   >([]);

   React.useEffect(() => {
      const userIsLogged = auth.checkUserIsLogged();
      if (!userIsLogged) {
         return navigate('/login', { state: { toast: true } });
      }
   }, []);

   React.useEffect(() => {
      getDashboardData();
   }, []);

   const getDashboardData = useCallback(async () => {
      if (isLoading) return;
      setIsLoading(true);
      const waitingListData = await DashboardService.getWaitinglist();
      const pdvsData = await DashboardService.getPdvs();
      const pendingCandidatesData =
         await DashboardService.getPendingCandidates();
      const registeredCandidatesData =
         await DashboardService.getRegisteredCandidates();
      const usersWithIncompleteCardData =
         await DashboardService.getUsersWithIncompleteCard();
      const inactiveCandidatesData =
         await DashboardService.getInactiveCandidates();
      const candidatesWhoSentMessageData =
         await DashboardService.getCandidatesWhoSentMessage();
      const candidatesWhoSentMessagesToPdvsData =
         await DashboardService.getCandidatesWhoSentMessagesToPdvs();
      const pdvsSentMessagesData =
         await DashboardService.getPdvsWhoSentMessages();
      const RegistrationsPerDayData =
         await DashboardService.getRegistrationsPerDay();

      setWaitingList(waitingListData);
      setPdvs(pdvsData);

      setPendingCandidates(pendingCandidatesData);
      setRegisteredCandidates(registeredCandidatesData);
      setUsersWithIncompleteCard(usersWithIncompleteCardData);

      setInactiveCandidates(inactiveCandidatesData);
      setCandidatesWhoSentMessage(candidatesWhoSentMessageData);
      setCandidatesWhoSentMessagesToPdvs(candidatesWhoSentMessagesToPdvsData);
      setPdvsWhoSentMessages(pdvsSentMessagesData);

      setRegistrationsPerDay(RegistrationsPerDayData);

      setIsLoading(false);
   }, []);

   async function handleClick(worksheetName: string) {
      const spreadsheet = await DashboardService.getSpreadsheet(
         `${worksheetName}`,
      );

      var data = new Blob([spreadsheet] as any, {
         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      if (data.size > 0) {
         const tempLink = document.createElement('a');
         tempLink.href = window.URL.createObjectURL(data);
         tempLink.download = `${worksheetName}.xlsx`;
         document.body.appendChild(tempLink);
         tempLink.click();

         setTimeout(function () {
            document.body.removeChild(tempLink);
         }, 100);
      } else {
         toast.show({
            title: 'Sem dados para download!',
            type: 'warning',
         });

         return;
      }
   }

   const windowSize = window.innerWidth;

   return (
      <>
         <Header links={false} />
         {isLoading ? (
            <div className={styles.loading}>
               <ConnectionAnimatedDots />
            </div>
         ) : (
            <div className={styles.content}>
               <div className={styles.wrapper}>
                  <div className={styles.title}>
                     <clb-heading-team size={windowSize > 768 ? 'sm' : 'lg'}>
                        Geral
                     </clb-heading-team>
                  </div>
                  <div className={styles.cardsWrapper}>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Pessoas usuárias cadastradas"
                           subtitle="PDVs e pessoas candidatas."
                           data={pdvs.length + registeredCandidates.length}
                        />
                     </div>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Cadastros na lista de espera"
                           data={waitingList.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick('report_cadastro_lista_espera')
                           }
                        />
                     </div>
                  </div>

                  <div className={styles.title}>
                     <clb-heading-team size={windowSize > 768 ? 'sm' : 'lg'}>
                        Pessoas candidatas
                     </clb-heading-team>
                  </div>
                  <div className={styles.cardsWrapper}>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Pessoas candidatas cadastradas"
                           subtitle=""
                           data={registeredCandidates.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick('report_candidatos_cadastrados')
                           }
                        />
                     </div>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Contatos realizados"
                           subtitle="Entre pessoas candidatas."
                           data={candidatesWhoSentMessage.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick(
                                 'report_candidatos_que_enviaram_mensagens_para_candidatos',
                              )
                           }
                        />
                     </div>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Conexões realizadas"
                           subtitle="Retorno do contato das pessoas candidatas para os PDVs."
                           data={candidatesWhoSentMessagesToPdvs.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick(
                                 'report_candidatos_que_enviaram_mensagens_para_pdvs',
                              )
                           }
                        />
                     </div>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Cadastros pendentes"
                           subtitle="Pessoas candidatas convidadas para a plataforma."
                           data={pendingCandidates.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick('report_candidatos_pendentes')
                           }
                        />
                     </div>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Perfis desabilitados"
                           subtitle="Perfil público desabilitado na vitrine."
                           data={inactiveCandidates.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick('report_candidatos_nao_ativos')
                           }
                        />
                     </div>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Perfis incompletos"
                           subtitle="Descrição sobre o perfil incompleta."
                           data={usersWithIncompleteCard.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick('report_pessoas_com_card_incompleto')
                           }
                        />
                     </div>

                     <div className={styles.title}>
                        <clb-heading-team size={windowSize > 768 ? 'sm' : 'lg'}>
                           PDVs
                        </clb-heading-team>
                     </div>

                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="PDVs cadastrados"
                           subtitle=""
                           data={pdvs.length}
                           // @ts-ignore
                           onClick={() => handleClick('report_cadastros_pdv')}
                        />
                     </div>
                     <div className={styles.cardWrapper}>
                        <DashboardCard
                           title="Contatos realizados"
                           subtitle="PDVs para pessoas candidatas."
                           data={pdvsWhoSentMessages.length}
                           // @ts-ignore
                           onClick={() =>
                              handleClick(
                                 'report_pdvs_que_enviaram_mensagens_para_candidatos',
                              )
                           }
                        />
                     </div>
                  </div>

                  <div className={styles.title}>
                     <clb-heading-team size={windowSize > 768 ? 'sm' : 'lg'}>
                        Cadastros realizados por mês
                     </clb-heading-team>
                  </div>
                  <div className={styles.chartWrapper}>
                     <div className={styles.icon}>
                        <clb-button-icon
                           icon={'Downfile'}
                           // @ts-ignore
                           onClick={() =>
                              handleClick('report_cadastros_por_dia')
                           }
                        />
                     </div>

                     <RegistrationPerMonth data={registrationsPerDay} />
                  </div>
               </div>
            </div>
         )}
         <Footer isHideNavigation />
      </>
   );
}
