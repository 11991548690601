import React from 'react';
import { NavLink, useResolvedPath, useLocation } from 'react-router-dom';

import '@meiuca/celebration-team-bora/dist/components/header-team/header-team.scss';
import logo from '@assets/logoBora.svg';

import { useAuth } from '@hooks/useAuth';
import { useDrawer } from '@hooks/useDrawer';

interface Props {
   links?: boolean;
}

export function Header({ links }: Props) {
   const auth = useAuth();
   const drawer = useDrawer();

   const [userLogged, setUserLogged] = React.useState<boolean>(false);
   const clbHeaderElement = React.createRef<HTMLElement>();

   const headerLinks = [
      { label: 'Início', to: '/' },
      {
         label: 'Conexões',
         to: userLogged ? '/connections' : '/connection-denied',
      },
      { label: 'Ajuda', to: '/help' },
   ];

   React.useEffect(() => {
      (clbHeaderElement.current as HTMLElement).addEventListener('click', e => {
         const target = e.target as HTMLElement;
         if (target?.textContent?.trim().includes('Entrar')) {
            drawer.openLoginDrawer();
         }
      });
   }, []);

   React.useEffect(() => {
      (clbHeaderElement.current as HTMLElement).addEventListener('click', e => {
         const target = e.target as HTMLElement;
         if (target?.textContent?.trim().includes('Olá, ')) {
            drawer.openProfileDrawer();
         }
      });
   }, []);

   React.useEffect(() => {
      const _userIsLogged = auth.checkUserIsLogged();
      if (_userIsLogged) {
         setUserLogged(_userIsLogged);
      }
   }, []);

   function HeaderNavLink({ label, to }: { label: string; to: string }) {
      const location = useLocation();
      const path = useResolvedPath(to);

      const isActive = location.pathname === path.pathname;

      return (
         <li>
            <NavLink to={to} className={() => `${isActive ? 'active' : ''}`}>
               {label}
            </NavLink>
         </li>
      );
   }

   const isLogged = userLogged
      ? {
           isLogged: true,
           usernamePrefix: 'Olá, ',
           username: `${auth.user!.name.split(' ')[0]}!`,
        }
      : {};

   return (
      <clb-header-team
         ref={clbHeaderElement}
         logo={logo}
         buttonLabel="Entrar"
         {...isLogged}
      >
         {(links || links == undefined) && (
            <ul className={'navigation-menu'}>
               {headerLinks.map((link, index) => (
                  <HeaderNavLink key={index} label={link.label} to={link.to} />
               ))}
            </ul>
         )}
      </clb-header-team>
   );
}
