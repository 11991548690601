import React from 'react';
import styles from './More18Years.module.scss';

import { useNavigate } from 'react-router-dom';

type TRadioOptions = 'yes' | 'no';

interface Props {
   onClick: () => void;
}

export function More18Years({ onClick }: Props) {
   const navigate = useNavigate();

   const [optionChecked, setOptionChecked] =
      React.useState<TRadioOptions | null>(null);

   const yesChecked = optionChecked === 'yes' ? { checked: true } : {};
   const noChecked = optionChecked === 'no' ? { checked: true } : {};
   const isOptionSelected = optionChecked === null ? { disabled: true } : {};

   function onValidated() {
      if (optionChecked === 'no') {
         navigate('/denied');
         return;
      }

      onClick();
   }

   function handleYes() {
      setOptionChecked('yes');
   }

   function handleNo() {
      setOptionChecked('no');
   }

   return (
      <div>
         <clb-heading-team size="sm">
            Você tem mais <br />
            de 18 anos?
         </clb-heading-team>

         <div className={styles.spacingRadioGroup}>
            <clb-radio-button onClick={handleYes} {...yesChecked}>
               Sim
            </clb-radio-button>

            <div className={styles.spacingRadio} />
            <clb-radio-button onClick={handleNo} {...noChecked}>
               Não
            </clb-radio-button>
         </div>

         <clb-button onClick={onValidated} {...isOptionSelected}>
            Próximo
         </clb-button>
      </div>
   );
}
