import { useCallback, useEffect, useState } from 'react';
import styles from './LookingFor.module.scss';

import { motion } from 'framer-motion';

import * as InterestService from '@services/Interest';
import { Props, IOptionLooking, TScreen } from './LookingFor.types';
import { WhichOportunities } from '@components/WhichOportunities';
import { IOptionOportunities } from '@components/WhichOportunities/WhichOportunities.types';
import { ConnectionAnimatedDots } from '@components/ConnectionAnimatedDots';

export function LookingFor({ onChangeSetValue }: Props) {
   const [isLoading, setIsLoading] = useState(false);

   const [screenSelect, setScreenSelect] = useState<TScreen>('lookingfor');
   const [oportunitiesPageIndex, setOportunitiesPageIndex] = useState(0);

   const [options, setOptions] = useState<IOptionLooking[]>([]);
   const [optionsSelected, setOptionsSelected] = useState<IOptionLooking[]>([]);

   const isDisabledButton =
      optionsSelected.length > 0 ? {} : { disabled: true };

   useEffect(() => {
      getInterest();
   }, []);

   const getInterest = useCallback(async () => {
      if (isLoading) return;
      setIsLoading(true);

      const interestData = await InterestService.getInterest();
      setOptions(interestData);
      setIsLoading(false);
   }, []);

   function handleCheckedOption(item: IOptionLooking) {
      if (optionsSelected.includes(item)) {
         const list = optionsSelected.filter(i => i !== item);
         setOptionsSelected(list);
      } else {
         setOptionsSelected([...optionsSelected, item]);
      }
   }

   function handleNext() {
      if (optionsSelected.length > 0) {
         setScreenSelect('oportunities');
      }
   }

   function handleNextPageOpportunities(items: IOptionOportunities[]) {
      optionsSelected[oportunitiesPageIndex].oportunities = items;

      if (oportunitiesPageIndex === optionsSelected.length - 1) {
         onChangeSetValue(optionsSelected);
         return;
      }

      setOportunitiesPageIndex(prev => prev + 1);
   }

   if (screenSelect === 'oportunities') {
      return (
         <WhichOportunities
            itemLooking={optionsSelected[oportunitiesPageIndex]}
            onClick={handleNextPageOpportunities}
         />
      );
   }

   return (
      <div className={styles.container}>
         <div className={styles.spacingText}>
            <clb-heading-team size="sm" displayRole="h3">
               O que você busca?
            </clb-heading-team>

            <clb-text>Selecione uma ou mais opções.</clb-text>
         </div>

         <div
            className={styles.spacingOptions}
            style={{
               height: isLoading ? '50vh' : 'auto',
               maxWidth: isLoading ? '100%' : 287,
            }}
         >
            {isLoading ? (
               <div className={styles.loading}>
                  <ConnectionAnimatedDots />
               </div>
            ) : (
               options.length > 0 &&
               options.map(item => {
                  const isChecked = optionsSelected.includes(item) && {
                     checked: true,
                  };

                  const isDisabled = !item.enabled && { disabled: true };

                  return (
                     <div className={styles.option} key={item.id}>
                        <clb-checkbox
                           id={`option-${item.id}`}
                           name={`option-${item.id}`}
                           label={item.name}
                           onClick={() => handleCheckedOption(item)}
                           {...isChecked}
                           {...isDisabled}
                        ></clb-checkbox>
                        <clb-text size="sm">
                           <span
                              className={`${
                                 !item.enabled ? `${styles.disabled}` : ''
                              }`}
                           >
                              {item.description}
                           </span>
                        </clb-text>
                     </div>
                  );
               })
            )}
         </div>

         {!isLoading && (
            <div className={styles.button}>
               <clb-button
                  aria-label="Entrar"
                  onClick={handleNext}
                  {...isDisabledButton}
               >
                  Próximo
               </clb-button>
            </div>
         )}
      </div>
   );
}
