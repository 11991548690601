import React from 'react';
import styles from './CnpjCpf.module.scss';

type TRadio = 'CNPJ' | 'CPF';

interface Props {
   value: string;
   valueDescription: string;
   onChange: (value: string) => void;
   reload: number;
}

export function CnpjCpf({ value, valueDescription, onChange, reload }: Props) {
   const [radioSelected, setRadioSelected] = React.useState<TRadio>(
      value.length === 18 ? 'CNPJ' : 'CPF',
   );

   const cnpjChecked = radioSelected === 'CNPJ' ? { checked: true } : {};
   const cpfChecked = radioSelected === 'CPF' ? { checked: true } : {};

   const [initialValue, setInitialValue] = React.useState(value);
   const [initialRadioSelected, setInitialRadioSelected] =
      React.useState<TRadio>(radioSelected);

   React.useEffect(() => {
      if (radioSelected === initialRadioSelected) {
         onChange(initialValue);
      } else {
         onChange('');
      }
   }, [radioSelected]);

   React.useEffect(() => {
      if (value !== valueDescription) {
         setRadioSelected(valueDescription.length === 18 ? 'CNPJ' : 'CPF');
         setInitialRadioSelected(
            valueDescription.length === 18 ? 'CNPJ' : 'CPF',
         );
      } else {
         setRadioSelected(value.length === 18 ? 'CNPJ' : 'CPF');
         setInitialRadioSelected(value.length === 18 ? 'CNPJ' : 'CPF');
      }

      setTimeout(() => {
         if (value.length === 0 || value !== valueDescription) {
            setInitialValue(valueDescription);
            onChange(valueDescription);
         } else {
            setInitialValue(value);
         }
      }, 100);
   }, [reload]);

   return (
      <>
         <div className={styles.container}>
            <clb-radio-button
               onClick={() => setRadioSelected('CNPJ')}
               {...cnpjChecked}
            >
               CNPJ
            </clb-radio-button>
            <clb-radio-button
               onClick={() => setRadioSelected('CPF')}
               {...cpfChecked}
            >
               CPF
            </clb-radio-button>
         </div>

         <div hidden={radioSelected !== 'CPF'}>
            <clb-input-text
               label="CPF"
               placeholder="000.000.000-00"
               mask="cpf"
               value={value}
               onChange={(e: any) => onChange(e.target.value)}
            ></clb-input-text>
         </div>

         <div hidden={radioSelected !== 'CNPJ'}>
            <clb-input-text
               label="CNPJ"
               placeholder="00.000.000/0000-00"
               mask="cnpj"
               value={value}
               onChange={(e: any) => onChange(e.target.value)}
            ></clb-input-text>
         </div>
      </>
   );
}
