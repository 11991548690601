import logo from '@assets/logoBora.svg';
import path from './../../../package.json';

interface Props {
   isHideNavigation?: boolean;
}

export function Footer({ isHideNavigation }: Props) {
   const linksLeft = [
      { label: 'Conexões', url: '/connections' },
      { label: 'Ajuda', url: '/help' },
   ];

   const linksRight = [
      {
         label: 'Ambev Voa',
         url: 'https://www.ambev.com.br/voa/',
         target: '_blank',
      },
      {
         label: 'Ambev On',
         url: 'https://linktr.ee/ambev_on',
         target: '_blank',
      },
   ];

   const linksFooterSimple = [
      { label: 'Termos de Uso', url: '/terms-of-use', target: '_blank' },
      {
         label: 'Política de Privacidade',
         url: '/privacy-policy',
         target: '_blank',
      },
   ];

   const hideNavigation = isHideNavigation ? { 'hide-navigation': true } : {};

   return (
      /*@ts-ignore */
      <clb-footer
         // @ts-ignore
         links-left={JSON.stringify(linksLeft)}
         // @ts-ignore
         links-right={JSON.stringify(linksRight)}
         links-footer-simple={JSON.stringify(linksFooterSimple)}
         logo={logo}
         {...hideNavigation}
         version={`Bora Ambev ${path.version} | `}
      ></clb-footer>
   );
}
