import React, { useEffect } from 'react';
import {
   BrowserRouter,
   Routes,
   Route,
   useLocation,
   Navigate,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { Home } from '@pages/Home';
import { Denied } from '@pages/Denied';
import { Connections } from '@pages/Connections';
import { ConnectionDenied } from '@pages/ConnectionDenied';
import { Help } from '@pages/Help';
import { FirstAccess } from '@pages/FirstAccess';
import { Pdv } from '@pages/Pdv';
import { WaitingList } from '@pages/WaitingList';

import { PrivacyPolicy } from '@pages/PrivacyPolicy';
import { TermsOfUse } from '@pages/TermsOfUse';

import { Dashboard } from '@pages/Dashboard';

import { DrawerProvider } from '@hooks/useDrawer';
import { Admin } from '@pages/Admin';

export default function RoutesApp() {
   function AnimatedRoutes() {
      const location = useLocation();

      return (
         <AnimatePresence>
            <DrawerProvider>
               <Routes location={location} key={location.pathname}>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Home />} />
                  <Route path="/denied" element={<Denied />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                  <Route path="/connections" element={<Connections />} />
                  <Route
                     path="/connection-denied"
                     element={<ConnectionDenied />}
                  />
                  <Route path="/help" element={<Help />} />
                  <Route path="/firstaccess" element={<FirstAccess />} />
                  <Route path="/waitinglist" element={<WaitingList />} />
                  <Route path="/pdv" element={<Pdv />} />

                  <Route path="/ZGFzaGJvYXJk" element={<Dashboard />} />
                  <Route path="/YWRtaW4=" element={<Admin />} />

                  <Route path="*" element={<Navigate to="/" />} />
               </Routes>
            </DrawerProvider>
         </AnimatePresence>
      );
   }

   return (
      <BrowserRouter>
         <AnimatedRoutes />
      </BrowserRouter>
   );
}
