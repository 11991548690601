import React from 'react';

import { Status as TStatus } from '@meiuca/celebration-team-bora/dist/components/toast-team/toast-team.types';

const ToastContext = React.createContext({} as IToastContext);

interface IToastContext {
   show: (props: IToastMessage) => void;
   close: () => void;
}

interface IToastMessage {
   title: string;
   type: TStatus;
   message?: string;
   link?: string;
   linkLabel?: string;
}

interface Props {
   children: React.ReactNode;
}

export function ToastProvider({ children }: Props) {
   const TIMER_CLOSE_TOAST = 10000;

   const [_show, _setShow] = React.useState(false);

   const [_title, _setTitle] = React.useState('');
   const [_message, _setMessage] = React.useState('');
   const [_link, _setLink] = React.useState('');
   const [_linkLabel, _setLinkLabel] = React.useState('');
   const [_type, _setType] = React.useState<TStatus>('positive');

   const toastTimerRef = React.useRef<NodeJS.Timeout | null>(null);

   const showToast = _show ? { visible: true } : {};

   React.useEffect(() => {
      if (_show) {
         toastTimerRef.current = setTimeout(() => {
            _setShow(false);
         }, TIMER_CLOSE_TOAST);
      }
   }, [_show]);

   function onCloseToast() {
      if (toastTimerRef.current) {
         clearTimeout(toastTimerRef.current);
      }

      if (_show) {
         _setShow(false);
      }
   }

   function close() {
      //@ts-ignore
      document
         .querySelector('clb-toast-team')!
         //@ts-ignore
         ?.shadowRoot.querySelector('clb-button-icon')
         ?.click();
   }

   const show = ({ title, type, message, link, linkLabel }: IToastMessage) => {
      close();

      setTimeout(() => {
         _setTitle(title);
         _setType(type);
         _setMessage(message ?? '');
         _setLink(link ?? '');
         _setLinkLabel(linkLabel ?? '');

         _setShow(true);
      }, 100);
   };

   return (
      <ToastContext.Provider value={{ show, close }}>
         {children}
         <div>
            <clb-toast-team
               {...showToast}
               title={_title}
               text={_message}
               link={_link}
               linkLabel={_linkLabel}
               status={_type}
               showtime={10}
               //@ts-ignore
               onClick={onCloseToast}
            ></clb-toast-team>
         </div>
      </ToastContext.Provider>
   );
}

export function useToast() {
   return React.useContext(ToastContext);
}
