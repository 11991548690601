import React from 'react';
import styles from './AgeGate.module.scss';

import { useNavigate } from 'react-router-dom';
import { useDrawer } from '@hooks/useDrawer';

type TOptions = 'Yes' | 'No';

export function AgeGate() {
   const navigate = useNavigate();
   const drawer = useDrawer();

   const [showModal, setShowmodal] = React.useState(false);
   const [optionChecked, setOptionChecked] = React.useState<TOptions | null>(
      null,
   );

   const KEY_AGE_GATE = `${process.env.REACT_APP_NAME_BD!}agegate`;
   const yesChecked = optionChecked === 'Yes' ? { checked: true } : {};
   const noChecked = optionChecked === 'No' ? { checked: true } : {};

   React.useEffect(() => {
      let lAgeGate = localStorage.getItem(KEY_AGE_GATE);

      if (lAgeGate) {
         if (JSON.parse(lAgeGate) === 'No') setShowmodal(true);
      } else {
         setShowmodal(true);
      }
   }, []);

   if (!showModal) return <></>;

   function handleContinue() {
      if (optionChecked === null) return;

      localStorage.setItem(KEY_AGE_GATE, JSON.stringify(optionChecked));

      if (optionChecked === 'Yes') {
         setShowmodal(false);
      } else if (optionChecked === 'No') {
         navigate('/denied');

         if (drawer.isOpen) {
            setShowmodal(false);
            drawer.closeDrawer();
         }
      }
   }

   function handleYes() {
      setOptionChecked('Yes');
   }

   function handleNo() {
      setOptionChecked('No');
   }

   return (
      <clb-modal id="modalAgeGate" isOpen={true} hideButtonClose>
         <div className={styles.modalContent}>
            <div className={styles.emojiHeadingWrapper}>
               <clb-emoji type="thinking-face"></clb-emoji>
               <clb-heading-team size="sm">
                  Você tem mais<br></br> de 18 anos?
               </clb-heading-team>
            </div>

            <div className={styles.modalRadiosWrapper}>
               <clb-radio-button onClick={handleYes} {...yesChecked}>
                  Sim
               </clb-radio-button>
               <clb-radio-button onClick={handleNo} {...noChecked}>
                  Não
               </clb-radio-button>
            </div>

            <div className={styles.modalButtonWrapper}>
               <clb-button onClick={handleContinue}>Continuar</clb-button>
            </div>
         </div>
      </clb-modal>
   );
}
