import React, { createContext, useContext } from 'react';

import { useLocation } from 'react-router-dom';
import { DrawerAnimationScreen } from '@components/DrawerAnimationScreen';

import { Login } from '@pages/Login';
import { Profile } from '@pages/Profile';

import { Props, IDrawerContext } from './useDrawer.types';

export const DrawerContext = createContext({} as IDrawerContext);

export const KEY_SAVE_USER = `${process.env.REACT_APP_NAME_BD}user`;

export function DrawerProvider({ children }: Props) {
   const location = useLocation();

   const isLoginRoute = location.pathname === '/login';

   const [isOpen, setIsOpen] = React.useState(isLoginRoute);
   const [isProfile, setIsProfile] = React.useState(false);
   const [isRemove, setIsRemove] = React.useState(false);

   const openLoginDrawer = React.useCallback(() => {
      setIsProfile(false);
      setIsOpen(true);
   }, []);

   const openProfileDrawer = React.useCallback(() => {
      setIsProfile(true);
      setIsOpen(true);
   }, []);

   const closeDrawer = React.useCallback(() => {
      setIsOpen(false);
   }, []);

   const removeDrawer = React.useCallback(() => {
      setIsRemove(true);

      setTimeout(() => {
         setIsOpen(false);
         setIsRemove(false);
      }, 100);
   }, []);

   return (
      <DrawerContext.Provider
         value={{
            isOpen,
            openLoginDrawer,
            openProfileDrawer,
            closeDrawer,
            removeDrawer,
         }}
      >
         {!isRemove ? (
            <DrawerAnimationScreen
               drawerPage={isProfile ? <Profile /> : <Login />}
               isOpen={isOpen}
            >
               {children}
            </DrawerAnimationScreen>
         ) : (
            <>{children}</>
         )}
      </DrawerContext.Provider>
   );
}

export function useDrawer() {
   return useContext(DrawerContext);
}
