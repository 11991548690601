import { AxiosError } from 'axios';
import api from '@services/api';

import {
   IValidatePhoneProps,
   IvalidatePhone,
   IValidateCode,
   IValidateCodeProps,
} from '@services/login.types';

export async function validatePhone({
   phone,
}: IValidatePhoneProps): Promise<IvalidatePhone | null> {
   try {
      await api.post(`authentication/validatephone`, { number: phone });
      return { isUser: true } as IvalidatePhone;
   } catch (error) {
      if ((error as AxiosError)?.response?.status === 404) {
         return { isUser: false };
      }

      if ((error as AxiosError)?.response?.status === 429) {
         return { isUser: false, accessBlocked: true };
      }

      return null;
   }
}

export async function validateCode({
   phone,
   code,
}: IValidateCodeProps): Promise<IValidateCode | null> {
   try {
      const resp = await api.post(`authentication/validatecode`, {
         phone,
         code,
      });

      return resp.data as IValidateCode;
   } catch (error) {
      const codeMessage = (error as AxiosError)?.response?.data;

      if ((error as AxiosError)?.response?.status === 429) {
         return {
            userName: '',
            token: '',
            expiration: '',
            isFirstAccess: false,
            codeNotExists: true,
            accessBlocked: true,
            isAdmin: false,
         };
      }

      if (codeMessage === 'O código ou o telefone informado não existe!') {
         return {
            userName: '',
            token: '',
            expiration: '',
            isFirstAccess: false,
            codeNotExists: true,
            isAdmin: false,
         };
      }

      return null;
   }
}
