import api from '@services/api';

import {
   IConnections,
   IConnectionsProps,
   IConnectionsSendMessage,
   IGetReasons,
} from './connections.types';

export async function getConnections({
   page = 1,
   zipcode,
   opportunitiesIds,
   interestsIds,
}: IConnectionsProps): Promise<IConnections[] | null> {
   try {
      const resp = await api.post(`connections?pageNumber=${page}&pageSize=9`, {
         zipcode,
         opportunitiesIds,
         interestsIds,
      });

      return resp.data;
   } catch (error) {
      return null;
   }
}

export async function getReasons({ type }: IGetReasons): Promise<string[]> {
   try {
      const resp = await api.get(`reasons/${type}`);
      return resp.data;
   } catch (error) {
      return [];
   }
}

export async function sendMessage(
   Message: IConnectionsSendMessage,
): Promise<boolean> {
   try {
      const resp = await api.post(`connections/sms`, Message);
      return resp.status === 200;
   } catch (error) {
      return false;
   }
}
