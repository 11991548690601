import {
   XAxis,
   YAxis,
   Tooltip,
   Legend,
   ResponsiveContainer,
   LineChart,
   Line,
   CartesianGrid,
} from 'recharts';

export default function RegistrationPerMonth({ data }: any) {
   const dataValidated: Array<Object> = data.reduce(
      (acc: any[], item: { month: any; candidates: any; pdvs: any }) => {
         let month = item.month;
         let repeated = acc.find(
            (elem: { month: any }) => elem.month === month,
         );

         if (repeated) {
            repeated.candidates += item.candidates;
            repeated.pdvs += item.pdvs;
         } else {
            acc.push(item);
         }
         return acc;
      },
      [],
   );

   const renderColorfulLegendText = (value: string) => {
      const color = '#666666';

      return <span style={{ color }}>{value}</span>;
   };

   return (
      <ResponsiveContainer width="100%" height={260}>
         <LineChart
            data={dataValidated}
            margin={{ left: 10, top: 10, right: 25 }}
         >
            <XAxis
               dataKey="month"
               style={{
                  fontFamily: 'IBM Plex Sans',
                  paddingRight: '45px',
                  fontSize: '14px',
               }}
               textAnchor="middle"
               dy={5}
               minTickGap={5}
               tick={{
                  fontSize: '14px',
               }}
            />
            <YAxis
               style={{ fontFamily: 'IBM Plex Sans', fontSize: '14px' }}
               width={40}
               interval={0}
               dy={1}
               tick={{
                  fontSize: '14px',
               }}
            />
            <Tooltip
               contentStyle={{
                  backgroundColor: '#292929',
                  opacity: '95%',
                  border: 'none',
                  borderRadius: '8px',
               }}
               labelStyle={{ color: 'white' }}
               wrapperStyle={{
                  fontFamily: 'IBM Plex Sans',
                  outline: 'none',
               }}
            />
            <Legend
               iconType="square"
               wrapperStyle={{
                  fontFamily: 'IBM Plex Sans',
                  fontSize: '14px',
                  paddingLeft: '38px',
                  paddingTop: '30px',
               }}
               formatter={renderColorfulLegendText}
               layout="horizontal"
               align="left"
            />
            <CartesianGrid stroke="#eee" />
            <Line
               dataKey="pdvs"
               name="PDVs"
               stroke="#2749FF"
               fill="#2749FF"
               strokeWidth={2}
            />
            <Line
               dataKey="candidates"
               name="Candidatos"
               fill="#FF6699"
               stroke="#FF6699"
               strokeWidth={2}
            />
         </LineChart>
      </ResponsiveContainer>
   );
}
