import styles from './HowTo.module.scss';
import img01 from '@assets/howToSectionImage01.svg';
import img02 from '@assets/howToSectionImage02.svg';
import img03 from '@assets/howToSectionImage03.svg';

import '@meiuca/celebration-team-bora';

export function HowTo() {
   return (
      <section className={styles.container}>
         <div className={styles.content}>
            <div className={styles.wrapper}>
               <div className={styles.title}>
                  <clb-heading-team size="xl">
                     Como tudo<br></br>isso funciona?
                  </clb-heading-team>
               </div>
               <div className={styles.cardsWrapper}>
                  <div className={styles.card}>
                     <img
                        loading="lazy"
                        className={styles.image}
                        src={img01}
                        alt="imagem de uma folha"
                     />
                     <div className={styles.headingWrapper}>
                        <clb-heading-team size="sm">
                           Qualificação
                        </clb-heading-team>
                     </div>
                     <clb-text size="lg">
                        A base do Bora são as trilhas de conteúdo em parceria
                        com grandes instituições, onde você aprende habilidades
                        essenciais.
                     </clb-text>
                  </div>
                  <div className={styles.card}>
                     <img
                        loading="lazy"
                        className={styles.image}
                        src={img02}
                        alt="imagem de uma folha"
                     />
                     <div className={styles.headingWrapper}>
                        <clb-heading-team size="sm">Incentivo</clb-heading-team>
                     </div>
                     <clb-text size="lg">
                        Também podemos oferecer incentivos financeiros para
                        ajudar você a se manter estudando ou alcançar seus
                        objetivos mais rápido.
                     </clb-text>
                  </div>
                  <div className={styles.card}>
                     <img
                        loading="lazy"
                        className={styles.image}
                        src={img03}
                        alt="imagem de uma folha"
                     />
                     <div className={styles.headingWrapper}>
                        <clb-heading-team size="sm">Conexão</clb-heading-team>
                     </div>
                     <clb-text size="lg">
                        Usamos toda a rede Ambev para criar formas criativas de
                        conectar as pessoas através dos seus objetivos em comum.
                     </clb-text>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}
