import ReactGA from 'react-ga4';

export function gaFormTracking(
   category: string,
   action: string,
   label: string,
) {
   const trackingId = 'G-ZBW3LN18DL';

   ReactGA.initialize(trackingId);

   ReactGA.event({
      category: category,
      action: action,
      label: label,
   });
}
