import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import './ConnectionCarousel.scss';
import image01 from '@assets/cardProfileExample01.svg';
import image02 from '@assets/cardProfileExample02.svg';

export function ConnectionCarousel() {
   const slides = [image01, image02];
   const [position, setPosition] = useState(0);

   const updateDots = (currentDot: any, targetDot: any) => {
      currentDot.classList.remove('currentDot');
      targetDot.classList.add('currentDot');
   };

   const prevSlide = () => {
      if (position > 0) {
         setPosition(position - 1);
         const currentDot = document.querySelector('.currentDot');
         if (currentDot) {
            const prevDot = currentDot.previousElementSibling;
            updateDots(currentDot, prevDot);
         }
      } else return;
   };

   const nextSlide = () => {
      if (position < slides.length - 1) {
         setPosition(position + 1);
         const currentDot = document.querySelector('.currentDot');
         if (currentDot) {
            const nextDot = currentDot.nextElementSibling;
            updateDots(currentDot, nextDot);
         }
      } else return;
   };

   const handlers = useSwipeable({
      onSwipedLeft: nextSlide,
      onSwipedRight: prevSlide,
   });

   return (
      <div className="carousel">
         <div {...handlers} className="row">
            {slides.map((url, index) => (
               <motion.div
                  className="container"
                  key={index}
                  animate={{
                     left: `${(index - position) * 336 - 172}px`,
                  }}
                  transition={{
                     type: 'spring',
                     stiffness: 260,
                     damping: 20,
                  }}
               >
                  <img src={url} alt="some stuff" />
               </motion.div>
            ))}
         </div>
         <div className="carouselControls">
            <div className="dotsWrapper">
               <div className="dot currentDot"></div>
               <div className="dot"></div>
            </div>
         </div>
      </div>
   );
}
