import api from '@services/api';
import { InterestItem } from '@services/Interest.types';

export async function getInterest(): Promise<InterestItem[]> {
   try {
      const resp = await api.get(`interests`);
      return resp.data;
   } catch (error) {
      return [];
   }
}
