import React from 'react';
import styles from './Interests.module.scss';

import { InterestItem } from '@services/Interest.types';
import { OpportunityItem } from '@services/opportunity.types';

import { useToast } from '@hooks/useToast';

export interface IItemsSelected {
   interests: string[];
   opportunities: string[];
}
interface Props {
   onUpdateInterestsOpportunities: (items: IItemsSelected) => Promise<boolean>;
   interests: InterestItem[];
   interestsSelected: string[];
   opportunities: OpportunityItem[];
   opportunitiesSelected: string[];
}

export function Interests({
   onUpdateInterestsOpportunities,
   interests,
   interestsSelected,
   opportunities,
   opportunitiesSelected,
}: Props) {
   const toast = useToast();

   const [isLoading, setIsLoading] = React.useState(false);

   const [_interestsSelected, _setInterestsSelected] = React.useState<string[]>(
      [],
   );

   const [_opportunitiesSelected, _setOpportunitiesSelected] = React.useState<
      string[]
   >([]);

   const loading = isLoading && { loading: true };

   //@ts-ignore
   const findJobisSelected = _interestsSelected.includes(1);

   const disabledOpportunities = !findJobisSelected && {
      disabled: true,
   };

   const disabledButton =
      (findJobisSelected && _opportunitiesSelected.length == 0) ||
      (_opportunitiesSelected.length == 0 && _interestsSelected.length === 0)
         ? { disabled: true }
         : {};

   React.useEffect(() => {
      if (_interestsSelected.length === 0) _setOpportunitiesSelected([]);
   }, [_interestsSelected]);

   React.useEffect(() => {
      _setInterestsSelected(interestsSelected);
      _setOpportunitiesSelected(opportunitiesSelected);
   }, []);

   function handleCheckInterest(itemId: string) {
      const itemExist = _interestsSelected.filter(i => i === itemId).length > 0;

      if (itemExist) {
         const listInterest = _interestsSelected.filter(i => i !== itemId);
         _setInterestsSelected(listInterest);
      } else {
         _setInterestsSelected([..._interestsSelected, itemId]);
      }

      if (!findJobisSelected) _setOpportunitiesSelected([]);
   }

   function handleCheckOpportunity(itemId: string) {
      const itemExist =
         _opportunitiesSelected.filter(i => i === itemId).length > 0;

      if (itemExist) {
         const listOpportunity = _opportunitiesSelected.filter(
            i => i !== itemId,
         );
         _setOpportunitiesSelected(listOpportunity);
      } else {
         _setOpportunitiesSelected([..._opportunitiesSelected, itemId]);
      }
   }

   async function handleUpdate() {
      if (isLoading) return;
      setIsLoading(true);
      toast.close();

      const isUpdated = await onUpdateInterestsOpportunities({
         interests: _interestsSelected,
         opportunities: _opportunitiesSelected,
      });

      if (isUpdated) {
         toast.show({
            title: `Interesses atualizados com sucesso!`,
            type: 'positive',
         });
      } else {
         toast.show({
            title: `Ocorreu um erro ao enviar sua mensagem!`,
            message: 'Por favor, tente novamente!',
            type: 'negative',
         });
      }

      setIsLoading(false);
   }

   return (
      <div className={styles.container}>
         <div className={styles.goalsContainer}>
            <clb-heading-team size="xs">Objetivos</clb-heading-team>

            <div className={styles.goalsSpacing}>
               {interests.map(interest => {
                  const isChecked =
                     _interestsSelected.filter(i => i === interest.id).length >
                     0;

                  const checked = isChecked && { checked: true };
                  const disabled = !interest.enabled && { disabled: true };

                  return (
                     <div className={styles.goal} key={interest.id}>
                        <clb-checkbox
                           id={`interest-${interest.id}`}
                           name={`interest-${interest.id}`}
                           label={interest.name}
                           onClick={() => {
                              if (!interest.enabled) return;
                              handleCheckInterest(interest.id);
                           }}
                           {...checked}
                           {...disabled}
                        ></clb-checkbox>
                        <clb-text size="sm">
                           <span
                              className={`${
                                 !interest.enabled ? `${styles.disabled}` : ''
                              }`}
                           >
                              {interest.description}
                           </span>
                        </clb-text>
                     </div>
                  );
               })}
            </div>
         </div>

         <div className={styles.oportunitiesContainer}>
            <clb-heading-team size="xs">Oportunidades</clb-heading-team>

            <div className={styles.oportunitiesSpacing}>
               {opportunities.map(opportunity => {
                  const isChecked =
                     _opportunitiesSelected.filter(i => i === opportunity.id)
                        .length > 0;

                  const checked = isChecked && { checked: true };

                  return (
                     <div className={styles.oportunity} key={opportunity.id}>
                        <clb-checkbox
                           id={`opportunity-${opportunity.id}`}
                           name={`opportunity-${opportunity.id}`}
                           label={opportunity.name}
                           onClick={() =>
                              handleCheckOpportunity(opportunity.id)
                           }
                           {...checked}
                           {...disabledOpportunities}
                        ></clb-checkbox>
                     </div>
                  );
               })}
            </div>
         </div>

         <div className={styles.buttonsContainer}>
            <clb-button
               aria-label="Atualizar"
               onClick={handleUpdate}
               {...loading}
               {...disabledButton}
            >
               Atualizar
            </clb-button>
         </div>
      </div>
   );
}
