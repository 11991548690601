type TCnpjCpf = 'CNPJ' | 'CPF';
type TFormatDate = 'PT-BR' | 'EUA';

export function formatCnpjCpf(value: string, type?: TCnpjCpf) {
   let cnpjCpf = value.replace(/\D/g, '');

   const valideType = cnpjCpf.length === 11 ? 'CPF' : 'CNPJ';
   const forceType = type ? type : valideType;

   if (forceType === 'CPF') {
      if (cnpjCpf.length > 11) {
         cnpjCpf = cnpjCpf.slice(3, 14);
      }

      return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
   }

   if (cnpjCpf.length < 14) {
      cnpjCpf = ('00000000000000' + cnpjCpf).slice(-14);
   }

   return cnpjCpf.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5',
   );
}

export function formatDate(value: string) {
   let isDateUSA = value.slice(0, 10).indexOf('-') > -1;

   if (isDateUSA) {
      const dateUsa = value.slice(0, 10).split('-');

      const day = dateUsa[2];
      const month = dateUsa[1];
      const year = dateUsa[0];

      return `${day}/${month}/${year}`;
   }

   let date = value.replace(/\D/g, '').slice(0, 8);

   const day = date.slice(0, 2);
   const month = date.slice(2, 4);
   const year = date.slice(4, 8);

   return `${day}/${month}/${year}`;
}

export function formatCep(value?: string): string {
   if (value) {
      let _cep = clearString(value);

      if (_cep.length === 8) {
         return `${_cep.slice(0, 5)}-${_cep.slice(5, 8)}`;
      } else {
         return `00000-000`;
      }
   } else {
      return `00000-000`;
   }
}

export function convertDate(value: string) {
   let isDateUSA = value.slice(0, 10).indexOf('-') > -1;
   const date = value.replace(/\D/g, '').slice(0, 8);

   if (isDateUSA) {
      const day = date.slice(6, 8);
      const month = date.slice(4, 6);
      const year = date.slice(0, 4);

      return `${year}-${month}-${day}`;
   }

   const day = date.slice(0, 2);
   const month = date.slice(2, 4);
   const year = date.slice(4, 8);

   return `${year}-${month}-${day}`;
}

export function formatPhone(value: string) {
   return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
      .replace(/(-\d{4})\d+$/, '$1');
}

export function clearString(value: string) {
   return value.replace(/[^\d]+/g, '');
}
