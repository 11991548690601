import styles from './TermsOfUse.module.scss';

import logo from '@assets/logoBora.svg';

export function TermsOfUse() {
   return (
      <>
         <clb-header-team logo={logo} empty></clb-header-team>

         <section className={styles.container}>
            <div className={styles.wrapper}>
               <div className={styles.textWrapper}>
                  <div className={styles.titleWrapper}>
                     <clb-heading-team size="sm">
                        Termos de Uso - Bora Ambev
                     </clb-heading-team>
                  </div>
                  <div className={styles.subtitleAndTextWrapper}>
                     <clb-text>
                        O Bora é uma iniciativa da AMBEV S.A., pessoa jurídica
                        com sede na cidade de São Paulo, inscrita no CNPJ sob o
                        nº 07.526.557/0001-00 ("AMBEV"), pela qual ela serviço
                        de recolocação para ajudar os clientes Ambev a conectar
                        pessoas da população geral que estejam em busca de uma
                        recolocação profissional, no qual o próprio interessado
                        realiza o cadastro para que seja avaliado por parceiros
                        possíveis oportunidades, sem qualquer garantia, por
                        parte da Ambev e parceiros que haverá a contratação.
                        <br />
                        <br />O acesso ao Site somente será liberado após o
                        preenchimento de cadastro pelo Usuário com as
                        informações requeridas pela AMBEV.
                        <br />
                        <br />O endereço do site está disponível por meio do
                        link:{' '}
                        <a href="https://bora.ambev.com.br/" target="_blank">
                           bora.ambev.com.br
                        </a>
                        <br />
                        <br />
                        Estes Termos estão disponíveis para acesso e consulta a
                        qualquer momento pelo Usuário, por meio do endereço{' '}
                        <a
                           href="https://bora.ambev.com.br/terms-of-use."
                           target="_blank"
                        >
                           https://bora.ambev.com.br/terms-of-use.
                        </a>{' '}
                        Ainda, aplicam-se aos presentes Termos e na utilização
                        das Páginas o quanto disposto na Política de Privacidade
                        e Proteção de Dados do Bora, disponível em{' '}
                        <a
                           href="https://bora.ambev.com.br/privacy-policy"
                           target="_blank"
                        >
                           https://bora.ambev.com.br/privacy-policy
                        </a>{' '}
                        <br />
                        <br />
                        Ainda, estes Termos poderão ser atualizados, a qualquer
                        tempo, pela AMBEV, mediante aviso no site e/ou por
                        e-mail, se o Usuário tiver optado por receber
                        comunicações da AMBEV.
                     </clb-text>
                  </div>
                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.subtitleWrapper}>
                        <clb-heading-team size="2xs">1.</clb-heading-team>
                        <clb-heading-team size="2xs">
                           CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO SITE
                        </clb-heading-team>
                     </div>
                  </div>
                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="whichDataWeUse"
                  >
                     <div className={styles.mb2x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           I) Caráter gratuito do acesso e utilização do Site
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        O acesso e a utilização das Páginas pelo Usuário têm,
                        como regra, caráter gratuito.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           II) Cadastro para utilização do Site
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        O Usuário que desejar utilizar o Site, deverá realizar o
                        cadastro no site. Para tanto, o Usuário deverá fornecer
                        informações e dados cadastrais, tais como: nome, data de
                        nascimento, endereço, e interesses profissionais. No
                        caso de PDV’s haverá também a coleta de CPF ou CNPJ).
                        <br />
                        <br />
                        Após o cadastro, o Usuario poderá ser redirecionado para
                        possíveis oportunidades de emprego, mas sem qualquer
                        garantia, por parte da AMBEV e parceiros que haverá
                        contratação. O acesso ao Site somente será liberado após
                        o preenchimento de cadastro pelo Usuário com as
                        informações requeridas pela AMBEV.
                        <br />
                        <br />
                        Eventualmente, nos casos em que o Usuário cadastrado
                        tiver interesse em estar por dentro de notícias,
                        conteúdos informativos e/ou institucionais do Site, que
                        não são relacionados as ferramentas da plataforma, ele
                        poderá optar pelo envio desse conteúdo no seu e-mail
                        cadastrado.
                        <br />
                        <br />
                        Toda informação fornecida pelo Usuário por meio do Site
                        deverá ser verdadeira. Assim, o Usuário garante a
                        autenticidade de todos os dados que informar durante o
                        preenchimento dos respectivos formulários. Desta forma,
                        o Usuário poderá exercer o seu direito de correção de
                        dados incompletos, inexatos ou desatualizados, para
                        manter toda a informação fornecida à AMBEV
                        permanentemente atualizada, de forma que sempre reflita
                        os dados reais do Usuário.
                        <br />
                        <br />
                        Em qualquer circunstância, o Usuário será o único
                        responsável por declarações falsas ou inexatas que
                        prestar e que vierem a causar prejuízos à AMBEV ou a
                        terceiros.
                     </clb-text>
                  </div>
                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2x}>
                        <clb-heading-team size="2xs">
                           III) Obrigação de utilizar o Site corretamente
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Ao utilizar o Site, o Usuário se compromete a respeitar
                        a legislação brasileira vigente, bem como todo o quanto
                        disposto nos presentes Termos, não devendo produzir,
                        disponibilizar, divulgar ou transmitir qualquer conteúdo
                        que:
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) Seja contrário a
                        qualquer norma da legislação brasileira, bem como à
                        moral e aos bons costumes geralmente aceitos;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) Incentive a
                        discriminação, o ódio ou a violência contra pessoas ou
                        grupos em razão de orientação sexual, gênero, raça,
                        religião, deficiência, nacionalidade ou por qualquer
                        outro motivo;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) Incorporem,
                        ponham à disposição ou permitam acessar produtos,
                        elementos, mensagens e/ou serviços ilegais, violentos,
                        pornográficos, degradantes ou, em geral, contrários à
                        lei, à moral e aos bons costumes geralmente aceitos ou à
                        ordem pública;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) Sejam falsos,
                        ambíguos, inexatos, exagerados ou extemporâneos, de
                        forma que possam induzir a erro sobre seu objeto ou
                        sobre as intenções ou propósitos do comunicante;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) Sejam protegidos
                        por quaisquer direitos de propriedade intelectual ou
                        industrial pertencentes a terceiros, sem que o Usuário
                        tenha obtido previamente dos seus titulares a
                        autorização necessária para levar a cabo o uso que
                        efetuar ou pretender efetuar;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f) Incorporem vírus
                        ou outros elementos físicos ou eletrônicos que possam
                        causar dano ou impedir o normal funcionamento da rede,
                        do sistema ou de equipamentos informáticos ("hardware" e
                        "software") de terceiros, ou que possam causar dano aos
                        documentos eletrônicos e arquivos armazenados nestes
                        equipamentos informáticos; ou,
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g) Provoquem, por
                        suas características (tais como forma, extensão etc.)
                        dificuldades no normal funcionamento do Site.
                        <br />
                        <br />
                        Em qualquer circunstância, o Usuário é o único
                        responsável pelo uso que fizer do Site, bem como por
                        qualquer conteúdo por ele inserido.
                     </clb-text>
                  </div>

                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="whoWeShareDataWith"
                  >
                     <div className={styles.mb2x}>
                        <clb-heading-team
                           className={styles.mb2x}
                           size="2xs"
                           id=""
                        >
                           IV) Utilização Correta do Conteúdo e Propriedade
                           Intelectual
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        O Usuário se compromete a utilizar as Páginas e o seu
                        conteúdo, ou seja, os textos, gráficos, ícones, links,
                        as fotografias, imagens, tecnologia, e demais conteúdos
                        audiovisuais ou sonoros, softwares, desenhos gráficos e
                        códigos fonte (em conjunto, "Conteúdo(s)"), de acordo
                        com todo o ordenamento jurídico, com a moral e os bons
                        costumes geralmente aceitos, com os presentes Termos e
                        com as Condições Particulares de cada Página.
                        <br />
                        <br />
                        Todas as marcas, os nomes comerciais ou logotipos de
                        qualquer espécie disponibilizados nas Páginas são de
                        propriedade da AMBEV, sem que a utilização das Páginas
                        possa ser entendida como autorização para que o Usuário
                        possa citar tais marcas, nomes comerciais e/ou
                        logotipos.
                        <br />
                        <br />
                        Todo o Conteúdo é de propriedade exclusiva da AMBEV, ou
                        de terceiros que autorizaram sua utilização nas Páginas,
                        e está protegido por leis e tratados internacionais,
                        sendo vedada sua cópia, reprodução, ou qualquer outro
                        tipo de utilização, ficando os infratores sujeitos às
                        sanções civis e criminais correspondentes, nos termos
                        das Leis 9.610/98, 9.609/98 e 9.279/96.
                        <br />
                        <br />O Usuário deverá se abster de obter, ou de tentar
                        obter, os Conteúdos por meios distintos daqueles que, em
                        cada caso, tenham sido colocados à disposição para tais
                        propósitos.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2x}>
                        <clb-heading-team size="2xs" id="cookies">
                           V) Política Anti-Spamming da AMBEV
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Por meio deste documento o Usuário se obriga a abster-se
                        de:
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) obter dados com
                        finalidade publicitária e remeter publicidade de
                        qualquer classe com finalidade de venda ou outras de
                        natureza comercial sem a prévia solicitação e
                        consentimento;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) remeter a um
                        grupo de pessoas quaisquer mensagens não solicitadas nem
                        consentidas previamente;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) enviar cadeias de
                        mensagens eletrônicas não solicitadas nem previamente
                        consentidas;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) utilizar listas
                        de distribuição a que se pode ter acesso através do Site
                        para a realização das atividades descritas nos itens
                        acima; e<br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) colocar à
                        disposição de terceiros, com qualquer finalidade, dados
                        captados a partir de listas de distribuição.
                        <br />
                        <br />O Usuário ou terceiros prejudicados pela recepção
                        de mensagens não solicitadas, relacionadas ao Site e
                        dirigidas a pluralidade de pessoas poderão efetuar sua
                        reclamação comunicando a AMBEV por meio do e-mail{' '}
                        <a href="mailto:bora@ambev.com.br">bora@ambev.com.br</a>
                     </clb-text>
                  </div>

                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="howWeKeepDataSafe"
                  >
                     <div className={styles.mb2x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           VI) Introdução de "hiperlinks" que permitam o acesso
                           ao Site
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        O Usuário que se propuser a estabelecer hiperlink entre
                        sua página web e ao Site ("Hiperlink") deverá cumprir as
                        seguintes condições:
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) o "Hiperlink"
                        permitirá única e exclusivamente o acesso à homepage ou
                        à página de início do Site, mas não poderá reproduzi-la
                        de qualquer forma;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) não criar um
                        frame sobre o Site;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) não realizar
                        manifestações ou indicações falsas, inexatas ou
                        incorretas sobre a AMBEV, seus administradores,
                        empregados, ou Site;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) não declarar nem
                        fazer entender que a AMBEV teria autorizado o
                        "Hiperlink" ou que teria supervisionado ou assumido, sob
                        qualquer forma, responsabilidade sobre os serviços
                        oferecidos ou colocados à disposição na página web em
                        que for estabelecido o "Hiperlink";
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) com exceção dos
                        sinais que formarem parte do "Hiperlink", a página web
                        em que se estabelecer o "Hiperlink" não conterá nenhuma
                        marca, nome
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f) comercial,
                        logotipo, slogan, look and feel ou quaisquer outros
                        sinais pertencentes à AMBEV;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g) a página web em
                        que estabelecer o "Hiperlink" não deverá conter
                        informações ou conteúdos ilícitos, contrários à moral,
                        aos bons costumes ou à ordem pública, bem como não
                        conterá conteúdos contrários aos direitos de terceiros.
                        <br />
                        <br />A fixação de um "Hiperlink" não implica, em
                        qualquer hipótese, a existência de relações entre a
                        AMBEV e o proprietário da página web que o contiver, nem
                        a aceitação ou aprovação da AMBEV acerca de seus
                        conteúdos ou serviços.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           2. DURAÇÃO E FINALIZAÇÃO
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        O acesso ao Site é concedido ao Usuário por prazo
                        indeterminado. A AMBEV, no entanto, está facultada a dar
                        por terminado, suspender ou interromper unilateralmente,
                        a qualquer momento e sem necessidade de prévio aviso, o
                        acesso ao Site, sem que qualquer indenização seja devida
                        ao Usuário.
                        <br />
                        <br />A AMBEV, ainda, se reserva o direito de recusar ou
                        retirar o acesso ao Site, a qualquer momento e sem
                        necessidade de prévio aviso, por iniciativa própria ou
                        por exigência de um terceiro, àqueles Usuários que
                        descumprirem estes Termos.
                     </clb-text>
                  </div>

                  <div
                     className={styles.subtitleAndTextWrapper}
                     id="yourRights"
                  >
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           3. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Em decorrência de questões técnicas e operacionais, a
                        AMBEV não pode garantir a disponibilidade,
                        infalibilidade e continuidade do funcionamento do Site,
                        nem garante a utilidade do Site para a realização de
                        qualquer atividade em concreto. Desse modo, a AMBEV não
                        será, em hipótese alguma, responsável por quaisquer
                        danos decorrentes da interrupção do acesso ao Site, ou
                        por falhas de funcionamento das mesmas, nem se
                        responsabiliza pela defraudação da utilidade que os
                        Usuários possam ter atribuídos ao Site, pela
                        falibilidade do mesmo, nem por qualquer dificuldade de
                        acesso.
                        <br />
                        <br />A AMBEV utiliza as melhores práticas recomendadas
                        de mercado para manter seguros todos os dados inseridos
                        pelo Usuário no Site, no entanto não garante que
                        terceiros não autorizados não farão uso de meios
                        ilícitos para obter indevidamente tais informações.
                        Assim, a AMBEV se exime de responsabilidade pelos danos
                        e prejuízos de toda natureza que possam decorrer do
                        conhecimento que terceiros não autorizados possam ter de
                        quaisquer das informações repassadas, em decorrência de
                        falha exclusivamente atribuível aos Usuários ou a
                        terceiros que fujam a qualquer controle razoável da
                        AMBEV.
                        <br />
                        <br />A AMBEV não garante a ausência de vírus no Site,
                        bem como de outros elementos nocivos que possam produzir
                        alterações nos sistemas informáticos dos Usuários
                        <br />
                        <br />
                        (software e hardware) ou nos documentos eletrônicos
                        armazenados no sistema informático, eximindo-se de
                        qualquer responsabilidade pelos danos e prejuízos que
                        possam decorrer de quaisquer elementos nocivos.
                        <br />
                        <br />A AMBEV não verifica ou controla o uso que o
                        Usuário faz do Site. Dessa forma, a AMBEV se exime de
                        qualquer responsabilidade pelos danos e prejuízos de
                        toda natureza que possam decorrer da utilização do Site
                        ou que possam ser devidos à falta de veracidade,
                        vigência, exaustividade e/ou autenticidade do conteúdo
                        lá disponibilizado, bem como da informação que os
                        Usuários proporcionam a outros Usuários sobre si mesmos
                        e, em particular, ainda que não de forma exclusiva,
                        pelos danos e prejuízos de toda a natureza que possam
                        ser devidas à suplantação da personalidade efetuada por
                        um Usuário em qualquer classe de comunicação realizada
                        por meio do Site.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           4. PROCEDIMENTO EM CASO DE REALIZAÇÃO DE ATIVIDADES
                           DE CARÁTER ILÍCITO
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Na hipótese de um Usuário ou um terceiro considerar que
                        existem informações, fatos ou circunstâncias que
                        constituem atividade ilícita, no Site, especialmente em
                        caso de violação de direitos de propriedade intelectual
                        ou outros direitos, deverá enviar comunicação à AMBEV
                        contendo os seguintes dados:
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) dados pessoais:
                        nome, endereço, número de telefone e endereço de correio
                        eletrônico do reclamante;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) especificação da
                        suposta atividade ilícita ocorrida nas Páginas e, em
                        particular, quando se tratar de suposta violação de
                        direitos autorais, indicação precisa e completa dos
                        conteúdos protegidos e supostamente infringidos;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) fatos ou
                        circunstâncias que revelam o caráter ilícito de tal
                        atividade; e<br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) se o caso,
                        declaração expressa e clara de que a utilização dos
                        conteúdos foi realizada sem o consentimento do titular
                        dos direitos de propriedade intelectual supostamente
                        infringidos.
                        <br />
                        <br />
                        Sempre de acordo com a legislação em vigor, a partir da
                        ciência, a AMBEV poderá excluir conteúdo acusado como
                        ilícito, assim como fornecer informações às autoridades
                        competentes, mediante o competente requerimento, com a
                        finalidade de viabilizar a apuração de condutas ilegais.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           5. DISPOSIÇÕES GERAIS
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Se qualquer parte destes Termos for considerada inválida
                        ou inexequível, tal trecho deve ser interpretado de
                        forma consistente com a lei aplicável, para refletir, na
                        medida do possível, a intenção original das partes, e as
                        demais disposições permanecerão em pleno vigor e efeito.
                        <br />
                        <br />A falha da AMBEV em exigir quaisquer direitos ou
                        disposições dos presentes Termos não constituirá
                        renúncia, podendo exercer regularmente o seu direito,
                        dentro dos prazos legais.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           6. NOTIFICAÇÕES
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Todas as notificações e comunicações (doravante, as
                        "Notificações") por parte do Usuário à AMBEV são
                        consideradas eficazes, para todos os efeitos, quando
                        forem dirigidas por meio do canal do site{' '}
                        <a href="mailto:bora@ambev.com.br">bora@ambev.com.br</a>
                        <br />
                        <br />
                        Todas as notificações e comunicações (doravante, as
                        "Notificações") por parte da AMBEV ao Usuário serão
                        consideradas eficazes, para todos os efeitos, quando se
                        realizarem em uma das seguintes formas:
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) envio de carta ao
                        domicílio do Usuário quando este tiver fornecido um
                        endereço válido à AMBEV;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) envio de mensagem
                        por correio eletrônico a qualquer dos endereços e/ou
                        Redes Sociais fornecidos pelo Usuário;
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) comunicação
                        telefônica ao número fornecido pelo Usuário, como por
                        SMS e/ou por WhatsApp; e,
                        <br />
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) mediante a
                        inclusão de mensagens em qualquer das Páginas da AMBEV.
                        <br />
                        <br />
                        Neste sentido, todas as Notificações que a AMBEV
                        realizar serão consideradas válidas quando efetuadas
                        empregando os dados e através dos meios anteriormente
                        destacados. Para estes efeitos, o Usuário declara que
                        todos os dados fornecidos são válidos e corretos,
                        comprometendo-se a comunicar à AMBEV todas as mudanças
                        relativas aos dados de notificação.
                     </clb-text>
                  </div>

                  <div className={styles.subtitleAndTextWrapper}>
                     <div className={styles.mb2xmt1x}>
                        <clb-heading-team className={styles.mb2x} size="2xs">
                           7. LEGISLAÇÃO E FORO APLICÁVEIS
                        </clb-heading-team>
                     </div>
                     <clb-text>
                        Esses Termos serão regidos, interpretados e executados
                        de acordo com as Leis da República Federativa do Brasil,
                        independentemente dos conflitos dessas leis com leis de
                        outros estados ou países, sendo competente o Foro de
                        domicílio do Usuário, para dirimir qualquer dúvida
                        decorrente deste instrumento.
                        <br />
                        <br />
                        Atualizado em 05 de setembro de 2022.
                     </clb-text>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
}
