import card01 from '@assets/cardProfileExample01.svg';
import card02 from '@assets/cardProfileExample02.svg';
import pattern from '@assets/pattern02.svg';
import heartIcon from '@assets/heartIcon.svg';
import eyeIcon from '@assets/eyeIcon.svg';
import messageIcon from '@assets/messageIcon.svg';
import shareIcon from '@assets/shareIcon.svg';
import { AgeGate } from '@components/AgeGate';
import { ConnectionCarousel } from '@components/ConnectionCarousel';
import { Footer } from '@components/Footer';
import { Header } from '@components/Header';
import { useAuth } from '@hooks/useAuth';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ConnectionDenied.module.scss';

export function ConnectionDenied() {
   const auth = useAuth();
   const navigate = useNavigate();

   React.useEffect(() => {
      const userIsLogged = auth.checkUserIsLogged();
      if (userIsLogged) return navigate('/connections');
   }, []);

   return (
      <div className={styles.deniedContainer}>
         <Header />
         <section className={styles.container}>
            <div className={styles.content}>
               <div className={styles.wrapper}>
                  <div className={styles.contentSection}>
                     <div className={styles.titleTextWrapper}>
                        <clb-heading-team size="xl">
                           Conecte-se com perfis que buscam oportunidades
                        </clb-heading-team>
                        <clb-text size="lg">
                           O Bora <b>promove a conexão</b> entre pessoas que
                           querem trabalhar e pessoas empreendedoras (PDVs) que
                           querem contratar, com a finalidade de criar formas
                           criativas de rede e conexão com esses perfis através
                           de objetivos em comum.
                        </clb-text>
                     </div>
                     <div className={styles.cardsWrapper}>
                        <clb-heading-team size="xs">
                           Quem busca trabalhar
                        </clb-heading-team>
                        <div className={styles.card}>
                           <div className={styles.iconWrapper}>
                              <img src={heartIcon} alt="ícone de coração" />
                           </div>
                           <clb-text>
                              Cria seu cadastro na vitrine com <b>interesses</b>{' '}
                              e <b>objetivos</b> de emprego.
                           </clb-text>
                        </div>
                        <div className={styles.card}>
                           <div className={styles.iconWrapper}>
                              <img src={messageIcon} alt="ícone de sms" />
                           </div>
                           <clb-text>
                              As pessoas que buscam um trabalho{' '}
                              <b>serão avisadas</b> por SMS sempre que alguém
                              demonstrar interesse no seu perfil.
                           </clb-text>
                        </div>
                        <clb-heading-team size="xs">
                           Quem busca contratar
                        </clb-heading-team>
                        <div className={styles.card}>
                           <div className={styles.iconWrapper}>
                              <img src={eyeIcon} alt="ícone de olho aberto" />
                           </div>
                           <clb-text>
                              Os perfis cadastrados na vitrine{' '}
                              <b>ficam visíveis</b> para quem necessita de mão
                              de obra.
                           </clb-text>
                        </div>
                        <div className={styles.card}>
                           <div className={styles.iconWrapper}>
                              <img
                                 src={shareIcon}
                                 alt="ícone de compartilhamento"
                              />
                           </div>
                           <clb-text>
                              As pessoas empreendedoras <b>se conectam</b> com
                              esses perfis já qualificados por parceiros
                              educacionais da Ambev.
                           </clb-text>
                        </div>
                     </div>
                  </div>
                  <div className={styles.cardsProfileWrapper}>
                     <img src={card01} alt="cardProfileImage" />
                     <img src={card02} alt="cardProfileImage" />
                  </div>
               </div>
            </div>
         </section>

         <ConnectionCarousel />

         <section className={styles.container}>
            <div className={styles.content}>
               <div className={styles.wrapperNoGrid}>
                  <clb-emoji type="smiling-face"></clb-emoji>
                  <div className={styles.textWrapper}>
                     <clb-heading-team size="xs">
                        Para acessar a vitrine de
                        <br className={styles.brMobile}></br> conexões você
                        precisa ter <br className={styles.brMobile}></br>
                        um cadastro.
                     </clb-heading-team>
                     <clb-text>
                        Faça o login ou cadastre-se
                        <br className={styles.brMobile}></br> ao clicar em
                        entrar.
                     </clb-text>
                  </div>
                  <img
                     className={styles.image}
                     src={pattern}
                     alt="hero image"
                  />
               </div>
            </div>
         </section>

         <Footer isHideNavigation />

         <AgeGate />
      </div>
   );
}
