import api from '@services/api';
import { WaitingListItem } from '@services/waitingList.types';
import { AxiosError } from 'axios';

export async function saveWaitingList({
   phone,
   name,
   foundBy,
}: WaitingListItem): Promise<WaitingListItem[] | null> {
   try {
      const resp = await api.post(`waitinglist`, {
         phone: phone,
         name: name,
         foundBy: foundBy,
      });
      return resp.data;
   } catch (error) {
      const _error = error as AxiosError;

      if (
         //@ts-ignore
         _error?.response?.data?.status === 400 &&
         //@ts-ignore
         _error?.response?.data?.errors
      ) {
         return [
            {
               phone: phone,
               name: name,
               foundBy: foundBy,
            },
         ];
      }

      return null;
   }
}
