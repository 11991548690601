import React from 'react';
import styles from './InformCNPJCPF.module.scss';

import validateCpf from '@utils/validateCpf';
import validateCnpj from '@utils/validateCnpj';
import { CheckboxTerms } from '@components/CheckboxTerms';

type TRadio = 'CNPJ' | 'CPF';

interface Props {
   onClick: () => void;
   value: string;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
   isLoading?: boolean;
}

export function InformCNPJCPF({ onClick, value, onChange, isLoading }: Props) {
   const [radioSelected, setRadioSelected] = React.useState<TRadio>('CNPJ');
   const [isIagree, setIsIagree] = React.useState(false);
   const [isError, setIsError] = React.useState<TRadio | null>(null);

   const cnpjChecked = radioSelected === 'CNPJ' ? { checked: true } : {};
   const cpfChecked = radioSelected === 'CPF' ? { checked: true } : {};
   const loading = isLoading ? { loading: true } : {};

   const disabled = isIagree && value.length > 10 ? {} : { disabled: true };

   const error = () => {
      if (isError === 'CNPJ') {
         return { error: true, 'helper-text': 'CNPJ inválido' };
      } else if (isError === 'CPF') {
         return { error: true, 'helper-text': 'CPF inválido' };
      }

      return {};
   };

   function handleFinish() {
      if (isLoading || (isError !== null && value.length <= 10)) return;

      const _value = value.replace(/\D/g, '');

      if (radioSelected === 'CNPJ' && !validateCnpj.isValid(_value)) {
         setIsError('CNPJ');
         return;
      }

      if (radioSelected === 'CPF' && !validateCpf.isValid(_value)) {
         setIsError('CPF');
         return;
      }

      setIsError(null);
      onClick();
   }

   return (
      <div>
         <clb-heading-team size="sm">
            Informe seu CNPJ <br />
            ou CPF, por favor.
         </clb-heading-team>

         <div className={styles.spacingRadioGroup}>
            <clb-radio-button
               onClick={() => setRadioSelected('CNPJ')}
               {...cnpjChecked}
            >
               CNPJ
            </clb-radio-button>
            <clb-radio-button
               onClick={() => setRadioSelected('CPF')}
               {...cpfChecked}
            >
               CPF
            </clb-radio-button>
         </div>

         <div hidden={radioSelected !== 'CPF'}>
            <clb-input-text
               label="CPF"
               placeholder="000.000.000-00"
               mask="cpf"
               value={value}
               onChange={onChange}
               {...error()}
            ></clb-input-text>
         </div>

         <div hidden={radioSelected !== 'CNPJ'}>
            <clb-input-text
               label="CNPJ"
               placeholder="00.000.000/0000-00"
               mask="cnpj"
               value={value}
               onChange={onChange}
               {...error()}
            ></clb-input-text>
         </div>

         <div className={styles.spacingIagree}>
            <CheckboxTerms
               checked={isIagree}
               onClick={() => setIsIagree(prev => !prev)}
            />
         </div>

         <clb-button onClick={handleFinish} {...disabled} {...loading}>
            Finalizar
         </clb-button>
      </div>
   );
}
