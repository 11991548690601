import React from 'react';
import styles from './Connections.module.scss';

import { clearString } from '@utils/formatString';

interface Props {
   onFilter: () => void;
   onSearch: () => void;
   value: string;
   onChange: React.ChangeEventHandler<HTMLInputElement>;
   isLoading: boolean;
}

export function HeaderConnection({
   onFilter,
   onSearch,
   value,
   onChange,
   isLoading,
}: Props) {
   function handleSearchCep(e: React.KeyboardEvent<HTMLElement>) {
      if (isLoading) return;

      const cep = clearString(value);
      const searchCep = [0, 8].includes(cep.length);

      if (e.key === 'Enter' && searchCep) {
         onSearch();
      }
   }

   function handleSearchCepButton(e: React.MouseEvent<HTMLElement>) {
      if (isLoading) return;

      //@ts-ignore
      if (e.target.tagName.toLowerCase() === 'input') {
         const cep = clearString(value);
         const searchCep = [0, 8].includes(cep.length);

         if (searchCep) {
            onSearch();
         }
      }
   }

   return (
      <>
         <div className={styles.headingAndTextWrapper}>
            <clb-heading-team size="xl">Conexões</clb-heading-team>
            <clb-text size="lg">
               Mais de 1.000 pessoas em busca de emprego em bares e restaurantes
               na cidade do Rio de Janeiro.
            </clb-text>
         </div>

         <div className={styles.inputAndFilterWrapper}>
            <div className={styles.headingInputWrapper}>
               <clb-heading-team size="2xs">
                  Encontre pessoas próximas a você
               </clb-heading-team>
               <clb-input-search
                  placeholder="Buscar pelo CEP..."
                  //@ts-ignore
                  mask="cep"
                  value={value}
                  onChange={onChange}
                  onKeyUp={handleSearchCep}
                  onClick={handleSearchCepButton}
               ></clb-input-search>
            </div>

            <div className={styles.buttonFilterWrapper}>
               <clb-button onClick={onFilter} icon="Filter02" type="tertiary">
                  Filtros
               </clb-button>
            </div>
         </div>

         <clb-divider></clb-divider>
      </>
   );
}
