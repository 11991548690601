import { ConnectionAnimatedDots } from '@components/ConnectionAnimatedDots';
import React from 'react';
import styles from './ConnectionSearchingFor.module.scss';

export function ConnectionSearchingFor() {
   return (
      <div className={styles.container}>
         <div className={styles.dotsAndHeadingWrapper}>
            <ConnectionAnimatedDots />
            <clb-heading-team size="2xs">
               Procurando conexões para você...
            </clb-heading-team>
         </div>
      </div>
   );
}
