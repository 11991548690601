import React from 'react';
import styles from './Connections.module.scss';

import { useNavigate, useLocation } from 'react-router-dom';

import { Header } from '@components/Header';
import { ConnectionSearchingFor } from '@components/ConnectionSearchingFor';
import { ConnectionModal } from '@components/ConnectionModal';
import { Footer } from '@components/Footer';
import {
   ConnectionFilters,
   IItemsSelected,
} from '@components/ConnectionFilters';

import { getInterest } from '@services/Interest';
import { InterestItem } from '@services/Interest.types';
import { getOpportunityByInterest } from '@services/opportunity';
import { OpportunityItem } from '@services/opportunity.types';
import * as connectionsService from '@services/connections';
import { IConnections } from '@services/connections.types';

import { useAuth } from '@hooks/useAuth';
import { useToast } from '@hooks/useToast';
import { clearString } from '@utils/formatString';

import { HeaderConnection } from './HeaderConnection';
import { ModalButton } from '@components/ModalButton';

interface ITagsFilter {
   id: string;
   name: string;
   type?: string;
}

export function Connections() {
   const auth = useAuth();
   const toast = useToast();
   const navigate = useNavigate();
   const location = useLocation();

   const [isLoading, setIsLoading] = React.useState(false);
   const [isLoadingMore, setIsLoadingMore] = React.useState(false);
   const [showButtonLoadMore, setShowButtonLoadMore] = React.useState(true);

   const [connectionData, setConnectionData] = React.useState<IConnections[]>(
      [],
   );

   const [showFilters, setShowFilters] = React.useState(false);
   const [showModalWhats, setShowModalWhats] = React.useState(false);
   const [userIdWhatsSelected, setUserIdWhatsSelected] = React.useState('');

   const [showModalWelcomeUser, setShowModalWelcomeUser] = React.useState(
      location.state?.showWelcomeUser,
   );
   const [showModalWelcomePDV, setShowModalWelcomePDV] = React.useState(
      location.state?.showWelcomePDV,
   );

   const [tagsFilter, setTagsFilter] = React.useState<ITagsFilter[]>([]);
   const [interests, setInterests] = React.useState<InterestItem[]>([]);
   const [interestsSelect, setInterestsSelect] = React.useState<string[]>();

   const [page, setPage] = React.useState(1);
   const [cep, setCep] = React.useState('');
   const [opportunities, setOpportunities] = React.useState<OpportunityItem[]>(
      [],
   );
   const [opportunitiesSelect, setOpportunitiesSelect] =
      React.useState<string[]>();

   const loadingMore = isLoadingMore && { loading: true };

   React.useEffect(() => {
      const userIsLogged = auth.checkUserIsLogged();
      if (!userIsLogged) return navigate('/connection-denied');

      getConnections();
   }, [tagsFilter, page]);

   React.useEffect(() => {
      if (location.state?.showWelcomePDV) {
         location.state.showWelcomePDV = false;
      }

      if (location.state?.showWelcomeUser) {
         location.state.showWelcomeUser = false;
      }
   }, []);

   React.useEffect(() => {
      const superDrawerElement = document.querySelector(
         'clb-super-drawer',
      ) as unknown as HTMLElement;

      superDrawerElement?.addEventListener('click', e => {
         const targetTagName = (e.target as HTMLElement).tagName.toLowerCase();

         const closeSuperDrawer = () => {
            const timer = window.innerWidth > 768 ? 700 : 450;
            setTimeout(() => setShowFilters(false), timer);
         };

         if (targetTagName.includes('clb-button-icon')) {
            closeSuperDrawer();
         }

         if (targetTagName.includes('clb-button')) {
            superDrawerElement.shadowRoot
               ?.querySelector('div')
               ?.classList.remove('open');

            closeSuperDrawer();
         }
      });
   }, []);

   React.useEffect(() => {
      const modalElement = document.querySelector(
         'clb-modal',
      ) as unknown as HTMLElement;

      modalElement?.addEventListener('click', e => {
         const targetTagName = (e.target as HTMLElement).tagName.toLowerCase();
         if (targetTagName.includes('clb-button-icon'))
            setTimeout(() => setShowModalWhats(false), 400);
      });
   }, []);

   async function getInterestOpportunities() {
      if (interests.length === 0) {
         const _interestsData = await getInterest();
         setInterests(_interestsData);
      }

      if (opportunities.length === 0) {
         const _opportunitiesData = await getOpportunityByInterest({
            interestId: '1',
         });

         setOpportunities(_opportunitiesData);
      }
   }

   async function getConnections() {
      if (isLoading || isLoadingMore) return;

      if (page === 1) setIsLoading(true);
      else setIsLoadingMore(true);

      await getInterestOpportunities();

      const cepNumber = clearString(cep);
      const _connections = await connectionsService.getConnections({
         opportunitiesIds: opportunitiesSelect ?? [],
         interestsIds: interestsSelect ?? [],
         zipcode: cepNumber,
         page,
      });

      if (!_connections) {
         toast.show({
            title: 'Erro de conexão com o servidor',
            type: 'negative',
         });
         return;
      }

      setShowButtonLoadMore(_connections.length >= 9);

      if (page == 1) {
         setConnectionData(_connections);
      } else {
         setConnectionData([...connectionData, ..._connections]);
      }

      setIsLoading(false);
      setIsLoadingMore(false);
   }

   const handleShowFilters = () => {
      if (isLoading) return;
      setShowFilters(true);
   };

   const handleSendWhatsApp = (userId: string) => {
      setUserIdWhatsSelected(userId);
      setShowModalWhats(true);
   };

   function handleApplyFilter(items: IItemsSelected) {
      setTagsFilter([
         ...items.interests.map(i => ({
            id: i.id,
            name: i.name,
            type: 'interest',
         })),
         ...items.opportunities.map(o => ({
            id: o.id,
            name: o.name,
            type: 'opportunity',
         })),
      ]);

      setInterestsSelect(items.interests.map(i => i.id));
      setOpportunitiesSelect(items.opportunities.map(i => i.id));

      setPage(1);
   }

   function handleRemoveTagFilter(tagRemove: ITagsFilter) {
      if (isLoading) return;

      const isInterest =
         tagsFilter.filter(tag => tag === tagRemove && tag.type === 'interest')
            .length > 0;

      if (isInterest) {
         const listTagsSelect = interestsSelect?.filter(
            i => i !== tagRemove.id,
         );
         setInterestsSelect(listTagsSelect);
      } else {
         const listTagsSelect = opportunitiesSelect?.filter(
            i => i !== tagRemove.id,
         );
         setOpportunitiesSelect(listTagsSelect);
      }

      const listTags = tagsFilter.filter(t => t !== tagRemove);

      setPage(1);
      setTagsFilter(listTags);
   }

   function handleLoadMoreRegisters() {
      if (isLoadingMore) return;
      setPage(prev => prev + 1);
   }

   const capitalizeLetters = React.useCallback((text: string) => {
      const list = text.toLowerCase().split(' ');
      for (let i = 0; i < list.length; i++) {
         list[i] = list[i].charAt(0).toUpperCase() + list[i].slice(1);
      }

      return list.join(' ');
   }, []);

   const NotFoundPeople = React.memo(() => (
      <div className={styles.emptyWrapper}>
         <clb-emoji type="disappointed-face"></clb-emoji>
         <div className={styles.wrapperHeading}>
            <clb-heading-team size="xs">
               Não encontramos pessoas próximas a você no momento!
            </clb-heading-team>
         </div>
         <clb-text>
            Estamos trabalhando para ampliarmos cada vez mais as possibilidades
            de conexão dentro do Bora.
         </clb-text>
      </div>
   ));

   function connectionsPeople() {
      return (
         <div className={styles.cardsWrapper}>
            {connectionData.map(item => {
               const neighborhood = item.address?.district ?? '';
               const city = item.address?.city ?? '';
               const uf = item.address?.uf ?? '';

               const _adress = {
                  neighborhood,
                  city: `${city}/${uf}`,
               };

               return (
                  //@ts-ignore
                  <clb-card-profile
                     key={item.userId}
                     onClick={() => handleSendWhatsApp(item.userId)}
                     name={capitalizeLetters(item.name)}
                     //@ts-ignore
                     address={JSON.stringify(_adress)}
                     about={item.about}
                     //@ts-ignore
                     interests={JSON.stringify(item.opportunities)}
                     whatsapp
                  ></clb-card-profile>
               );
            })}
         </div>
      );
   }

   return (
      <>
         <ConnectionModal
            isOpen={showModalWhats}
            onClose={() => setShowModalWhats(false)}
            userID={userIdWhatsSelected}
         />

         <ConnectionFilters
            visible={showFilters}
            interests={interests}
            interestsIds={interestsSelect}
            opportunities={opportunities}
            opportunitiesIds={opportunitiesSelect}
            onApply={handleApplyFilter}
         />

         <div className={styles.connectionsContainer}>
            <Header />

            <section className={styles.container}>
               <div className={styles.content}>
                  <div className={styles.wrapper}>
                     <HeaderConnection
                        onFilter={handleShowFilters}
                        onSearch={() => {
                           if (page > 1) {
                              setPage(1);
                           } else {
                              getConnections();
                           }
                        }}
                        value={cep}
                        onChange={e => setCep(e.target.value)}
                        isLoading={isLoading}
                     />

                     {tagsFilter.length > 0 && (
                        <div className={styles.tagsFilterWrapper}>
                           {tagsFilter.map(tag => (
                              <div key={tag.id}>
                                 <clb-tag-filter
                                    label={tag.name}
                                    onClick={() => handleRemoveTagFilter(tag)}
                                 ></clb-tag-filter>
                              </div>
                           ))}
                        </div>
                     )}

                     <div className={styles.cardsAndLoadmoreWrapper}>
                        {isLoading && <ConnectionSearchingFor />}

                        {connectionData.length > 0 &&
                           !isLoading &&
                           connectionsPeople()}

                        {connectionData.length === 0 && !isLoading && (
                           <NotFoundPeople />
                        )}

                        {connectionData.length >= 9 &&
                           !isLoading &&
                           showButtonLoadMore && (
                              <div className={styles.buttonLoadMoreWrapper}>
                                 <clb-button
                                    icon="Loader"
                                    type="tertiary"
                                    onClick={handleLoadMoreRegisters}
                                    {...loadingMore}
                                 >
                                    Carregar mais
                                 </clb-button>
                              </div>
                           )}
                     </div>
                  </div>
               </div>
            </section>

            <Footer isHideNavigation />
         </div>

         <ModalButton
            id="modalWelcomeUser"
            isOpen={showModalWelcomeUser}
            onClose={setShowModalWelcomeUser}
            title="Seu perfil<br/>está pronto!"
            description="A partir de agora, seu perfil já está visível<br/>na vitrine de conexões. Caso precise, você<br/>pode editá-lo na área Meu Perfil."
            buttonPrimary={{
               label: 'Ver conexões',
               onClick: () => setShowModalWelcomeUser(false),
            }}
            emojiType="star-struck"
         />

         <ModalButton
            id="modalWelcomePDV"
            isOpen={showModalWelcomePDV}
            onClose={setShowModalWelcomePDV}
            title="Seu perfil<br/>está pronto!"
            description="A partir de agora, você tem acesso a todos<br/>os perfis disponíveis na vitrine de conexões."
            buttonPrimary={{
               label: 'Ver conexões',
               onClick: () => setShowModalWelcomePDV(false),
            }}
            emojiType="star-struck"
         />
      </>
   );
}
