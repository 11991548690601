import React from 'react';
import styles from './ModalStepper.module.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import parseHtml from 'html-react-parser';

import { Type as TEmoji } from '@meiuca/celebration-team-bora/dist/components/emoji/emoji.types';

import 'swiper/css';

interface Props {
   id?: string;
   steps: Step[];
   onClick: () => void;
   onClose?: (isOpen: boolean) => void;
   isOpen?: boolean;
   [key: string]: unknown;
}

interface Step {
   id: string;
   title: string;
   description: string;
   emoji?: TEmoji;
}

export function ModalStepper({
   id = 'Stepper',
   steps,
   onClick,
   onClose,
   isOpen = false,
}: Props) {
   const [isLoading, setIsLoading] = React.useState(false);
   const [itemSelected, setItemSelected] = React.useState(0);
   const swiperRef = React.useRef<unknown>();

   const showNavigation = itemSelected === 2 ? {} : { 'show-navigation': true };

   const booleanProps = {
      isOpen: isOpen ? true : undefined,
   };

   const loading = isLoading && { loading: true };

   const modalId = `modal${id
      .toLocaleLowerCase()
      .split(' ')
      .join('_')
      .split('/')
      .join('_')}`;

   React.useEffect(() => {
      if (onClose) {
         const modalElement = document.getElementById(
            modalId,
         ) as unknown as HTMLElement;

         modalElement?.addEventListener('click', e => {
            const targetTagName = (
               e.target as HTMLElement
            ).tagName.toLowerCase();

            if (targetTagName.includes('clb-button-icon')) {
               if (isLoading) return;
               setTimeout(() => onClose(false), 400);
            }

            if (targetTagName.includes('clb-button')) {
               if (isLoading) return;
               setTimeout(() => {
                  modalElement.shadowRoot
                     ?.querySelector('div')
                     ?.classList.remove('container--isOpen');
               }, 1000);
            }
         });
      }
   }, []);

   React.useEffect(() => {
      if (swiperRef.current) {
         // @ts-ignore
         swiperRef.current.slidePrev();
      }
   }, [isOpen]);

   const handleNavControl = (event: Event): void => {
      const flowDirection = (event.target as HTMLElement).getAttribute('id');
      const step = itemSelected + (flowDirection === 'next-button' ? 1 : -1);

      if (swiperRef.current) {
         // @ts-ignore
         if (step > itemSelected) swiperRef.current.slideNext();
         // @ts-ignore
         else swiperRef.current.slidePrev();
      }

      setItemSelected(step);
   };

   function onHandleContinue() {
      setIsLoading(true);
      setTimeout(() => {
         onClick();
      }, 1000);
   }

   return (
      <clb-modal id={modalId} {...booleanProps}>
         <div className={styles.modalContent}>
            <div className={styles.emojiHeadingWrapper}>
               <Swiper
                  onSwiper={(swiper: any) => {
                     swiperRef.current = swiper;
                  }}
                  onActiveIndexChange={(swiper: any) =>
                     setItemSelected(swiper.activeIndex)
                  }
                  className={styles.swiper}
                  spaceBetween={50}
                  slidesPerView={1}
               >
                  {steps.length > 0 &&
                     steps.map(item => {
                        return (
                           <SwiperSlide key={item.id}>
                              <div className={styles.step}>
                                 <clb-emoji type={item.emoji}></clb-emoji>
                                 <clb-heading-team size="lg">
                                    {parseHtml(item.title)}
                                 </clb-heading-team>
                                 <clb-text>
                                    {parseHtml(item.description)}
                                 </clb-text>
                              </div>
                           </SwiperSlide>
                        );
                     })}
               </Swiper>
            </div>

            <div className={styles.modalButtonWrapper}>
               <clb-nav-control
                  {...showNavigation}
                  itemsCount={steps.length}
                  items-current={itemSelected}
                  onClick={handleNavControl}
               ></clb-nav-control>

               {itemSelected === 2 && (
                  <clb-button {...loading} onClick={onHandleContinue}>
                     Começar
                  </clb-button>
               )}
            </div>
         </div>
      </clb-modal>
   );
}
